import React, { useEffect, useState } from "react";
import "./EditDetails.css";
import axios from "../../../axios";

export default function EditDetail({
  id,
  existingData,
  setExistingData,
  setEditModal,
  olderData,
}) {

    const Marketer = JSON.parse(localStorage.getItem("marketer"))

  const EditBankDetail = async (e) => {
    e.preventDefault();
    const formElements = e.target.elements;
    const newBankDetails = {
      bank_name: formElements.bank_name.value,
      account_number: formElements.account_number.value,
      ifsc_code: formElements.ifsc_code.value,
      account_name: formElements.account_name.value,
    };

    const { data } = await axios.post(
      `/marketer-bank-detail/update/${id}`,
      newBankDetails,
      {
        headers: {
          authorization: "Bearer " + Marketer.token,
        },
      }
    );

    const updatedData = existingData?.map((element) => {
      if (element._id === id) {
        return { ...element, ...data }; // Update the matched element
      }
      return element; // Keep the other elements unchanged
    });

    setExistingData(updatedData);
    setEditModal(false);
  };

  return (
    <form action="" className="editDetailForm" onSubmit={EditBankDetail}>
      <div className="bankDetailGrid">
        <div className="grid-child">
          <label htmlFor="bank_name">Bank Name</label>
          <input
            type="text"
            name="bank_name"
            defaultValue={olderData?.bank_name}
          />
        </div>
        <div className="grid-child">
          <label htmlFor="account_number">Account Number</label>
          <input
            type="text"
            name="account_number"
            defaultValue={olderData?.account_number}
          />
        </div>
        <div className="grid-child">
          <label htmlFor="ifsc_code">IFSC Code</label>
          <input
            type="text"
            name="ifsc_code"
            defaultValue={olderData?.ifsc_code}
          />
        </div>
        <div className="grid-child">
          <label htmlFor="account_name">Account Name</label>
          <input
            type="text"
            name="account_name"
            defaultValue={olderData?.account_name}
          />
        </div>
      </div>
      <div className="bankDetailButtons">
        <button
          className="cancelButton"
          onClick={() => {
            setEditModal(false);
          }}
        >
          Cancel
        </button>
        <button type="submit" className="saveButton">
          Save
        </button>
      </div>
    </form>
  );
}
