import ClientSay from "./sub-components/ClientSay.js";
import CarrerResoure from "./sub-components/CarrerResoure.js";
import FindMentor from "./sub-components/FindMentor.js";
import LastSect from "./sub-components/LastSect.js";
import Main from "./sub-components/Main.js";
import Mentor from "./sub-components/Mentor.js";
import Mentor2 from "./sub-components/Mentor2.js";
import WorkFlow from "./sub-components/WorkFlow.js";
import Faq from "./sub-components/Faq.js";

import "./NewHomePage.css";

function NewHomePage() {
  return (
    <div className=" Main">
      <Main></Main>
      <FindMentor></FindMentor>
      <WorkFlow></WorkFlow>
      <Mentor></Mentor>
      <div className="break-line"></div>
      <Mentor2></Mentor2>
      <div className="break-line"></div>
      <ClientSay></ClientSay>
      <div className="break-line"></div>
      <CarrerResoure></CarrerResoure>
      <div className="break-line"></div>
      <Faq></Faq>
      <LastSect></LastSect>
      <div className="break-line"></div>
    </div>
  );
}

export default NewHomePage;
