import React, { useEffect, useState } from "react";
import "../../styles/SapAgencies.css";
import searchgrey from "../../images/searchgrey.png";
import blogworks from "../../images/blogworks.png";
import axios from "../../axios";
import { useHistory, Link } from "react-router-dom";
import logo from "../../images/logos.png";
import acessLogo from "./assets/access.png"
import Loader from "../loader/Loader";


function SapAgencies(props) {
  const history = useHistory();

  const [searchinput,setsearchinput]=useState("")

  const [dataName,setDataName]=useState("agency")

  const agencyListField={
    1:"Agency",
    2:"Admin",
    3:"Buisness Email",
    dataModel:{
      logo:"agencyLogo",
      name:"agencyName",
      usernameAdmin:"name",
      email:"businessEmail"
    }
  }
  const BrandListField={
    1:"Brand",
    2:"Admin",
    3:"Buisness Email", 
    dataModel:{
      logo:"agencyLogo",
      name:"brandName",
      usernameAdmin:"name",
      email:"businessEmail"
    }
  }
  const UserListField={
    1:"User",
    2:"Username",
    3:"Email ID",
    dataModel:{
      logo:"profilePhoto",
      name:"name",
      usernameAdmin:"username",
      email:"email"
    }
  }

  const [listField,setlistField]=useState(agencyListField)

  useEffect(()=>{
    if(dataName==="agency"){
      setlistField(agencyListField)
      setData(allAgencies)
    }else if(dataName==="brand"){
      setlistField(BrandListField)
      setData(allBrands)
    }else{
      setlistField(UserListField)
      setData(allMarketers)
    }

  },[dataName])
   
   
  const [allAgencies, setAllAgencies] = useState();
  const [allBrands,setAllBrands]=useState()
  const [allMarketers,setAllMarketers]=useState()

  const [data,setData]=useState([])

  useEffect(()=>{
    if(dataName==="agency"){
      setData(allAgencies)
    }else if(dataName==="brand"){
      setData(allBrands)
    }else{
      setData(allMarketers)
    }
  },[allAgencies,allBrands,allMarketers])

  // Get api data
  useEffect(async () => {
    try {
      const Agencyres = await axios.post("/superAdmin/agencies",{pageSize:50,page:1});
      setAllAgencies(Agencyres.data);
      setDataName("agency")
      const BrandRes = await axios.post("/superAdmin/brands",{pageSize:50,page:1});
      setAllBrands(BrandRes.data );
      const MarketersRes = await axios.post("/superAdmin/marketers",{pageSize:50,page:1});
      setAllMarketers(MarketersRes.data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  // const accessDashboard = (i) => {
  //   const id = allAgencies[i]._id;
  //   history.push(`/adashboard`);
  // };

  if(!allAgencies||!allBrands ||!allMarketers){
    return <Loader/>
  }else  return (
    <div className="sapagencies">
      
      <div className="sapagencies__top sapfilters">
      <div className="sapfilter__types">
      <div
          className="sapfilter__type"
          style={{textAlign:"center",borderBottom:dataName==="agency"&&"3px solid #ff656a",padding:"0.5rem 1rem"}}
          onClick={()=>{setDataName("agency")}}
          dest="servicelist"
        >
         Agency
        </div>
        <div
          className="sapfilter__type "
          style={{ textAlign:"center",borderBottom: dataName==="brand"&&"3px solid #ff656a",padding:"0.5rem 1rem"}}
          onClick={()=>{setDataName("brand")}}
          dest="industrylist"
        >
          Brands 
        </div>
        <div
          className="sapfilter__type"
          style={{textAlign:"center",borderBottom:dataName==="user"&&"3px solid #ff656a",padding:"0.5rem 1rem"}}
          onClick={()=>{setDataName("user")}}
          dest="locationlist"
        >
          User
        </div>
      </div>
        {/* <form className="sapagencies__data__inpcont">
          <img className="search__icon" src={searchgrey} alt="" />
          <input className="search__input"  onChange={(event)=>{console.log(event.target.value);}} placeholder="Search" />
        </form>
        <select className="sap__select">
          <option defaultValue="Sort">Sort</option>
          <option>Popular</option>
          <option>Popular</option>
        </select> */}
      </div>

      <div className="saptable__lists casestudy sapservicestable">
      <h5 style={{textAlign:"left",color:'#ff656a'}}>Total Results : <span>{data.length}</span></h5>
        <table className="pricing__table">
          <tbody className="">
            <tr className="table__heading__row ">
              <th className="heading__row__part">
                <p className="theading ">#</p>
              </th>
              <th className="heading__row__part">
                <p className="theading ">{listField[1]}</p>
              </th>
              <th className="heading__row__part">
                <p className="theading ">{listField[2]}</p>
              </th>
              <th className="heading__row__part">
                <p className="theading ">{listField[3]}</p>
              </th>
              <th className="heading__row__part">
                <p className="theading ">Registration Log</p>
              </th>
              <th className="heading__row__part">
                <p className="theading "><img style={{height:"11px"}} src={acessLogo} alt="" /></p>
              </th>
            </tr>
            {data.slice().reverse().map((a, i) => (
              <tr className="text__center saptablerow">
                <td className="para adgrey pad1hrow"> 
                  {i+1}
                </td>
                <td style={{flexDirection:"column"}} className="para adgrey image__name__cont pad1hrow textleftfirr">
                  {a[listField.dataModel.logo] ? (
                    <img src={a[listField.dataModel.logo]} className="saprow__logo" alt="" />
                  ) : (
                    <img src={logo} className="saprow__logo"></img>
                  )}
                  {a[listField.dataModel.name]}
                </td>
                <td className="para adgrey pad1hrow">
                  {a[listField.dataModel.usernameAdmin]}
                </td>
                <td className="para adgrey pad1hrow">
                  {a[listField.dataModel.email]}
                </td>
                <td className="para adgrey pad1hrow">
                   {/* for time ObjectId("507c7f79bcf86cd7994f6c0e").getTimestamp()  */}
                  {a.createdAt} 
                </td>
                {/* <td className="para adgrey pad1hrow">
                  {a.isClaimed ? <p>Yes</p> : <p>No</p>}
                </td> */}
                {/* <td className="para adgrey pad1hrow"> 
                  <div className="yonobtns">
                    <p className="para yonobtn activetog" onClick={toggleyesno}>
                      Yes
                    </p>
                    <p className="para yonobtn" onClick={toggleyesno}>
                      No
                    </p>
                  </div>
                </td> */}
                {/* <td className="para adgrey pad1hrow">Online</td> */}
                <td className="para adgrey pad1hrow">
                  <div className="dfxlex align__center justify__center">
                    <Link style={{textDecoration:"none"}} target="_blank" to={"/aeditprofile/" + a._id} >
                      <p style={{marginBottom:"0",fontSize:"20px"}} className="para color__orange cursor__pointer">
                        {/* Access Dashboard */}
                        {">"}
                      </p>
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SapAgencies;
