import React, { useEffect, useState } from "react";
import "./SearchPage.css";
import axios from "../../axios.js";
import AgencyCard from "../AgencyCard/AgencyCard";
import ReactPaginate from "react-paginate";
import unispade_logo from "../../images/unispade_logo.png";
import HourGlass from "../../images/HourGlass.png";
import CheckCircle from "../../images/check-circle.png";
import { useHistory, useParams } from "react-router-dom";
import AgencyDetails from "../AgencyDetails/AgencyDetails";
import SecondaryFooter from "../SecondaryFooter/SecondaryFooter";
import FilterDropDown from "../FiterDropDown/FilterDropDown";
import Button from "../Button/Button";
import ServicePageBanner from "../ServicePageBanner/ServicePageBanner";
import { Helmet } from "react-helmet";
import Loader from "../loader/Loader"

const dummtAgencyObj = {
  aboutAgency: "",
  agencyClients: [],
  agencyLogo: unispade_logo,
  agencyName: "Unispade",
  agencySize: 54,
  agencyWebsite: "",
  businessEmail: "",
  clients: [],
  foundedYear: "2017-01-01T00:00:00.000Z",
  industry: [],
  isClaimed: true,
  isFeatured: false,
  isOnline: true,
  isUpdated: false,
  locations: ["Delhi"],
  name: "Raghu Nandan Choudhary",
  password: "$2b$12$tEV8rzlXNvKfkliWo.uB1uSFC/3JoCAVR6/LBjhZauQwWig8MK20C",
  phoneNumber: 919968669944,
  services: [
    "branding",
    "design",
    "B2B Marketing",
    "Content Marketing",
    "Content Strategy",
    "seo",
    "Account Based Marketing (ABM)",
    "Email Marketing",
    "Events Marketing",
  ],
  teamProfiles: [],
};

const dummyAgenciesArr = Array(3).fill(dummtAgencyObj);
const pageSize = 50;

const initFilters = {
  services: "",
  locations: "",
  industries: "",
  minSize: 0,
  maxSize: 1000000000,
  clients: "",
  pageSize: pageSize,
  page: 1,
  clientId: "",
  alphabet: ""
};

const SearchPage = (props) => {
  const [filters, setFilters] = useState(initFilters);
  const [agencies, setAgencies] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedAgency, setSelectedAgency] = useState(undefined);
  const history = useHistory();
  const [showFooter, setShowFooter] = useState(false);
  const [allServices, setAllServices] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [allIndustries, setAllIndustries] = useState([]);
  const [dropDownValues, setDropDown] = useState({});
  const [totalResults, setTotalResults] = useState("");
  const [likedAgencies, setLikedAgencies] = useState([]);
  const [servicePageTitle, setServicePageTitle] = useState("");
  const [pageContent, setPageContent] = useState("");
  const [showClaimProfile, setShowClaimProfile] = useState(false);
  const [showEmailSent, setShowEmailSent] = useState(false);
  const [showClaimedProfile, setShowClaimedProfile] = useState(false);
  const [service,setService]=useState("")
  const [location,setLocation]=useState("")
  const searchService = useParams();
  
  let footerTimeout;
  let description = "";

  useEffect(() => {
    footerTimeout = setTimeout(() => {
      setShowFooter(true);
    }, 30000);

    if (!props.servicePage) {
      getAllServices()
        .then((res) => {
          setAllServices(res);
        })
        .catch((err) => {
          console.error(err, "getAllServices");
        });
    }

    getAllLocations()
      .then((res) => {
        setAllLocations(res);
      })
      .catch((err) => {
        console.error(err, "getAllLocations");
      });

    getAllClients()
      .then((res) => {
        setAllClients(res);
      })
      .catch((err) => {
        console.error(err, "getAllClients");
      });

    getAllIndustries()
      .then((res) => {
        setAllIndustries(res);
      })
      .catch((err) => {
        console.error(err, "getAllIndustries");
      });

    getLikedAgencies();
    if (props.servicePage) getServicePageTitle();

    return () => {
      clearTimeout(footerTimeout);
    };
  }, []);

  useEffect(() => {
    if (showFooter) {
      setTimeout(() => {
        setShowFooter(false);
      }, 10000);
    }
  }, [showFooter]);

  useEffect(() => {
    getAllAgencies();
  }, [filters, props.loggedIn]);

  const getServicePageTitle = () => {
    if (!searchService.servicePageKeyword.includes("-")) {
      history.replace("/");
    } else {
      let location = "";
      let service = "";
      let searchedKeyword = searchService.servicePageKeyword;
      let temp = "";
      if (!searchedKeyword.includes("-in")) {
        temp = searchedKeyword.split("-agencies");
        service = temp[0]?.split(" ").join("-");
        location = "";
      } else {
        temp = searchedKeyword.split("-agencies-in-");
        service = temp[0]?.split(" ").join("-"); // web_design
        location = capitalize(temp[1]?.replace("_", " ")); // new_york
      }
      let bannerText = `Best ${capitalize(service)
        .split("-")
        .join(" ")} Agencies ${location === "" ? "" : "in " + capitalize(location)
        }`;
      description =
        "Looking to hire the reliable Best" +
        capitalize(service) +
        "Company in" +
        capitalize(location) +
        "for your digital marketing project? Select the best company on the basis of their reviews, ratings and your requirement.";
      setServicePageTitle(bannerText);

 
      // for removing - and replaicing with space for service search
      service=service.replace(/-/g, " ").toLowerCase();
      setService(service)
      setLocation(location)
      getServicePageDetails(service, location);
      let filterCopy = { ...filters };
      filterCopy.locations = location;
      filterCopy.services = service;
      setTimeout(() => setFilters(filterCopy), 4000);
    }
  };

  const getServicePageDetails = async (service, location) => {
    try {
      const url = `/superAdmin/getservicePageDetails?service=${service}${location !== "" ? "&location=" + location : ""
        }`;
      const res = await axios.get(url);
      const pageData = res.data.content;
      setPageContent(pageData);
    } catch (err) {
      console.log(err);
    }
  };

  const capitalize = (input) => {
    if (input === null || input === undefined || input === "") return input;
    var words = input.split(" ");
    var CapitalizedWords = [];
    words.forEach((element) => {
      CapitalizedWords.push(
        element[0].toUpperCase() + element.slice(1, element.length)
      );
    });
    return CapitalizedWords.join(" ");
  };

  const getAllServices = async () => {
    try {
      const allServices = await axios.get("/superAdmin/filters/getAllServices");
      return allServices.data.selectedServices;
    } catch (err) {
      return err;
    }
  };

  const getAllLocations = async () => {
    try {
      const allLocations = await axios.get(
        "/superAdmin/filters/getAllLocations"
      );
      return allLocations.data;
    } catch (err) {
      return err;
    }
  };

  const getAllClients = async () => {
    try {
      const allClients = await axios.post("/getAllDistinctClientsName");
      return allClients?.data || [];
    } catch (err) {
      return err;
    }
  };

  const getAllIndustries = async () => {
    try {
      const allIndustries = await axios.get(
        "/superAdmin/filters/getAllIndustries"
      );
      return allIndustries.data.selectedIndustries;
    } catch (err) {
      return err;
    }
  };

  const getAllAgencies = async () => {
    props.setLoadingHandler(true);
    let filterCopy = { ...filters };
    if (searchService && searchService.alphabet) {
      filterCopy.alphabet = searchService.alphabet.toUpperCase();
    }
    const response = await axios.post("/getAllagencies", filterCopy);
    let sortedRes = sortAgencyWithLogo(response.data.agencies);
    let totalPagesCalc = response.data.totalResults / pageSize;
    setTotalPages(Math.ceil(totalPagesCalc));
    setTotalResults(response.data.totalResults);
    if (props.loggedIn) setAgencies(sortedRes);
    else if (!props.loggedIn) setAgencies(sortedRes.slice(0, 5));
    props.setLoadingHandler(false);
    if (sortedRes.length > 0) selectAgencyHandler(sortedRes[0]);
    setLoading(false);
    if (window.innerWidth <= 768) {
      let elem = document.getElementById("agency-details-wrapper");
      if (elem) {
        elem.style.display = "none";
      }
    }
  };

  const sortAgencyWithLogo = (agencyList) => {
    let agenciesWithLogo = [];
    let agenciesWithoutLogo = [];
    let dummyId = undefined;
    let bottomIds = [];
    agencyList.forEach((agency) => {
      if (agency._id === "61208e7061873d39a4fadd0e") {
        dummyId = agency;
      } else if (agency._id === "613f8bdbb0363d0004a1c401") {
        //team varience
        bottomIds.push(agency);
      } else if (agency.agencyLogo) {
        agenciesWithLogo.push(agency);
      } else {
        agenciesWithoutLogo.push(agency);
      }
    });
    let sortedList = [
      ...agenciesWithLogo,
      ...agenciesWithoutLogo,
      ...bottomIds,
    ];
    return sortedList;
  };

  const handlePageClick = (page) => {
    let filterCopy = { ...filters };
    filterCopy.page = page.selected + 1;
    setFilters(filterCopy);
  };

  const selectAgencyHandler = (agency) => {
    if (window.innerWidth <= 768) {
      let elem = document.getElementById("agency-details-wrapper");
      if (elem) {
        elem.style.display = "flex";
      }
    }
    setSelectedAgency(agency);
  };

  const setDropDownValues = (key, value) => {
    let dropDownValuesCopy = { ...dropDownValues };
    dropDownValuesCopy[key] = value;
    setDropDown(dropDownValuesCopy);
  };

  const formatClientName = (clientName) => {
    let arr = clientName.split(" ");
    let lowercaseArr = arr.map((elem) => elem.toLowerCase());
    return lowercaseArr.join("-");
  };

  const getClientId = async (clientName) => {
    let payload = {
      clientname: clientName,
    };
    const response = await axios.post(
      "/getClientDetailsbyclientsName",
      payload
    );
    if (response.status === 200) {
      return response.data._id;
    }
  };

  const searchClickHandler = async () => {
    let clientId = "";
    if (dropDownValues.clients && dropDownValues.clients !== "") {
      clientId = await getClientId(dropDownValues.clients);
    }
    let filterCopy = { ...filters };
    filterCopy.services = dropDownValues.services;
    filterCopy.locations = dropDownValues.locations;
    filterCopy.industries = dropDownValues.industries;
    filterCopy.clients = dropDownValues.clients;
    filterCopy.clientId = clientId;
    setFilters(filterCopy);
  };

  const scrollElem = (id) => {
    const yOffset = -70;
    const element = document.getElementById("agency-wrapper" + id);
    if (element) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const getLikedAgencies = (agencyId) => {
    if (localStorage.getItem("branduser") != null) {
      const payload = {
        brandId: JSON.parse(localStorage.getItem("branduser")).user.brandId,
      };
      if (agencyId) payload["agencyId"] = agencyId;
      axios
        .post("/brands/unlikeAgency", payload)
        .then((response) => {
          setLikedAgencies(response.data.likedAgencies);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const showClaimProfilePopup = () => {
    setShowClaimProfile(true);
  };

  const removeDash = (titleservice) => {
    if (!titleservice) return "";
    const arr = titleservice.split("-");
    const service = arr.join(" ");
    return service;
  };

  const onConfirmButtonClick = async (businessEmail) => {
    let payload = {
      email: businessEmail,
    };
    const response = await axios.post("/emailSendForClaimed", payload);
    setShowClaimProfile(false);
    setShowEmailSent(true);
  };

  const emailSentPopup = () => {
    return (
      <div className="popup">
        <div className="popup-wrapper">
          <div className="close-btn" onClick={() => setShowEmailSent(false)}>
            X
          </div>
          <div className="popup-content">
            <img src={CheckCircle} alt="check circle" />
            <div className="popup-header">Email Sent</div>
            {selectedAgency.businessEmail &&
              selectedAgency.businessEmail !== "" && (
                <div className="popup-text2">{`Please check the mail sent to ${selectedAgency.businessEmail}`}</div>
              )}
          </div>
        </div>
      </div>
    );
  };
  const pageRangeDisplayed = window.innerWidth >= 1300 ? 8 : 4;


  const claimProfilePopup = () => {
    return (
      <div className="popup">
        <div className="popup-wrapper">
          <div className="close-btn" onClick={() => setShowClaimProfile(false)}>
            X
          </div>
          <div className="popup-content">
            <img src={HourGlass} alt="hour glass" />
            <div className="popup-header">Please Confirm</div>
            <div className="popup-text1">
              Do you want to claim this agency profile?
            </div>
            {selectedAgency.businessEmail &&
              selectedAgency.businessEmail !== "" && (
                <div className="popup-text2">{`The mail will be sent to ${selectedAgency.businessEmail}`}</div>
              )}
            <Button
              buttonText="Confirm"
              onBtnClick={() =>
                onConfirmButtonClick(selectedAgency.businessEmail)
              }
            ></Button>
          </div>
        </div>
      </div>
    );
  };

  const claimedProfilePopup = () => {
    return (
      <div className="popup">
        <div className="popup-wrapper">
          <div
            className="close-btn"
            onClick={() => setShowClaimedProfile(false)}
          >
            X
          </div>
          <div className="popup-content">
            <img src={CheckCircle} alt="check circle" />
            <div className="popup-header">Profile Claimed</div>
            {selectedAgency.businessEmail &&
              selectedAgency.businessEmail !== "" && (
                <div className="popup-text2">{`This profile has been claimed by ${selectedAgency.businessEmail}`}</div>
              )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {loading && <Loader/>}
      {showEmailSent && emailSentPopup()}
      {showClaimProfile && claimProfilePopup()}
      {showClaimedProfile && claimedProfilePopup()}
      {!loading && (
        <>
          <Helmet>
            <title>{`Top ${capitalize(
              removeDash(filters.services)
            )} agencies ${filters.locations !== ""
                ? "in " + capitalize(filters.locations)
                : ""
              } - 2024 Reviews | Unispade`}</title>
            <meta
              charSet="UTF-8"
              content={`Looking for reliable top ${capitalize(removeDash(filters.services))} Agencies${filters.locations !== ""? " in " + capitalize(filters.locations): ""}? Enhance your digital presence with our expert ${capitalize(removeDash(filters.services))} services. Hire the best ${capitalize(removeDash(filters.services))} firms for your big project.`}
              name="description"
            ></meta>
            <link rel="canonical" href="https://www.unispade.com/search/" />
          </Helmet>
          {props.servicePage && (
            <>
            <div className="total-results">
              <span onClick={()=>{window.location.href ='/'}}>{"Home Page"}</span>
              <span>&nbsp;</span>
              <span onClick={()=>{window.location.href ="/"+service.replaceAll(" ","-")+"-agencies"}} >{"> "+service.charAt(0).toUpperCase() + service.slice(1)}</span>
              <span>&nbsp;</span>
              {location&&<span>{" > "+location.charAt(0).toUpperCase() + location.slice(1)}</span>}
            </div>
              <ServicePageBanner bannerText={servicePageTitle} />
            </>
            
          )}
          <div className="search-page" id="search-page">
            <div className="filter-container">
              <FilterDropDown
                placeholder="Services"
                dropdownOptions={allServices}
                setDropDownValues={setDropDownValues}
              />
              <FilterDropDown
                placeholder="Locations"
                dropdownOptions={allLocations}
                setDropDownValues={setDropDownValues}
              />
              <FilterDropDown
                placeholder="Clients"
                dropdownOptions={allClients}
                setDropDownValues={setDropDownValues}
              />
              <FilterDropDown
                placeholder="Industries"
                dropdownOptions={allIndustries}
                setDropDownValues={setDropDownValues}
              />
              <Button
                buttonText="Search"
                onBtnClick={searchClickHandler}
                customClass="search-btn"
              />
            </div>
            <div className="total-results">
              {totalResults + " search results"}
            </div>
            <div className="content-div">
              <div className="agency-list">
                <div className="agencyCardSec">
                {agencies && agencies.length > 0 &&
                  agencies.map((agency) => {
                    return (
                      <div
                        className="agency-wrapper"
                        id={"agency-wrapper" + agency?._id}
                      >
                        {selectedAgency?._id === agency?._id && (
                          <span className="selected-agencies"></span>
                        )}
                        <AgencyCard
                          agency={agency}
                          selectAgencyHandler={selectAgencyHandler}
                          scrollElem={scrollElem}
                        />
                      </div>
                    );
                  })}
                {!props.loggedIn && (
                  <>
                    <div className="transparent">
                      {dummyAgenciesArr.map((agency) => (
                        <AgencyCard agency={agency} />
                      ))}
                    </div>
                    <div className="register_text">
                      Please
                      <span
                        className="link-text"
                        onClick={() => history.push("/brandsignup")}
                      >
                        Register
                      </span>
                      or
                      <span
                        className="link-text"
                        onClick={() => history.push("/brandlogin")}
                      >
                        {" "}
                        log in{" "}
                      </span>
                      to view more results
                    </div>
                  </>
                )}
                </div>
                {props.loggedIn && (
                  <ReactPaginate
                    breakLabel="....."
                    nextLabel=""
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={pageRangeDisplayed}
                    pageCount={totalPages}
                    previousLabel=""
                    renderOnZeroPageCount={null}
                    className="pagination_sec"
                    pageClassName="pagination_sec_li"
                    breakClassName="pagination_sec_break"
                    activeClassName="pagination_sec_active"
                  />
                )}
              </div>
              {selectedAgency && (
                <div
                  className="agency-details-wrapper"
                  id="agency-details-wrapper"
                >
                  <AgencyDetails
                    selectedAgency={selectedAgency}
                    likedAgencies={likedAgencies}
                    getLikedAgencies={getLikedAgencies}
                    showClaimProfilePopup={showClaimProfilePopup}
                    setShowClaimedProfile={setShowClaimedProfile}
                  />
                </div>
              )}
            </div>
            {props.servicePage && (
              <div
                className="service__page"
                dangerouslySetInnerHTML={{
                  __html: pageContent?.replaceAll("\\", ""),
                }}
              ></div>
            )}
          </div>
        </>
      )}
      {showFooter && <SecondaryFooter />}
    </>
  );
};

export default SearchPage;
