import React, { useEffect, useRef, useState } from "react";
import reviewsIcon from "../demoImages/reviews.svg";
import magnify from "../demoImages/magnify.svg";
import UnispadeLogoBlack from "../../../../../assets/Unispade Favicon 000000.png";
import UnispadeLogoGray from "../../../../../assets/Unispade favicon b5b5b5.png";

import "../TalentPool.css";
import axios from "../../../../../axios.js";
import TwoButtonWrapper from "../../../../../atoms/TwoButtonWrapper/TwoButtonWrapper.jsx";

import { MdDelete, MdExpandMore, MdExpandLess } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineAdjust } from "react-icons/md";

import { IoFunnelOutline } from "react-icons/io5";
import { DropdownContentArea } from "../../../../../atoms/DropdownContentArea.jsx";
import { AddNewCandidate } from "../AddNewCandidate/AddNewCandidate.jsx";
import { ChatBoxComponent } from "../../../../../atoms/ChatBoxComponent/ChatBoxComponent.jsx";
import ToggleSwitch from "../../../../../atoms/ToggleSwitch/ToggleSwitch.jsx";

export const InThePool = ({ selectedCategory }) => {
  const [addedUser, setAddedUser] = useState([]);
  const [copyUserData, setcopyUserData] = useState([]);
  const [rejectionComment, setRejectionComment] = useState({});
  const [comment, setComment] = useState({});

  const [userPrice, setUserPrice] = useState({});
  const [isTopCandidate, setIsTopCandidate] = useState({});
  const [skillSearchValue, setSkillSearchValue] = useState("");
  const [tagSeachValue, setTagSearchValue] = useState("");
  const [suggestedSkills, setSuggestedSkills] = useState([]);
  const [suggestedTags, setSuggestedTags] = useState([]);

  const [chatMessages, setChatMessages] = useState([]);

  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [toggleButtonSelected, setToggleButtonSelected] = useState({});
  const [deleteButtonSelected, setDeleteButtonSelected] = useState({});
  const [commentButtonSelected, setCommentButtonSelected] = useState({});
  const [chatButtonSelected, setChatButtonSelected] = useState({});

  const [stateForNormal, setStateForNormal] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [hoverInviteSent, setHoverInviteSent] = useState({});

  const [filterValue, setFilterValue] = useState([]);
  const [idToProfile, setIdToProfile] = useState({
    [addedUser[0]?.invited_by?._id]: addedUser[0]?.invited_by?.profilePhoto,
  });

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  const toast_style = {
    position: "top-right",
    autoClose: 4000,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    width: "10rem",
  };

  useEffect(() => {
    (async () => {
      try {
        let { data } = await axios.get(
          `/talentPool/get_talent_pool_users/${selectedCategory?._id}`,
          { headers: { authorization: "Bearer " + Marketer.token } }
        );
        setAddedUser(data);
        setcopyUserData(data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [selectedCategory]);

  useEffect(() => {
    if (addedUser?.length > 0) {
      addedUser?.map((user) => {
        setRejectionComment((prevData) => ({
          ...prevData,
          [user?._id]: user?.rejectionComment,
        }));
      });
    }
  }, [addedUser]);

  useEffect(() => {
    if (addedUser?.length > 0) {
      addedUser?.map((user) => {
        setIsTopCandidate((prevData) => ({
          ...prevData,
          [user?._id]: user?.is_top_candidate,
        }));
      });
    }
  }, [addedUser]);

  const handleToggleButton = (id) => {
    if (!toggleButtonSelected[id]) {
      setDeleteButtonSelected({ [id]: false });
      setCommentButtonSelected({ [id]: false });
      setChatButtonSelected({ [id]: false });
      setSelectedCandidate({ [id]: true });
    } else {
      setSelectedCandidate({ [id]: false });
    }
    setToggleButtonSelected({ [id]: !toggleButtonSelected[id] });
  };
  const handleDeleteButton = (id) => {
    if (!deleteButtonSelected[id]) {
      setCommentButtonSelected({ [id]: false });
      setChatButtonSelected({ [id]: false });
      setToggleButtonSelected({ [id]: false });
      setSelectedCandidate({ [id]: true });
    } else {
      setSelectedCandidate({ [id]: false });
    }
    setDeleteButtonSelected({ [id]: !deleteButtonSelected[id] });
  };
  const handleCommentButton = (id) => {
    if (!commentButtonSelected[id]) {
      setDeleteButtonSelected({ [id]: false });
      setChatButtonSelected({ [id]: false });
      setToggleButtonSelected({ [id]: false });
      setSelectedCandidate({ [id]: true });
    } else {
      setSelectedCandidate({ [id]: false });
    }
    setCommentButtonSelected({ [id]: !commentButtonSelected[id] });
  };
  const handleChatButton = (id, userData, chatBoxId) => {
    console.log("The chat box id is -", userData);
    if (!chatButtonSelected[id]) {
      setDeleteButtonSelected({ [id]: false });
      setCommentButtonSelected({ [id]: false });
      setToggleButtonSelected({ [id]: false });
      setIdToProfile((prevData) => ({
        ...prevData,
        [userData?._id]: userData?.profilePhoto,
      }));
      getAllMessage(chatBoxId);
      setSelectedCandidate({ [id]: true });
    } else {
      setSelectedCandidate({ [id]: false });
    }
    setChatButtonSelected({ [id]: !chatButtonSelected[id] });
  };

  async function handleSkillsSearch(e) {
    setSkillSearchValue(e.target.value);
    if (e.target.value.length === 1) {
      const param = e.target.value.toLowerCase();
      const { data } = await axios.get(`talentPool/find_skills/${param}`, {
        headers: { authorization: "Bearer " + Marketer.token },
      });
      setSuggestedSkills(data?.data);
    } else {
      let skills = suggestedSkills.filter((key) =>
        key.name.toLowerCase().startsWith(e.target.value.toLowerCase())
      );
      setSuggestedSkills(skills);
    }
  }

  async function handleTagsSearch(e) {
    setTagSearchValue(e.target.value);
    console.log("the value is -", e.target.value);
    if (e.target.value.length === 1 && e.target.value !== " ") {
      const param = e.target.value.toLowerCase();
      const { data } = await axios.get(`talentPool/find_tags/${param}`, {
        headers: { authorization: "Bearer " + Marketer.token },
      });
      setSuggestedTags(data?.data);
    } else {
      let tags = suggestedTags.filter((key) =>
        key.name.toLowerCase().startsWith(e.target.value.toLowerCase())
      );
      setSuggestedTags(tags);
    }
  }

  async function addNewTag(e, id) {
    if (e.key === "Enter") {
      let { data } = await axios.post(
        `/talentPool/add_tag`,
        { name: e.target.value },
        { headers: { authorization: "Bearer " + Marketer.token } }
      );

      addedUser?.map((user) => {
        if (user._id === id) {
          user?.tags?.push(data?.data);
        }
      });
      setTagSearchValue("");
    }
  }

  async function selectSuggestedTag(id, tag) {
    addedUser?.map((user) => {
      if (user._id === id) {
        user?.tags?.push(tag);
      }
    });
    setTagSearchValue("");
  }

  async function selectSuggestedSkill(id, skill) {
    addedUser?.map((user) => {
      if (user._id === id) {
        user?.skills?.push(skill);
      }
    });
    setSkillSearchValue("");
  }

  async function removeExistingTag(id, tag) {
    addedUser?.map((user) => {
      if (user._id === id) {
        const requiredTags = user?.tags?.filter(
          (existingTag) => existingTag._id !== tag._id
        );
        user.tags = [];
        user.tags = requiredTags;
      }
    });
    setStateForNormal(!stateForNormal);
  }

  async function removeExistingSkill(id, skill) {
    addedUser = addedUser?.map((user) => {
      if (user._id === id) {
        user.skills = user.skills.filter(
          (existingSkill) => existingSkill._id !== skill._id
        );
        return user;
      }
    });
    setStateForNormal(!stateForNormal);
  }

  async function handleDeleteAddedUser(id) {
    let { data } = await axios.delete(
      `/talentPool/delete_talent_pool_users/${id}`,
      { headers: { authorization: "Bearer " + Marketer.token } }
    );
    setcopyUserData(copyUserData.filter((user) => user?._id !== id));
    setDeleteButtonSelected({ [id]: false });
  }

  const handleSubmitComment = async (id) => {
    try {
      const { data } = await axios.post(
        `/talentPool/getRejectionComment/${id}`,
        {
          rejectionComment: comment[id],
        },
        { headers: { authorization: "Bearer " + Marketer.token } }
      );
      setCommentButtonSelected({ [id]: false });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Talent pool user not found.");
      } else {
        console.error("An error occurred while submitting the comment:", error);
      }
    }
  };

  const handleSubmitData = async (user) => {
    const { data } = await axios.post(
      `/talentPool/update_talent_pool_users/${user?._id}`,
      {
        is_top_candidate: isTopCandidate[user?._id],
        skills: user?.skills,
        tags: user?.tags,
        user_price: userPrice[user?._id],
      },
      { headers: { authorization: "Bearer " + Marketer.token } }
    );

    setToggleButtonSelected({ [user?._id]: false });
    const updatedAddedUser = addedUser?.map((added) => {
      if (added._id === user._id) {
        added = data?.data;
      }
      return added;
    });

    setcopyUserData(updatedAddedUser);
    setAddedUser(updatedAddedUser);
    setSelectedCandidate({ [user?._id]: false });
    setFilterValue([]);
  };

  const getAllMessage = async (id) => {
    const { data } = await axios.get(`/talentPool/chat/get_messages/${id}`, {
      headers: { authorization: "Bearer " + Marketer.token },
    });

    setChatMessages(data?.data);
  };

  const addFilter = (filter) => {
    setFilterValue((prevData) => [...prevData, filter]);
    setProfileDropdown(false);
  };

  const removeFilter = (filter) => {
    setFilterValue((prevData) =>
      prevData.filter((element) => element !== filter)
    );
  };

  useEffect(() => {
    if (filterValue.length > 0) {
      let filterData = addedUser?.filter((element) =>
        filterValue.includes(element?.current_status)
      );
      setcopyUserData(filterData);
    } else {
      setcopyUserData(addedUser);
    }
  }, [filterValue]);
  return (
    <>
      <div className="filterTab">
        <IoFunnelOutline />
        <p>Filtered by</p>
        <div className="profileStatusDropdown">
          <p>Profile Status</p>
          <div onClick={() => setProfileDropdown(!profileDropdown)}>
            {profileDropdown ? <MdExpandLess /> : <MdExpandMore />}
          </div>

          {profileDropdown && (
            <div className="variousProfileStatus">
              <div className="statusName" onClick={() => addFilter("ACTIVE")}>
                {" "}
                Active{" "}
              </div>
              <div
                className="statusName"
                onClick={() => addFilter("INVITE_SENT")}
              >
                {" "}
                Invite Sent{" "}
              </div>
              <div className="statusName" onClick={() => addFilter("INACTIVE")}>
                {" "}
                Inactive{" "}
              </div>
              <div className="statusName" onClick={() => addFilter("DELETED")}>
                {" "}
                Deleted{" "}
              </div>
              <div
                className="statusName"
                onClick={() => addFilter("ADD-INVITE")}
              >
                {" "}
                Add-Invite{" "}
              </div>
              <div className="statusName" onClick={() => addFilter("REJECTED")}>
                {" "}
                Rejected{" "}
              </div>
            </div>
          )}
        </div>

        {filterValue?.map((filter) => {
          return (
            <div className="selectedStatusName">
              {filter}

              <RxCross2
                className="crossIcon"
                onClick={() => removeFilter(filter)}
              ></RxCross2>
            </div>
          );
        })}
      </div>

      <AddNewCandidate
        setcopyUserData={setcopyUserData}
        setAddedUser={setAddedUser}
        selectedCategory={selectedCategory}
      ></AddNewCandidate>

      <div className="talentPool_table width-full">
        <div class="grid-container">
          <div class="grid-item-1 table-headings">Candidates</div>
          <div class="grid-item-2 table-headings">Profile Status</div>
          <div class="grid-item-3 table-headings">Actions</div>
        </div>

        {copyUserData?.map((user) => {
          return (
            <>
              <div class="grid-container">
                <div class="grid-item-1">
                  <div
                    className="request-header-prof"
                    style={{
                      backgroundColor: selectedCandidate[user?._id]
                        ? "#E0E0E0"
                        : "white",
                    }}
                  >
                    <div className="profile-img"></div>
                    <p>
                      {user?.user_id?.name} |{" "}
                      <span>{user?.user_id?.email}</span>
                    </p>
                  </div>
                </div>
                <div class="grid-item-2">
                  {user?.current_status === "INVITE_SENT" ? (
                    hoverInviteSent[user?._id] ? (
                      <TwoButtonWrapper
                        firstComponent={
                          <span class="material-symbols-outlined">refresh</span>
                        }
                        secondComponent={"Resent Invite"}
                        backgroundColor={`white`}
                        color={"black"}
                        padding={"10px 20px"}
                        onMouseLeave={() =>
                          setHoverInviteSent({ [user?._id]: false })
                        }
                      />
                    ) : (
                      <TwoButtonWrapper
                        firstComponent={
                          <span class="material-symbols-outlined">
                            app_badging
                          </span>
                        }
                        secondComponent={"Invite Sent"}
                        backgroundColor={`white`}
                        color={"black"}
                        padding={"10px 20px"}
                        onMouseEnter={() =>
                          setHoverInviteSent({ [user?._id]: true })
                        }
                      />
                    )
                  ) : user?.current_status === "ACTIVE" ? (
                    <TwoButtonWrapper
                      firstComponent={
                        <MdOutlineAdjust style={{ color: "green" }} />
                      }
                      secondComponent={"Active"}
                      backgroundColor={`white`}
                      color={"black"}
                      padding={"10px 20px"}
                    />
                  ) : user?.current_status === "INACTIVE" ? (
                    <TwoButtonWrapper
                      firstComponent={
                        <MdOutlineAdjust style={{ color: "gray" }} />
                      }
                      secondComponent={"Inactive"}
                      backgroundColor={`white`}
                      color={"black"}
                      padding={"10px 20px"}
                    />
                  ) : user?.current_status === "ADD-INVITE" ? (
                    <TwoButtonWrapper
                      firstComponent={
                        <MdOutlineAdjust style={{ color: "gray" }} />
                      }
                      secondComponent={"Add-Invite"}
                      backgroundColor={`white`}
                      color={"black"}
                      padding={"10px 20px"}
                    />
                  ) : user?.current_status === "REJECTED" ? (
                    <TwoButtonWrapper
                      firstComponent={
                        <MdOutlineAdjust style={{ color: "gray" }} />
                      }
                      secondComponent={"Rejected"}
                      backgroundColor={`white`}
                      color={"black"}
                      padding={"10px 20px"}
                    />
                  ) : null}
                </div>
                <div class="grid-item-3 actionButtons">
                  <TwoButtonWrapper
                    firstComponent={
                      <span class="material-symbols-outlined">chat</span>
                    }
                    backgroundColor={`${
                      chatButtonSelected[user?._id] ? "#F0F0F0" : "white"
                    }`}
                    color={"black"}
                    padding={"0 20px"}
                    componentFunction={() =>
                      handleChatButton(
                        user?._id,
                        user?.user_id,
                        user?.chat_inbox
                      )
                    }
                  />

                  <TwoButtonWrapper
                    firstComponent={
                      user?.is_top_candidate ? (
                        <img
                          src={UnispadeLogoBlack}
                          style={{ height: "18px" }}
                        ></img>
                      ) : (
                        <img
                          src={UnispadeLogoGray}
                          style={{ height: "18px" }}
                        ></img>
                      )
                    }
                    backgroundColor={`white`}
                    color={"red"}
                    padding={"0 20px"}
                  />

                  <TwoButtonWrapper
                    firstComponent={<img src={reviewsIcon}></img>}
                    backgroundColor={`${
                      commentButtonSelected[user?._id] ? "#F0F0F0" : "white"
                    }`}
                    color={"black"}
                    padding={"0 20px"}
                    componentFunction={() => handleCommentButton(user?._id)}
                  />

                  <TwoButtonWrapper
                    firstComponent={<MdDelete />}
                    backgroundColor={`${
                      deleteButtonSelected[user?._id] ? "#F0F0F0" : "white"
                    }`}
                    color={"black"}
                    padding={"0 20px"}
                    componentFunction={() => handleDeleteButton(user?._id)}
                  />

                  <TwoButtonWrapper
                    firstComponent={
                      toggleButtonSelected[user?._id] ? (
                        <MdExpandLess />
                      ) : (
                        <MdExpandMore />
                      )
                    }
                    backgroundColor={`${
                      toggleButtonSelected[user?._id] ? "#F0F0F0" : "white"
                    }`}
                    color={"black"}
                    padding={"0 20px"}
                    componentFunction={() => handleToggleButton(user?._id)}
                  />
                </div>
              </div>

              {deleteButtonSelected[user?._id] && (
                <div className="deleteDropdownComponent">
                  <DropdownContentArea padding={"20px 20px"} height={"60px"}>
                    <p>
                      Are you sure you want to remove this candidate from your
                      talent pool?
                    </p>
                  </DropdownContentArea>
                  <TwoButtonWrapper
                    firstComponent={"Remove"}
                    componentFunction={() => handleDeleteAddedUser(user?._id)}
                  ></TwoButtonWrapper>
                </div>
              )}

              {commentButtonSelected[user?._id] && (
                <DropdownContentArea>
                  <div className="inviteDetailContainer">
                    <div className="verticalLabelInput">
                      <label htmlFor="candidateComments">Comments</label>

                      <textarea
                        name="candidateComments"
                        id="candidateComments"
                        className="detailInput userComments"
                        rows="4"
                        defaultValue={rejectionComment[user?._id]}
                        value={comment[user?._id]}
                        onChange={(e) =>
                          setComment({ [user?._id]: e.target.value })
                        }
                      ></textarea>
                    </div>

                    <div className="inviteDetailButtons">
                      <TwoButtonWrapper
                        firstComponent={"Cancel"}
                        height={"45px"}
                        borderRadius={"10px"}
                        backgroundColor={"white"}
                        color={"black"}
                        componentFunction={() => handleCommentButton(user?._id)}
                      ></TwoButtonWrapper>

                      <TwoButtonWrapper
                        firstComponent={"Save"}
                        height={"45px"}
                        borderRadius={"10px"}
                        backgroundColor={"black"}
                        color={"white"}
                        componentFunction={() => handleSubmitComment(user?._id)}
                      ></TwoButtonWrapper>
                    </div>
                  </div>
                </DropdownContentArea>
              )}

              {chatButtonSelected[user?._id] && (
                <ChatBoxComponent
                  chatMessages={chatMessages}
                  setChatMessages={setChatMessages}
                  user_id={Marketer?.user?._id}
                  chatBoxId={user?.chat_inbox}
                  type={"talentPool"}
                  idToProfile={idToProfile}
                ></ChatBoxComponent>
              )}

              {toggleButtonSelected[user?._id] && (
                <DropdownContentArea>
                  <div className="inviteDetailContainer">
                    <div className="detailColumns">
                      <div className="horizontalLabelInput">
                        <label htmlFor="userName">Custom Profile Pricing</label>
                        <input
                          type="number"
                          className="detailInput userPrice"
                          defaultValue={user?.user_price}
                          value={userPrice[user?._id]}
                          onChange={(e) =>
                            setUserPrice({ [user?._id]: e.target.value })
                          }
                        />
                      </div>

                      <div className="horizontalLabelInput">
                        <label htmlFor="userName">
                          Make them the top candidate in your pool
                        </label>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <ToggleSwitch
                            isTopCandidate={isTopCandidate[user?._id]}
                            user_id={user?._id}
                            setIsTopCandidate={setIsTopCandidate}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="detailColumns">
                      <div className="verticalLabelInput">
                        <label htmlFor="addSkills">Add Skills</label>
                        <div className="skillAndCustomTags">
                          <div className="seachBarWithSuggestion">
                            <div className="searchBar">
                              <img src={magnify} alt="" />
                              <input
                                type="text"
                                placeholder="Add skills"
                                value={skillSearchValue}
                                onChange={(e) => handleSkillsSearch(e)}
                              />
                            </div>

                            {suggestedSkills.length > 0 &&
                              skillSearchValue.length > 0 && (
                                <div className="suggestionBox">
                                  {suggestedSkills?.map((element) => {
                                    return (
                                      <div
                                        className="skillOrTagItem"
                                        style={{
                                          backgroundColor:
                                            element?.backgroundColor,
                                          border: `1px solid ${element?.borderColor}`,
                                        }}
                                        onClick={() =>
                                          selectSuggestedSkill(
                                            user?._id,
                                            element
                                          )
                                        }
                                      >
                                        {element.name}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                          </div>

                          <div className="skillAndTagContainer">
                            {user?.skills?.map((skill) => {
                              return (
                                <div
                                  className="skillOrTagItem"
                                  style={{
                                    backgroundColor: skill?.backgroundColor,
                                    border: `1px solid ${skill?.borderColor}`,
                                  }}
                                >
                                  {skill?.name}
                                  <RxCross2
                                    className="crossIcon"
                                    onClick={() =>
                                      removeExistingSkill(user?._id, skill)
                                    }
                                  ></RxCross2>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="verticalLabelInput">
                        <label htmlFor="customTags">Custom Tags</label>
                        <div className="skillAndCustomTags">
                          <div className="seachBarWithSuggestion">
                            <div className="searchBar">
                              <img src={magnify} alt="" />
                              <input
                                type="text"
                                placeholder="Add tags"
                                value={tagSeachValue}
                                onChange={(e) => handleTagsSearch(e)}
                                onKeyDown={(e) => addNewTag(e, user._id)}
                              />
                            </div>
                            {suggestedTags.length > 0 &&
                              tagSeachValue.length > 0 && (
                                <div className="suggestionBox">
                                  {suggestedTags?.map((element) => {
                                    return (
                                      <div
                                        className="skillOrTagItem"
                                        style={{
                                          backgroundColor:
                                            element?.backgroundColor,
                                          border: `1px solid ${element?.borderColor}`,
                                        }}
                                        onClick={() =>
                                          selectSuggestedTag(user._id, element)
                                        }
                                      >
                                        {element.name}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                          </div>

                          <div className="skillAndTagContainer">
                            {user?.tags?.map((tag) => {
                              return (
                                <div
                                  className="skillOrTagItem"
                                  style={{
                                    backgroundColor: tag?.backgroundColor,
                                    border: `1px solid ${tag?.borderColor}`,
                                  }}
                                >
                                  {tag?.name}
                                  <RxCross2
                                    className="crossIcon"
                                    onClick={() =>
                                      removeExistingTag(user?._id, tag)
                                    }
                                  ></RxCross2>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inviteDetailButtons">
                      <TwoButtonWrapper
                        firstComponent={"Cancel"}
                        height={"45px"}
                        borderRadius={"10px"}
                        backgroundColor={"white"}
                        color={"black"}
                        componentFunction={() => handleToggleButton(user?._id)}
                      ></TwoButtonWrapper>

                      <TwoButtonWrapper
                        firstComponent={"Save"}
                        height={"45px"}
                        borderRadius={"10px"}
                        backgroundColor={"black"}
                        color={"white"}
                        componentFunction={() => handleSubmitData(user)}
                      ></TwoButtonWrapper>
                    </div>
                  </div>
                </DropdownContentArea>
              )}
            </>
          );
        })}
      </div>
    </>
  );
};
