import React, { useEffect, useState } from "react";
import "../../styles/SapAgencies.css";
import searchgrey from "../../images/searchgrey.png";
import blogworks from "../../images/blogworks.png";
import axios from "../../axios";
import { useHistory, Link } from "react-router-dom";
import Login from "../auth/Login";

function SapLeads() {
  const [arrow, setArrow] = useState(">");
  const handleClick = (e) => {
    var marketingContent = document.getElementById(e.target.name);
    if (marketingContent.classList.contains("display")) {
      marketingContent.classList.remove("display");
    } else {
      marketingContent.classList.add("display");
    }
  };
  const history = useHistory();

  const [allLeads, setAllLeads] = useState([]);
  // Get api data
  useEffect(async () => {
    try {
      const res = await axios.get("/superAdmin/leads");
      setAllLeads(res.data.leadsdata);
      console.log(res.data.leadsdata);
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <div className="sapagencies">
      <div className="sapagencies__top">
        <form className="sapagencies__data__inpcont">
          <img className="search__icon" src={searchgrey} alt="" />
          <input className="search__input" placeholder="Search" />
        </form>
        <select className="sap__select">
          <option defaultValue="Sort">Sort</option>
          <option>Popular</option>
          <option>Popular</option>
        </select>
      </div>
      <div className="saptable__lists casestudy sapservicestable">
        <table className="pricing__table">
          <tbody className="">
            <tr className="table__heading__row ">
              <th className="heading__row__part">
                <p className="theading "></p>
              </th>
              <th className="heading__row__part">
                <p className="theading ">Brand</p>
              </th>
              <th className="heading__row__part">
                <p className="theading ">Mail ID</p>
              </th>
              <th className="heading__row__part">
                <p className="theading ">Service(s)</p>
              </th>
              <th className="heading__row__part">
                <p className="theading ">Brief</p>
              </th>
              <th className="heading__row__part">
                <p className="theading ">Agency</p>
              </th>
            </tr>
            {allLeads.map((a, i) => (
              <React.Fragment>
                <tr className="text__center saptablerow">
                  <td className="para adgrey image__name__cont pad2row textleftfirr">
                    {a.details.name}
                  </td>
                  <td className="para adgrey pad2row">{a.brandname}</td>
                  <td className="para adgrey pad2row">{a.details.mail}</td>

                  <td className="para adgrey pad2row">
                    {a.data.services.map((s) => (
                      <p>{s}</p>
                    ))}
                  </td>
                  <td className="para adgrey pad2row">
                    <div className="dflex align__center justify__center">
                      <p className="para color__orange cursor__pointer">
                        View Brief
                      </p>
                    </div>
                  </td>
                  <td className="para adgrey pad2row">
                    <div className="dflex align__center justify__center">
                      <input
                        className="para color__orange cursor__pointer rotate90"
                        onClick={(e) => {
                          handleClick(e);
                          if (e.target.value === ">") {
                            e.target.value = "<";
                          } else {
                            e.target.value = ">";
                          }
                        }}
                        name={i}
                        value={arrow}
                        type="button"
                        style={{ background: "#f8f8f8" }}
                      />
                    </div>
                  </td>
                </tr>

                <tr className="marketing-content display" id={i}>
                  {a.data.agencies.map((agen) => (
                    <td className="para adgrey pad2row">
                      <p>{agen}</p>
                    </td>
                  ))}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SapLeads;
