import React from 'react'
import './DeleteConfirmation.css'

const DeleteConfirmation = ({ dialog, dialogRef, removeFunc, id }) => {

    function handleCloseDialog() {
        dialog.close();
    }

    return (
        <div className='deleteConfirmDialog'>
            <dialog ref={dialogRef} className='deleteDialog'>
                <p>Are you sure you want to delete?</p>
                <div className='deleteConfirmationBtns'>
                    <button type='button' className='dialogCloseBtn dialogButton' onClick={handleCloseDialog}>Cancel</button>
                    <button type='button' className='dialogDeleteBtn dialogButton' onClick={() => removeFunc(id)}>Delete</button>
                </div>
            </dialog>
        </div>
    )
}

export default DeleteConfirmation