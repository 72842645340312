import React from "react";
import { useHistory } from "react-router-dom";
import { Redirect } from '../images';
import './bottomComp.css';

const BottomComp = () => {
  return (
    <div className="bottomComp">
      <div className="HPHeading">
        <h2>
          Unispade’s talent network is growing at a rate of 300% per month,
          providing an ever-expanding pool of quality candidates
        </h2>
      </div>
      <p className="respPHidden">
        100+ industry experts have joined Unispade, and more are set to come.
      </p>
      <p>
        Endorse the top talent in your network and re-shape the workforce of the
        future.
      </p>
      <a href="https://forms.gle/io3mjcjwLZgwuLqi9" target="blank">
        <button className="exploreTalentBtn">
          Explore Talent
          <img
            src={Redirect}
            alt="Explore Talent Icon"
            width="20"
            height="20"
          />
        </button>
      </a>
    </div>
  );
};

export default BottomComp;
