import React from "react";
import "./NewLoader.css"
const NewLoader = () => {
  return (
    <div className="NewLoaderPage">
      <div id="new-loader-container">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/otp-sender-382116.appspot.com/o/blogs%2Fimage_20230730_055928_69fce_Favicon.png?alt=media&token=83091f55-d303-44ce-853c-509ef4ecc269"
          alt="Logo"
          width="100"
          height="100"
        />
      </div>
    </div>
  );
};

export default NewLoader;