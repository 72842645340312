import React, { useState, useEffect } from "react";
import "../styles/SearchPage.css";
import searchicon from "../images/searchicon.png";
import arrowdown from "../images/arrowdown.png";
import scrolltotop from "../images/scrolltotop.png";
import digmark from "../images/digmark.png";
import mobad from "../images/mobad.png";
import ResCard from "./ResCard";
import Select from "react-select";
import { useParams, useHistory, Link } from "react-router-dom";
// import Select2 from "react-select2";
import axios from "../axios.js";
import { Helmet } from "react-helmet"
import Error404 from "./Error404";
import ReactPaginate from 'react-paginate';


function SearchPage() {
  const pageSize = 50;
  const history = useHistory();
  const [filter, setFilter] = useState({
    service: "",
    location: "",
    minSize: 0,
    maxSize: 1000000,
    industry: "",
    pageSize: pageSize,
    page: 1,
  });
  const [results, setResults] = useState([]);
  const [pageTitle, setPageTitle] = useState("");
  const [flag, setFlag] = useState(false);

  const [locationOptions, setLocationOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [titleservice, settitleService] = useState("");
  const [titlelocation, settitlelocation] = useState("");
  const searchService = useParams();
  const [pageContent, setPageContent] = useState("");
  const [totalAgencies, setTotalAgencies] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (!searchService.servicePageKeyword.includes("-")) {
      history.replace("/");
    } else {
      let location = '';
      let service = '';
      let searchedKeyword = searchService.servicePageKeyword;
      let temp = '';
      if (!searchedKeyword.includes("-in")) {
        temp = searchedKeyword.split("-agencies");
        service = temp[0]?.split(' ').join('-');
        location = '';
      } else {
        temp = searchedKeyword.split("-agencies-in-");
        service = temp[0]?.split(' ').join('-') // web_design
        location = capitalize(temp[1]?.replace("_", " ")); // new_york
      }
      settitleService(service)
      settitlelocation(location)
      setFilter((p) => {
        return {
          ...p,
          service: service,
          location: location,
        };
      });

      const fetchServicePage = async () => {
        try {
          const res = await axios.post("/superAdmin/servicePage/getPageTitle", {
            service,
            location,
          });
          setPageTitle(res.data);
          if (res.data === null) {
            setPageTitle("Not a service page");
            setResults([]);
          }
        } catch (er) {
          console.log(er);
        }
      };

      fetchServicePage();
      fetchServicePageDetails(service, location)
    }
  }, [searchService]);

  useEffect(() => {
    if (filter.service !== "") fetchData()
  }, [filter])

  const fetchData = async () => {
    try {
      let payload = {};
      let filterCopy = { ...filter };
      let serviceVar = "";
      if (filterCopy.service === "seo") {
        serviceVar = "seo"
      } else if (filterCopy.service === "web-design") {
        serviceVar = "website design"
      } else if (filterCopy.service === "content-writing") {
        serviceVar = "content writing"
      } else if (filterCopy.service === "social-media-marketing") {
        serviceVar = "social media marketing"
      } else if (filterCopy.service === "graphic-design") {
        serviceVar = "graphic designing"
      } else if( filterCopy.service === "digital-marketing"){
            serviceVar = "digital marketing"
      } else{
        serviceVar = filterCopy.service
      }

      filterCopy.services = filterCopy.service === "" ? [] : [serviceVar];
      filterCopy.locations = filterCopy.location === "" ? [] : [filterCopy.location];

      Object.keys(filterCopy).forEach(key => {
        if(key !== "service" && key !== "location"){
          if (filterCopy[key].constructor === Array) {
            if (filterCopy[key].length !== 0) {
              payload[key] = filterCopy[key]
            }
          } else {
            payload[key] = filterCopy[key]
          }
        }
      })
      // const response = await axios.post("/filter/", {
      //   services: [service],
      //   locations: [location],
      //   minSize: 0,
      //   maxSize: 10000000,
      //   industry: "",
      // });
      const response = await axios.post("/getAllagencies", payload);
      setTotalAgencies(response.data.totalResults);
      let totalPagesCalc = response.data.totalResults / pageSize;
      setTotalPages(Math.ceil(totalPagesCalc));
      setResults(response.data.agencies);
      setFlag(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handlePageClick = (page) => {
    let filterCopy = { ...filter };
    filterCopy.page = page.selected + 1;
    setFilter(filterCopy);
  }

  const fetchServicePageDetails = async (service, location) => {
    try {
      let serviceVar = "";
      let wordArr = service.split('-');
      wordArr.forEach(word => {
        serviceVar += " " + word[0].toUpperCase() + word.slice(1, word.length);
      })
      serviceVar.trim();

      if (service === "seo") {
        serviceVar = "seo"
      } else if (service === "web-design") {
        serviceVar = "Web Design"
      } else if (service === "content-writing") {
        serviceVar = "content writing"
      } else if (service === "social-media-marketing") {
        serviceVar = "social media marketing"
      } else if (service === "graphic-design") {
        serviceVar = "Graphic Design"
      } else if( service === "digital-marketing"){
        serviceVar = "digital marketing"
      }

      const url = `/superAdmin/getservicePageDetails?service=${serviceVar}${location !== '' ? "&location=" + location : ''}`
      const res = await axios.get(url);
      const pageData = res.data.content;
      setPageContent(pageData);
    }
    catch (err) {
      console.log(err)
    }
  }

  const [param, setParam] = useState(true);
  const options = [
    { value: "Recommended", label: "Recommended" },
    { value: "Popular", label: "Popular" },
    { value: "Best", label: "Best" },
  ];
  const locationOption = [
    { value: "", label: "All" },
    { value: "delhi", label: "Delhi" },
    { value: "pune", label: "Pune" },
    { value: "chennai", label: "Chennai" },
    { value: "ujjain", label: "Ujjain" },
  ];
  const industryOption = [
    { value: "", label: "All" },
    { value: "computer", label: "Computer" },
    { value: "dairy", label: "Dairy" },
    { value: "cosmetics", label: "Cosmetics" },
    { value: "food", label: "Food" },
  ];

  const clientsOption = [
    { value: "", label: "All" },
    { value: "volvo", label: "Volvo" },
    { value: "saab", label: "Saab" },
    { value: "mercedes", label: "Mercedes" },
    { value: "audi", label: "Audi" },
  ];

  const servicesOption = [
    { value: "", label: "All" },
    { value: "advertising & marketing", label: "Advertising & Marketing" },
    { value: "design", label: "Design" },
    { value: "development", label: "Development" },
    { value: "public relation", label: "Public Relation" },
  ];

  const sizeOption = [
    { value: "", label: "All" },
    { value: "0-10", label: "0-10" },
    { value: "10-50", label: "10-50" },
    { value: "50-100", label: "50-100" },
    { value: "above 100", label: "above 100" },
  ];

  const [selectValues, setSelectValues] = useState({
    services: "All",
    location: "All",
    size: "All",
    industry: "All",
    clients: "All",
  });

  const [selectedServicesOption, setSelectedServicesOption] = useState(null);
  const [selectedLocationOption, setSelectedLocationOption] = useState(null);
  const [selectedSizeOption, setSelectedSizeOption] = useState(null);
  const [selectedIndustryOption, setSelectedIndustryOption] = useState(null);
  const [selectedClientsOption, setSelectedClientsOption] = useState(null);

  const [searched, setSearched] = useState(false);

  const [input, setInput] = useState("");

  // const changeInputHandler = (event) => {
  //   setInput(event.target.value);
  // };


  var description = "Looking to hire the reliable Best" + capitalize(titleservice) + "Company in" + capitalize(titlelocation) + "for your digital marketing project? Select the best company on the basis of their reviews, ratings and your requirement."
  // const submitFilter = async (event, action) => {
  //   if (searched === true) {
  //     resetFilters();
  //     setSearched(false);
  //   }
  //   if (searchService.service !== undefined && param === true) {
  //     resetFilters();
  //     setParam(false);
  //   }
  //   const name = action.name;
  //   const value = event.value;
  //
  //   if (name === "location") setSelectedLocationOption(event);
  //   else if (name === "services") setSelectedServicesOption(event);
  //   else if (name === "industry") setSelectedIndustryOption(event);
  //   else if (name === "clients") setSelectedClientsOption(event);
  //   await setFilter((prev) => {
  //     if (name !== "services") {
  //       return {
  //         ...prev,
  //         [name]: value,
  //       };
  //     } else {
  //       return {
  //         ...prev,
  //         services: [value],
  //       };
  //     }
  //   });
  // };

  useEffect(() => {
    let location = '';
    let service = '';
    let searchedKeyword = searchService.servicePageKeyword;
    let temp = '';
    if (!searchedKeyword.includes("-in")) {
      temp = searchedKeyword.split("-agencies");
      service = temp[0]?.split(' ').join('-');
      location = '';
    } else {
      temp = searchedKeyword.split("-agencies-in-");
      service = temp[0]?.split(' ').join('-') // web_design
      location = temp[1]?.replace("_", " "); // new_york
    }
    // var temp = searchService.servicePageKeyword.split("-agencies-in-");
    // var service = temp[0]?.split("-").join(" "); // web_design
    // var location = temp[1]?.replace("-", " "); // new_york

    const fetchFilterServices = async () => {
      try {
        const filterServices = await axios.get(
          "/superAdmin/filters/getAllServices"
        );
        var service123 = [];
        // setPresent(false);

        filterServices.data.selectedServices.forEach((s) => {
          var newObject = {
            name: s,
            tick: false,
          };
          if (service.toLowerCase() === newObject.name.toLowerCase()) {
            newObject.tick = true;
            // setPresent(true);
          }
          service123.push(newObject);
        });
        setServiceOptions(service123);
      } catch (e) {
        console.log(e);
      }
    };

    const fetchFilterLocations = async () => {
      try {
        const filterLocations = await axios.get(
          "/superAdmin/filters/getAllLocations"
        );
        var location123 = [];
        filterLocations.data.forEach((s) => {
          var newObject = {
            name: s,
            tick: false,
          };
          if (location.toLowerCase() === newObject.name.toLowerCase()) {
            newObject.tick = true;
          }
          location123.push(newObject);
        });
        setLocationOptions(location123);
      } catch (e) {
        console.log(e);
      }
    };

    const fetchFilterIndustries = async () => {
      try {
        const filterIndustries = await axios.get(
          "/superAdmin/filters/getAllIndustries"
        );
        var industry123 = [];
        filterIndustries.data.selectedIndustries.forEach((s) => {
          const newObject = {
            name: s,
            tick: false,
          };
          industry123.push(newObject);
        });
        setIndustryOptions(industry123);
      } catch (e) {
        console.log(e);
      }
    };
    ///////////////////////
    // const fetchFilterClients= async()=>{
    //   try {
    //     const filterClients= await axios.get("/superAdmin/filters/getAllClients")
    //     setClientsOptions(filterClients);
    //
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }
    fetchFilterServices();
    fetchFilterLocations();
    // fetchFilterClients();
    fetchFilterIndustries();
  }, []);

  function capitalize(input) {
    if (input === null || input === undefined || input === "") return input;
    var words = input.split(" ");
    var CapitalizedWords = [];
    words.forEach((element) => {
      CapitalizedWords.push(
        element[0].toUpperCase() + element.slice(1, element.length)
      );
    });
    return CapitalizedWords.join(" ");
  }

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.post("/filter/", filter);
  //       setResults(response.data);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };
  //   fetchData();
  // }, [filter]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
          in place of 'smooth' */
    });
  };

  // Scroll to top functionality starts
  const [sttvisible, setsttvisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setsttvisible(true);
    } else if (scrolled <= 300) {
      setsttvisible(false);
    }
  };

  const scrollToTop2 = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  //Scroll to top functionality ends

  //  Filters open functionality for mobile view starts
  // const [isOpenFilter, setIsOpenFilter] = useState(false);
  // const openFilterPart = () => {
  //   setIsOpenFilter(prev => setIsOpenFilter(!prev));
  // };
  //  Filters open functionality for mobile view ends

  //////////  Filters open functionality for mobile view starts////////
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const openFilterPart = () => {
    setIsOpenFilter((prev) => setIsOpenFilter(!prev));

    // closing all others open dropdown filters
    setIsOpenprotype(false);
    setIsOpenBrand(false);
    setIsOpenBudget(false);
    setIsOpenIndustry(false);
    setIsOpenClients(false);
  };

  const [isOpenprotype, setIsOpenprotype] = useState(false);
  const isProtype = () => {
    setIsOpenprotype((prev) => setIsOpenprotype(!prev));
  };
  const [isOpenBrand, setIsOpenBrand] = useState(false);
  const isBrandtype = () => {
    setIsOpenBrand((prev) => setIsOpenBrand(!prev));
  };
  const [isOpenBudget, setIsOpenBudget] = useState(false);
  const isBudgettype = () => {
    setIsOpenBudget((prev) => setIsOpenBudget(!prev));
  };
  const [isOpenIndustry, setIsOpenIndustry] = useState(false);
  const isIndustrytype = () => {
    setIsOpenIndustry((prev) => setIsOpenIndustry(!prev));
  };
  const [isOpenClients, setIsOpenClients] = useState(false);
  const isclientstype = () => {
    setIsOpenClients((prev) => setIsOpenClients(!prev));
  };
  //////////  Filters open functionality for mobile view ends //////////

  const resetHandler = () => {
    history.push("/search");
  };

  const redirectHandler = (e) => {
    const { name, value } = e.target;
    if (name === "service") {
      if (value === "All") {
        history.push(
          "/search?services=All&locations=" +
          filter.location +
          "&key=servicePage"
        );
      } else if (value === filter.service) {
        history.push(
          "/search?services=All&locations=" +
          filter.location +
          "&key=servicePage"
        );
      } else {
        history.push(
          "/search?services=" +
          filter.service +
          "," +
          value +
          "&locations=" +
          filter.location +
          "&key=servicePage"
        );
      }
    }
    if (name === "location") {
      if (value === "All") {
        history.push(
          "/search?services=" +
          filter.service +
          "&locations=All&key=servicePage"
        );
      } else if (value === filter.location) {
        history.push(
          "/search?services=" +
          filter.service +
          "&locations=delhi,All&industries=All&agencySize=All&key=servicePage"
        );
      } else {
        history.push(
          "/search?services=" +
          filter.service +
          "&locations=" +
          filter.location +
          "," +
          value +
          "&key=servicePage"
        );
      }
    }
    if (name === "size") {
      var minSize, maxSize;
      if (value === "All") {
        minSize = 0;
        maxSize = 1000000000;
      } else if (value === "0-10") {
        minSize = 0;
        maxSize = 10;
      } else if (value === "11-50") {
        minSize = 11;
        maxSize = 50;
      } else if (value === "51-100") {
        minSize = 51;
        maxSize = 100;
      } else if (value === "above 100") {
        minSize = 101;
        maxSize = 1000000000;
      }
      if (value === "All") {
        history.push(
          "/search?services=" +
          filter.service +
          "&locations=" +
          filter.location +
          "&key=servicePage"
        );
      } else {
        history.push(
          "/search?services=" +
          filter.service +
          "&locations=" +
          filter.location +
          "&minSize=" +
          minSize +
          "&maxSize=" +
          maxSize +
          "&key=servicePage"
        );
      }
    }
    if (name === "industry") {
      if (value === "All") {
        history.push(
          "/search?services=" +
          filter.service +
          "&locations=" +
          filter.location +
          "&industry=All&key=servicePage"
        );
      } else {
        history.push(
          "/search?services=" +
          filter.service +
          "&locations=" +
          filter.location +
          "&industry=" +
          value +
          "&key=servicePage"
        );
      }
    }
  };

  const removeDash = (titleservice) => {
    const arr = titleservice.split("-");
    const service = arr.join(' ');
    return service;
  }

  return (

    <div className="searchpage">
      {flag && results.length === 0 ? <h3><Error404></Error404></h3> :
        <div>
          <Helmet>
            <title> Top {capitalize(removeDash(titleservice))} Agencies {capitalize(titlelocation)}{" - 2023 Reviews | Unispade"}</title>
            <meta charSet="UTF-8" content={description} name="description" ></meta>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
          </Helmet>


          <img
            onClick={scrollToTop2}
            src={scrolltotop}
            className="scrolltotopbtn"
            alt=""
            style={{ display: sttvisible ? "inline" : "none" }}
          />


          <div className="sp__top">
            <h1 className="normal__headings" style={{ color: "white" }}>
              {`Best ${capitalize(titleservice).split('-').join(" ")} Agencies ${titlelocation === '' ? '' : 'in ' + capitalize(titlelocation)}`}
              {/* Best {capitalize(titleservice).split('-').join(" ")} Agencies in {capitalize(titlelocation)}{" "} */}
              {/*Here are{" "}
          <span className="color__orange fw__600">top agencies</span> matching
          your cirteria*/}
            </h1>
            <p className="color__white para">
              You can refine filters to find the best agency for your business needs
            </p>
          </div>

          {/*<div className="search__box__cont">
        <div className="search__box">
          <img src={searchicon} alt="" />
          <input
            value={input}
            type="text"
            onChange={changeInputHandler}
            placeholder="Search for services e.g. digital marketing, advertising and design"
          />
          <button onClick={searchHandler}>Search</button>
        </div>
      </div>*/}
          <div className="fil__res__cont">
            <div className="filter__sec">
              <div className="filter__top ">
                <p
                  className="text__left cursor__pointer fw__600"
                  onClick={openFilterPart}
                >
                  Filters{" "}
                  <img
                    className={`arrow__down ${isOpenFilter && "rotate180"}`}
                    src={arrowdown}
                  />
                </p>
                <button onClick={resetHandler} className="resetfilters">
                  Reset Filters
                </button>
              </div>
              <div
                className={`filter__parttab filter__pro__type  ${isOpenFilter && "dflex"
                  }`}
              >
                <div
                  className={`part__top cursor__pointer ${isOpenFilter && "dflex"}`}
                  onClick={isProtype}
                >
                  <p className="text__left">Services</p>{" "}
                  <img
                    className={`arrow__down ${isOpenprotype && "rotate180"}`}
                    src={arrowdown}
                  />
                </div>

                <div
                  className={`part__bottom  ${isOpenprotype ? "dflex" : "dnoneimp"
                    }`}
                  style={{ display: isOpenprotype ? "flex !important" : "none" }}
                >
                  <div className="inp__field">
                    <input
                      type="checkbox"
                      id="requested"
                      value="All"
                      name="service"
                      onChange={redirectHandler}
                    />
                    <label htmlFor="requested" className="para">
                      All
                    </label>
                  </div>
                  {serviceOptions?.map((s) => {
                    return (
                      <div className="inp__field">
                        <input
                          onChange={redirectHandler}
                          type="checkbox"
                          name="service"
                          value={s.name}
                          checked={s.tick}
                        />
                        <label htmlFor="serv" className="para">
                          {capitalize(s.name)}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className={`filter__parttab filter__pro__type  ${isOpenFilter && "dflex"
                  }`}
              >
                <div
                  className={`part__top cursor__pointer ${isOpenFilter && "dflex"}`}
                  onClick={isBrandtype}
                >
                  <p className="text__left">Location</p>{" "}
                  <img
                    className={`arrow__down ${isOpenBrand && "rotate180"}`}
                    src={arrowdown}
                  />
                </div>

                <div
                  className={`part__bottom  ${isOpenBrand ? "dflex" : "dnoneimp"}`}
                  style={{ display: isOpenBrand ? "flex !important" : "none" }}
                  onChange={redirectHandler}
                >
                  <div className="inp__field">
                    <input type="checkbox" id="any" name="location" value="All" />
                    <label htmlFor="any" className="para">
                      All
                    </label>
                  </div>
                  {locationOptions?.map((l) => {
                    return (
                      <div className="inp__field">
                        <input
                          name="location"
                          checked={l.tick}
                          type="checkbox"
                          id="loc"
                          value={l.name}
                        />
                        <label htmlFor="loc" className="para">
                          {capitalize(l.name)}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className={`filter__parttab filter__pro__type  ${isOpenFilter && "dflex"
                  }`}
              >
                <div
                  className={`part__top cursor__pointer ${isOpenFilter && "dflex"}`}
                  onClick={isBudgettype}
                >
                  <p className="text__left">Agency Size</p>{" "}
                  <img
                    className={`arrow__down ${isOpenBudget && "rotate180"}`}
                    src={arrowdown}
                  />
                </div>

                <div
                  className={`part__bottom  ${isOpenBudget ? "dflex" : "dnoneimp"}`}
                  style={{ display: isOpenBudget ? "flex !important" : "none" }}
                  onChange={redirectHandler}
                >
                  <div className="inp__field">
                    <input type="checkbox" id="alll" name="size" value="All" />
                    <label htmlFor="alll" className="para">
                      All
                    </label>
                  </div>
                  <div className="inp__field">
                    <input type="checkbox" id="0-10" name="size" value="0-10" />
                    <label htmlFor="0-10" className="para">
                      0-10
                    </label>
                  </div>
                  <div className="inp__field">
                    <input type="checkbox" id="10-50" name="size" value="11-50" />
                    <label htmlFor="11-50" className="para">
                      11-50
                    </label>
                  </div>
                  <div className="inp__field">
                    <input type="checkbox" id="50-100" value="51-100" name="size" />
                    <label htmlFor="51-100" className="para">
                      51-100
                    </label>
                  </div>
                  <div className="inp__field">
                    <input
                      type="checkbox"
                      id="above100"
                      value="above 100"
                      name="size"
                    />
                    <label htmlFor="above100" className="para">
                      above 100
                    </label>
                  </div>
                </div>
              </div>

              <div
                className={`filter__parttab filter__pro__type  ${isOpenFilter && "dflex"
                  }`}
              >
                <div
                  className={`part__top cursor__pointer ${isOpenFilter && "dflex"}`}
                  onClick={isIndustrytype}
                >
                  <p className="text__left">Industry</p>{" "}
                  <img
                    className={`arrow__down ${isOpenIndustry && "rotate180"}`}
                    src={arrowdown}
                  />
                </div>

                <div
                  className={`part__bottom  ${isOpenIndustry ? "dflex" : "dnoneimp"
                    }`}
                  style={{ display: isOpenIndustry ? "flex !important" : "none" }}
                  onChange={redirectHandler}
                >
                  <div className="inp__field">
                    <input type="checkbox" id="allll" name="industry" value="All" />
                    <label htmlFor="allll" className="para">
                      All
                    </label>
                  </div>
                  {industryOptions?.map((i) => {
                    return (
                      <div className="inp__field">
                        <input
                          type="checkbox"
                          id="ind"
                          name="industry"
                          value={i.name}
                        />
                        <label htmlFor="ind" className="para">
                          {capitalize(i.name)}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className={`filter__parttab filter__pro__type  ${isOpenFilter && "dflex"
                  }`}
              >
                <div
                  className={`part__top cursor__pointer ${isOpenFilter && "dflex"}`}
                  onClick={isclientstype}
                >
                  <p className="text__left">Clients</p>{" "}
                  <img
                    className={`arrow__down ${isOpenClients && "rotate180"}`}
                    src={arrowdown}
                  />
                </div>

                <div
                  onChange={resetHandler}
                  className={`part__bottom  ${isOpenClients ? "dflex" : "dnoneimp"
                    }`}
                  style={{ display: isOpenClients ? "flex !important" : "none" }}
                >
                  <div className="inp__field">
                    <input type="checkbox" id="alllll" />
                    <label htmlFor="alllll" className="para">
                      All
                    </label>
                  </div>
                  <div className="inp__field">
                    <input type="checkbox" id="volvo" />
                    <label htmlFor="volvo" className="para">
                      Volvo
                    </label>
                  </div>
                  <div className="inp__field">
                    <input type="checkbox" id="saab" />
                    <label htmlFor="saab" className="para">
                      Saab
                    </label>
                  </div>
                  <div className="inp__field">
                    <input type="checkbox" id="mercedes" />
                    <label htmlFor="mercedes" className="para">
                      Mercedes
                    </label>
                  </div>
                  <div className="inp__field">
                    <input type="checkbox" id="audi" />
                    <label htmlFor="audi" className="para">
                      Audi
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={`filter__sec  ${isOpenFilter && "showFilterPart"}`}>
          <div className="part1">
            <h5 onClick={openFilterPart} className="cursor__pointer">
              Filters{" "}
              <img
                src={arrowdown}
                className={`arrowdown ${isOpenFilter && "rotate180"}`}
                alt=""
              />
            </h5>

            <button onClick={resetFilters} className="resetfilters">
              Reset Filters
            </button>
          </div>
          <div className="part2">
            <p>Services</p>

            <form>
              <Select
                value={selectedServicesOption}
                onChange={submitFilter}
                name="services"
                id="cars"
                options={servicesOption}
                className="react__select"
              />
            </form>
          </div>
          <div className="part2">
            <p>Location</p>
            <form>
              <Select
                value={selectedLocationOption}
                onChange={submitFilter}
                name="location"
                id="cars"
                options={locationOption}
                className="react__select"
              />
            </form>
          </div>
          <div className="part2">
            <p>Agency Size</p>

            <form>
              <Select
                value={selectedSizeOption}
                onChange={submitSize}
                name="size"
                id="cars"
                options={sizeOption}
                className="react__select"
              />
            </form>
          </div>
          <div className="part2">
            <p>Industry</p>
            <form>
              <Select
                value={selectedIndustryOption}
                onChange={submitFilter}
                name="industry"
                id="cars"
                options={industryOption}
                className="react__select"
              />
            </form>
          </div>
          <div className="part2">
            <p>Clients</p>
            <form>
              <Select
                value={selectedClientsOption}
                onChange={submitFilter}
                name="clients"
                id="cars"
                options={clientsOption}
                className="react__select"
              />
            </form>
          </div>
        </div> */}
            <div className="search__res__sec">
              <div className="tot__res">
                <p className="para fw__600">{totalAgencies} Results</p>

                <div className="para col__grey sortbytext">
                  {" "}
                  <p>Sort by: </p>
                  <Select
                    className="react__select"
                    defaultValue={options[0]}
                    options={options}
                  />
                  {/* <select>
                <option>Recommended</option>
                <option>Popular</option>
              </select> */}
                </div>
              </div>
              <div className="search__res">

                {!flag && results.length === 0 && <h3>Loading...</h3>}
                {results.map((res) => (
                  res._id !== "61208e7061873d39a4fadd0e" && <ResCard agency={res} />
                ))}
                <ReactPaginate
                  breakLabel="....."
                  nextLabel=""
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={totalPages}
                  previousLabel=""
                  renderOnZeroPageCount={null}
                  className="pagination_sec"
                  pageClassName="pagination_sec_li"
                  breakClassName="pagination_sec_break"
                  activeClassName="pagination_sec_active"
                />
              </div>
            </div>
          </div>


          <div className="mar5__cont">
            <div className="searchagencies__cont">
              <div className="searchagencies">
                <p className="mwidthsmall">
                  Increase the chances of your project success, partner with the{" "}
                  <span className="color__orange">right agency</span>
                </p>
                <div>
                  <Link to="/search">
                    <button>Search Agencies</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>}
      <div className="service__page" dangerouslySetInnerHTML={{ __html: pageContent?.replaceAll("\\", "") }}>
        {/* <h3 className="oxblue">What is Digital Marketing?</h3>
        <img className="digmarkimg" src={digmark} alt="" />
        <div className="text__ad__cont mart2">
          <div className="part__left">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada
            tristique aenean elit non vitae vivamus. Maecenas luctus vel amet
            volutpat pharetra integer. Fringilla arcu, purus ipsum euismod
            vivamus. Curabitur turpis aenean fames egestas netus facilisis
            elementum. Quis metus pellentesque diam euismod nisl, adipiscing
            mauris mauris non. Tristique pellentesque cursus non cras morbi
            tempor. Tempor, et quam enim pellentesque. Vestibulum purus cras
            varius tincidunt vulputate egestas enim donec lorem. Aliquam, sapien
            sagittis sit vestibulum scelerisque malesuada tincidunt. Est arcu
            massa viverra aliquet. Quisque elementum sit pretium augue mauris.
            Faucibus eu scelerisque laoreet elementum malesuada pellentesque
            quam. Aliquet duis enim, tristique morbi tincidunt ut ut nec,
            lectus. Tellus eget massa purus, arcu, vitae etiam nibh. Nulla
            egestas vulputate neque id et. In lobortis eu nunc elementum a. Elit
            eget phasellus dolor a. Interdum sollicitudin orci odio elit
            interdum eget elementum. At tristique gravida euismod ultrices nibh
            amet urna sed a. Tellus bibendum ut consectetur consectetur sed
            pellentesque neque cursus eu. Sed convallis egestas egestas felis ac
            tellus curabitur id.
            <h3 className="width__100">Best Digital Marketing strategies</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada
              tristique aenean elit non vitae vivamus. Maecenas luctus vel amet
              volutpat pharetra integer. Fringilla arcu, purus ipsum euismod
              vivamus. Curabitur turpis aenean fames egestas netus facilisis
              elementum. Quis metus pellentesque diam euismod nisl, adipiscing
              mauris mauris non. Tristique pellentesque cursus non cras morbi
              tempor. Tempor, et quam enim pellentesque. Vestibulum purus cras
              varius tincidunt vulputate egestas enim donec lorem. Aliquam,
              sapien sagittis sit vestibulum scelerisque malesuada tincidunt.
              Est arcu massa viverra aliquet. Quisque elementum sit pretium
              augue mauris. Faucibus eu scelerisque laoreet elementum malesuada
              pellentesque quam. Aliquet duis enim, tristique morbi tincidunt ut
              ut nec, lectus. Tellus eget massa purus, arcu, vitae etiam nibh.
              Nulla egestas vulputate neque id et. In lobortis eu nunc elementum
              a.
            </p>
            <p className="readmore__btn para fw__600">Read More</p>
            <div className="banner-fadebottom"></div>
          </div>
          <div className="part__right">
            <img className="mobadimg" src={mobad} alt="" />
            <h3 className="oxblue text__center marl2 marr2">
              Download your free Agency Search Guide
            </h3>
            <p className="download__btn para text__center marl2 marr2 marb2">
              Download
            </p>
          </div>
        </div> */}
      </div>
      {/* <div className="mar5__cont">
        <div className="searchagencies__cont">
          <div className="searchagencies">
            <p className="mwidthsmall">
              Increase the chances of your project success, partner with the{" "}
              <span className="color__orange">right agency</span>
            </p>
            <div>
              <Link to="/search">
                <button>Search Agencies</button>
              </Link>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default SearchPage;
