import React, { useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { CiChat1 } from "react-icons/ci";
import {
  MdDoneAll,
  MdOutlineWavingHand,
  MdOutlineDone,
  MdExpandMore,
  MdDelete,
  MdExpandLess,
} from "react-icons/md";
import { MdOutlineAdjust } from "react-icons/md";

import { IoFunnelOutline } from "react-icons/io5";
import { IoMdRefresh } from "react-icons/io";
import "./InternalActivity.css";
import axios from "../../../../axios";
import TwoButtonWrapper from "../../../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import UnispadeLogo from "../../../../assets/Component 6.svg";
import reviewsIcon from "../../svg/reviews.svg";
import magnify from "../../svg/magnify.svg";
import { DropdownContentArea } from "../../../../atoms/DropdownContentArea";
import { ChatBoxComponent } from "../../../../atoms/ChatBoxComponent/ChatBoxComponent";
import ToggleSwitch from "../../../../atoms/ToggleSwitch/ToggleSwitch";
import UserInfoSlate from "../../../../atoms/UserInfoSlate/UserInfoSlate";

const variousStatus = [
  {
    _id: 1,
    name: "ACTIVE",
  },
  {
    _id: 2,
    name: "INACTIVE",
  },
  {
    _id: 3,
    name: "INVITE_SENT",
  },
  {
    _id: 4,
    name: "ADD-INVITE",
  },{
    _id: 5,
    name: "REJECTED",
  },
];

export const InternalActivity = () => {
  const [addedUser, setAddedUser] = useState([]);
  const [tempAddedUser, setTempAddedUser] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const [comment, setComment] = useState({});

  const [userPrice, setUserPrice] = useState({});
  const [isTopCandidate, setIsTopCandidate] = useState({});
  const [skillSearchValue, setSkillSearchValue] = useState("");
  const [tagSeachValue, setTagSearchValue] = useState("");
  const [suggestedSkills, setSuggestedSkills] = useState([]);
  const [suggestedTags, setSuggestedTags] = useState([]);

  const [chatMessages, setChatMessages] = useState([]);

  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [toggleButtonSelected, setToggleButtonSelected] = useState({});
  const [deleteButtonSelected, setDeleteButtonSelected] = useState({});
  const [commentButtonSelected, setCommentButtonSelected] = useState({});
  const [chatButtonSelected, setChatButtonSelected] = useState({});

  const [stateForNormal, setStateForNormal] = useState(false);
  const [hoverInviteSent, setHoverInviteSent] = useState({});

  const [providedFilterOptions, setProvidedFilterOptions] = useState();
  const [filterList, setFilterList] = useState([]);
  const [profileDropdown, setProflieDropdown] = useState({});

  const [requestHeaderHover, setRequestHeaderHover] = useState({});
  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  useEffect(() => {
    (async () => {
      try {
        let { data } = await axios.get(
          `/userActivity/get-internal-user-activity/${Marketer?.user?._id}`,
          { headers: { authorization: "Bearer " + Marketer.token } }
        );
        setAddedUser(data?.data);
        setTempAddedUser(data?.data);
        setProvidedFilterOptions(data?.filters);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    addedUser?.map((user) => {
      setIsTopCandidate((prevData) => ({
        ...prevData,
        [user?._id]: user?.is_top_candidate,
      }));
    });
  }, [addedUser]);

  const handleToggleButton = (id) => {
    if (!toggleButtonSelected[id]) {
      setDeleteButtonSelected({ [id]: false });
      setCommentButtonSelected({ [id]: false });
      setChatButtonSelected({ [id]: false });
      setSelectedCandidate({ [id]: true });
    } else {
      setSelectedCandidate({ [id]: false });
    }
    setToggleButtonSelected({ [id]: !toggleButtonSelected[id] });
  };
  const handleDeleteButton = (id) => {
    if (!deleteButtonSelected[id]) {
      setCommentButtonSelected({ [id]: false });
      setChatButtonSelected({ [id]: false });
      setToggleButtonSelected({ [id]: false });
      setSelectedCandidate({ [id]: true });
    } else {
      setSelectedCandidate({ [id]: false });
    }
    setDeleteButtonSelected({ [id]: !deleteButtonSelected[id] });
  };
  const handleCommentButton = (id) => {
    if (!commentButtonSelected[id]) {
      setDeleteButtonSelected({ [id]: false });
      setChatButtonSelected({ [id]: false });
      setToggleButtonSelected({ [id]: false });
      setSelectedCandidate({ [id]: true });
    } else {
      setSelectedCandidate({ [id]: false });
    }
    setCommentButtonSelected({ [id]: !commentButtonSelected[id] });
  };
  const handleChatButton = (id, chatBoxId) => {
    if (!chatButtonSelected[id]) {
      setDeleteButtonSelected({ [id]: false });
      setCommentButtonSelected({ [id]: false });
      setToggleButtonSelected({ [id]: false });
      getAllMessage(chatBoxId);
      setSelectedCandidate({ [id]: true });
    } else {
      setSelectedCandidate({ [id]: false });
    }
    setChatButtonSelected({ [id]: !chatButtonSelected[id] });
  };

  async function handleSkillsSearch(e) {
    setSkillSearchValue(e.target.value);
    if (e.target.value.length === 1) {
      const param = e.target.value.toLowerCase();
      const { data } = await axios.get(`talentPool/find_skills/${param}`, {
        headers: { authorization: "Bearer " + Marketer.token },
      });
      setSuggestedSkills(data?.data);
    } else {
      let skills = suggestedSkills.filter((key) =>
        key.name.toLowerCase().startsWith(e.target.value.toLowerCase())
      );
      setSuggestedSkills(skills);
    }
  }

  async function handleTagsSearch(e) {
    setTagSearchValue(e.target.value);
    console.log("the value is -", e.target.value);
    if (e.target.value.length === 1 && e.target.value !== " ") {
      const param = e.target.value.toLowerCase();
      const { data } = await axios.get(`talentPool/find_tags/${param}`, {
        headers: { authorization: "Bearer " + Marketer.token },
      });
      setSuggestedTags(data?.data);
    } else {
      let tags = suggestedTags.filter((key) =>
        key.name.toLowerCase().startsWith(e.target.value.toLowerCase())
      );
      setSuggestedTags(tags);
    }
  }

  async function addNewTag(e, id) {
    if (e.key === "Enter") {
      let { data } = await axios.post(
        `/talentPool/add_tag`,
        { name: e.target.value },
        { headers: { authorization: "Bearer " + Marketer.token } }
      );

      addedUser?.map((user) => {
        if (user._id === id) {
          user?.tags?.push(data?.data);
        }
      });
      setTagSearchValue("");
    }
  }

  async function selectSuggestedTag(id, tag) {
    addedUser?.map((user) => {
      if (user._id === id) {
        user?.tags?.push(tag);
      }
    });
    setTagSearchValue("");
  }

  async function selectSuggestedSkill(id, skill) {
    addedUser?.map((user) => {
      if (user._id === id) {
        user?.skills?.push(skill);
      }
    });
    setSkillSearchValue("");
  }

  async function removeExistingTag(id, tag) {
    addedUser?.map((user) => {
      if (user._id === id) {
        const requiredTags = user?.tags?.filter(
          (existingTag) => existingTag._id !== tag._id
        );
        user.tags = [];
        user.tags = requiredTags;
      }
    });
    setStateForNormal(!stateForNormal);
  }

  async function removeExistingSkill(id, skill) {
    addedUser = addedUser?.map((user) => {
      if (user._id === id) {
        user.skills = user.skills.filter(
          (existingSkill) => existingSkill._id !== skill._id
        );
        return user;
      }
    });
    setStateForNormal(!stateForNormal);
  }

  async function handleDeleteAddedUser(id) {
    let { data } = await axios.delete(
      `/talentPool/delete_talent_pool_users/${id}`,
      { headers: { authorization: "Bearer " + Marketer.token } }
    );
    setAddedUser(addedUser.filter((user) => user?._id !== id));
    setDeleteButtonSelected({ [id]: false });
  }

  const handleSubmitComment = async (id) => {
    try {
      const { data } = await axios.post(
        `/talentPool/getRejectionComment/${id}`,
        {
          rejectionComment: comment[id],
        },
        { headers: { authorization: "Bearer " + Marketer.token } }
      );
      setCommentButtonSelected({ [id]: false });
      setSelectedCandidate({});
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Talent pool user not found.");
      } else {
        console.error("An error occurred while submitting the comment:", error);
      }
    }
  };

  const handleSubmitData = async (user) => {
    const { data } = await axios.post(
      `/talentPool/update_talent_pool_users/${user?._id}`,
      {
        is_top_candidate: isTopCandidate[user?._id],
        skills: user?.skills,
        tags: user?.tags,
        user_price: userPrice[user?._id],
      },
      { headers: { authorization: "Bearer " + Marketer.token } }
    );

    setToggleButtonSelected({ [user?._id]: false });
    const updatedAddedUser = addedUser?.map((added) => {
      if (added._id === user._id) {
        added = data?.data;
      }
      return added;
    });
    setAddedUser(updatedAddedUser);
    setSelectedCandidate({ [user?._id]: false });
  };

  const getAllMessage = async (id) => {
    const { data } = await axios.get(`/talentPool/chat/get_messages/${id}`, {
      headers: { authorization: "Bearer " + Marketer.token },
    });

    setChatMessages(data?.data);
  };

  useEffect(() => {
    if (filterList.length === 0) {
      setTempAddedUser(addedUser);
    } else {
      const filteredData = applyFilters(addedUser, filterList);
      setTempAddedUser(filteredData);
    }
  }, [filterList]);

  const handleFilterDropdown = (filter) => {
    setProflieDropdown({
      [filter]: !profileDropdown[filter],
    });
  };
  const addFilter = (filterData, type) => {
    setFilterList((prevFilters) => [...prevFilters, { filterData, type }]);
    setProflieDropdown({});
  };
  const removeFilter = (filter_id) => {
    setFilterList((prevFilters) =>
      prevFilters.filter((item) => item.filterData._id !== filter_id)
    );
  };

  const applyFilters = (data, filters) => {
    let filteredData = data;

    filters.forEach(({ filterData: filterValue, type }) => {
      if (type === "PoolCategory") {
        filteredData = filteredData.filter(
          (element) => element?.category_id?._id === filterValue?._id
        );
      } else if (type === "Status") {
        filteredData = filteredData.filter(
          (element) => element?.current_status === filterValue?.name
        );
      }
    });

    return filteredData;
  };

  return (
    <div className="InternalActivityTab">
      <div className="filterSection">
        <div className="filterTab">
          <div className="filterIcon">
            <IoFunnelOutline />
            <p>Filtered By</p>
          </div>

          <div className="filteringOption">
            <div className="filterTabDropdown">
              <p>Pool Category</p>
              <div onClick={() => handleFilterDropdown("PoolCategory")}>
                {profileDropdown["PoolCategory"] ? (
                  <MdExpandLess />
                ) : (
                  <MdExpandMore />
                )}
              </div>
              {profileDropdown["PoolCategory"] && (
                <div className="variousOptions">
                  {providedFilterOptions["PoolCategory"]?.map((category) => {
                    return (
                      <div onClick={() => addFilter(category, "PoolCategory")}>
                        {category?.name}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <div className="filterTabDropdown">
              <p>Profile Status</p>
              <div onClick={() => handleFilterDropdown("Status")}>
                {profileDropdown["Status"] ? (
                  <MdExpandLess />
                ) : (
                  <MdExpandMore />
                )}
              </div>
              {profileDropdown["Status"] && (
                <div className="variousOptions">
                  {variousStatus?.map((status) => {
                    return (
                      <div onClick={() => addFilter(status, "Status")}>
                        {status?.name}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          {filterList?.length > 0 && (
            <>
              <div></div>
              <div className="selectedFilters">
                {filterList?.map((filter) => {
                  return (
                    <div className={`singleFilter ${filter?.type}`}>
                      {filter?.filterData?.name}
                      <RxCross2
                        className="crossIcon"
                        onClick={() => removeFilter(filter?.filterData?._id)}
                      ></RxCross2>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="talentPool_table width-full">
        <div class="grid-container">
          <div class="grid-item-1 table-headings">Candidates</div>
          <div class="grid-item-2 table-headings">Profile Status</div>
          <div class="grid-item-3 table-headings">Actions</div>
        </div>

        {tempAddedUser?.map((user) => {
          return (
            <>
              <div class="grid-container">
                <div class="grid-item-1">
                  <div
                    className="request-header-prof"
                    style={{
                      backgroundColor: selectedCandidate[user?._id]
                        ? "#E0E0E0"
                        : "white",
                    }}
                    onMouseEnter={() => setRequestHeaderHover({[user?._id]: true})}
                    onMouseLeave={() => setRequestHeaderHover({[user?._id]: false})}
                  >
                    <img
                      src={user?.user_id?.profilePhoto}
                      alt=""
                      className="profile-img"
                    />
                    <p>
                      {user?.user_id?.name} |{" "}
                      <span>{user?.user_id?.email}</span>
                    </p>

                    <div className="extraDetails">
                      <UserInfoSlate
                        user_name={user?.category_id?.name}
                        height="45px"
                        imageSize="30px"
                      ></UserInfoSlate>
                      {requestHeaderHover[user?._id] && (
                        <span class="material-symbols-outlined">
                          arrow_outward
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div class="grid-item-2">
                  {user?.current_status === "INVITE_SENT" ? (
                    hoverInviteSent[user?._id] ? (
                      <TwoButtonWrapper
                        firstComponent={
                          <span class="material-symbols-outlined">refresh</span>
                        }
                        secondComponent={"Resent Invite"}
                        backgroundColor={`white`}
                        color={"black"}
                        padding={"10px 20px"}
                        onMouseLeave={() => setHoverInviteSent({[user?._id]: false})}
                      />
                    ) : (
                      <TwoButtonWrapper
                        firstComponent={
                          <span class="material-symbols-outlined">
                            app_badging
                          </span>
                        }
                        secondComponent={"Invite Sent"}
                        backgroundColor={`white`}
                        color={"black"}
                        padding={"10px 20px"}
                        onMouseEnter={() => setHoverInviteSent({[user?._id]: true})}
                      />
                    )
                  ) : user?.current_status === "ACTIVE" ? (
                    <TwoButtonWrapper
                      firstComponent={
                        <MdOutlineAdjust style={{ color: "green" }} />
                      }
                      secondComponent={"Active"}
                      backgroundColor={`white`}
                      color={"black"}
                      padding={"10px 20px"}
                    />
                  ) : user?.current_status === "INACTIVE" ? (
                    <TwoButtonWrapper
                      firstComponent={
                        <MdOutlineAdjust style={{ color: "gray" }} />
                      }
                      secondComponent={"Inactive"}
                      backgroundColor={`white`}
                      color={"black"}
                      padding={"10px 20px"}
                    />
                  ) : user?.current_status === "ADD-INVITE" ? (
                    <TwoButtonWrapper
                      firstComponent={
                        <MdOutlineAdjust style={{ color: "gray" }} />
                      }
                      secondComponent={"Add-Invite"}
                      backgroundColor={`white`}
                      color={"black"}
                      padding={"10px 20px"}
                    />
                  ) : user?.current_status === "REJECTED" ? (
                    <TwoButtonWrapper
                      firstComponent={
                        <MdOutlineAdjust style={{ color: "gray" }} />
                      }
                      secondComponent={"Rejected"}
                      backgroundColor={`white`}
                      color={"black"}
                      padding={"10px 20px"}
                    />
                  ) : null}
                </div>
                <div class="grid-item-3 actionButtons">
                  <TwoButtonWrapper
                    firstComponent={<CiChat1 />}
                    backgroundColor={`${
                      chatButtonSelected[user?._id] ? "#F0F0F0" : "white"
                    }`}
                    color={"black"}
                    padding={"0 20px"}
                    componentFunction={() =>
                      handleChatButton(user?._id, user?.chat_inbox)
                    }
                  />

                  <TwoButtonWrapper
                    firstComponent={
                      <img
                        src={UnispadeLogo}
                        style={{ height: "20px", color: "red" }}
                      ></img>
                    }
                    backgroundColor={`white`}
                    color={"red"}
                    padding={"0 20px"}
                  />

                  <TwoButtonWrapper
                    firstComponent={<img src={reviewsIcon}></img>}
                    backgroundColor={`${
                      commentButtonSelected[user?._id] ? "#F0F0F0" : "white"
                    }`}
                    color={"black"}
                    padding={"0 20px"}
                    componentFunction={() => handleCommentButton(user?._id)}
                  />

                  <TwoButtonWrapper
                    firstComponent={<MdDelete />}
                    backgroundColor={`${
                      deleteButtonSelected[user?._id] ? "#F0F0F0" : "white"
                    }`}
                    color={"black"}
                    padding={"0 20px"}
                    componentFunction={() => handleDeleteButton(user?._id)}
                  />

                  <TwoButtonWrapper
                    firstComponent={<MdExpandMore />}
                    backgroundColor={`${
                      toggleButtonSelected[user?._id] ? "#F0F0F0" : "white"
                    }`}
                    color={"black"}
                    padding={"0 20px"}
                    componentFunction={() => handleToggleButton(user?._id)}
                  />
                </div>
              </div>

              {deleteButtonSelected[user?._id] && (
                <div className="deleteDropdownComponent">
                  <DropdownContentArea padding={"20px 20px"} height={"60px"}>
                    <p>
                      Are you sure you want to remove this candidate from your
                      talent pool?
                    </p>
                  </DropdownContentArea>
                  <TwoButtonWrapper
                    firstComponent={"Remove"}
                    componentFunction={() => handleDeleteAddedUser(user?._id)}
                  ></TwoButtonWrapper>
                </div>
              )}

              {commentButtonSelected[user?._id] && (
                <DropdownContentArea>
                  <div className="inviteDetailContainer">
                    <div className="verticalLabelInput">
                      <label htmlFor="candidateComments">Comments</label>

                      <textarea
                        name="candidateComments"
                        id="candidateComments"
                        className="detailInput userComments"
                        rows="4"
                        defaultValue={user?.rejectionComment}
                        value={comment[user?._id]}
                        onChange={(e) =>
                          setComment({ [user?._id]: e.target.value })
                        }
                      ></textarea>
                    </div>

                    <div className="inviteDetailButtons">
                      <TwoButtonWrapper
                        firstComponent={"Cancel"}
                        height={"45px"}
                        borderRadius={"10px"}
                        backgroundColor={"white"}
                        color={"black"}
                        componentFunction={() => handleCommentButton(user?._id)}
                      ></TwoButtonWrapper>

                      <TwoButtonWrapper
                        firstComponent={"Save"}
                        height={"45px"}
                        borderRadius={"10px"}
                        backgroundColor={"black"}
                        color={"white"}
                        componentFunction={() => handleSubmitComment(user?._id)}
                      ></TwoButtonWrapper>
                    </div>
                  </div>
                </DropdownContentArea>
              )}

              {chatButtonSelected[user?._id] && (
                <ChatBoxComponent
                  chatMessages={chatMessages}
                  setChatMessages={setChatMessages}
                  user_id={Marketer?.user?._id}
                  chatBoxId={user?.chat_inbox}
                  type={"talentPool"}
                ></ChatBoxComponent>
              )}

              {toggleButtonSelected[user?._id] && (
                <DropdownContentArea>
                  <div className="inviteDetailContainer">
                    <div className="detailColumns">
                      <div className="horizontalLabelInput">
                        <label htmlFor="userName">Custom Profile Pricing</label>
                        <input
                          type="number"
                          className="detailInput userPrice"
                          defaultValue={user?.user_price}
                          value={userPrice[user?._id]}
                          onChange={(e) =>
                            setUserPrice({ [user?._id]: e.target.value })
                          }
                        />
                      </div>

                      <div className="horizontalLabelInput">
                        <label htmlFor="userName">
                          Make them the top candidate in your pool
                        </label>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <ToggleSwitch
                            isTopCandidate={isTopCandidate[user?._id]}
                            user_id={user?._id}
                            setIsTopCandidate={setIsTopCandidate}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="detailColumns">
                      <div className="verticalLabelInput">
                        <label htmlFor="addSkills">Add Skills</label>
                        <div className="skillAndCustomTags">
                          <div className="seachBarWithSuggestion">
                            <div className="searchBar">
                              <img src={magnify} alt="" />
                              <input
                                type="text"
                                placeholder="Add skills"
                                value={skillSearchValue}
                                onChange={(e) => handleSkillsSearch(e)}
                              />
                            </div>

                            {suggestedSkills.length > 0 &&
                              skillSearchValue.length > 0 && (
                                <div className="suggestionBox">
                                  {suggestedSkills?.map((element) => {
                                    return (
                                      <div
                                        className="skillOrTagItem"
                                        style={{
                                          backgroundColor:
                                            element?.backgroundColor,
                                          border: `1px solid ${element?.borderColor}`,
                                        }}
                                        onClick={() =>
                                          selectSuggestedSkill(
                                            user?._id,
                                            element
                                          )
                                        }
                                      >
                                        {element.name}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                          </div>

                          <div className="skillAndTagContainer">
                            {user?.skills?.map((skill) => {
                              return (
                                <div
                                  className="skillOrTagItem"
                                  style={{
                                    backgroundColor: skill?.backgroundColor,
                                    border: `1px solid ${skill?.borderColor}`,
                                  }}
                                >
                                  {skill?.name}
                                  <RxCross2
                                    className="crossIcon"
                                    onClick={() =>
                                      removeExistingSkill(user?._id, skill)
                                    }
                                  ></RxCross2>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="verticalLabelInput">
                        <label htmlFor="customTags">Custom Tags</label>
                        <div className="skillAndCustomTags">
                          <div className="seachBarWithSuggestion">
                            <div className="searchBar">
                              <img src={magnify} alt="" />
                              <input
                                type="text"
                                placeholder="Add tags"
                                value={tagSeachValue}
                                onChange={(e) => handleTagsSearch(e)}
                                onKeyDown={(e) => addNewTag(e, user._id)}
                              />
                            </div>
                            {suggestedTags.length > 0 &&
                              tagSeachValue.length > 0 && (
                                <div className="suggestionBox">
                                  {suggestedTags?.map((element) => {
                                    return (
                                      <div
                                        className="skillOrTagItem"
                                        style={{
                                          backgroundColor:
                                            element?.backgroundColor,
                                          border: `1px solid ${element?.borderColor}`,
                                        }}
                                        onClick={() =>
                                          selectSuggestedTag(user._id, element)
                                        }
                                      >
                                        {element.name}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                          </div>

                          <div className="skillAndTagContainer">
                            {user?.tags?.map((tag) => {
                              return (
                                <div
                                  className="skillOrTagItem"
                                  style={{
                                    backgroundColor: tag?.backgroundColor,
                                    border: `1px solid ${tag?.borderColor}`,
                                  }}
                                >
                                  {tag?.name}
                                  <RxCross2
                                    className="crossIcon"
                                    onClick={() =>
                                      removeExistingTag(user?._id, tag)
                                    }
                                  ></RxCross2>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inviteDetailButtons">
                      <TwoButtonWrapper
                        firstComponent={"Cancel"}
                        height={"45px"}
                        borderRadius={"10px"}
                        backgroundColor={"white"}
                        color={"black"}
                        componentFunction={() => handleToggleButton(user?._id)}
                      ></TwoButtonWrapper>

                      <TwoButtonWrapper
                        firstComponent={"Save"}
                        height={"45px"}
                        borderRadius={"10px"}
                        backgroundColor={"black"}
                        color={"white"}
                        componentFunction={() => handleSubmitData(user)}
                      ></TwoButtonWrapper>
                    </div>
                  </div>
                </DropdownContentArea>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};
