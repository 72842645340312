import React, { useEffect, useState } from "react";
import "../../styles/SapLogin.css";
import logo from "../../images/logo.png";
import { useHistory } from "react-router-dom";
import axios from "../../axios.js";

function SapLogin() {
  const history = useHistory();

  const [businessEmail, setBusinessEmail] = useState("");
  const [password, setPassword] = useState("");

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "businessEmail") {
      setBusinessEmail(value);
    } else setPassword(value);
  };

  const submitHandler = async (e) => {
    console.log("dkfsldfsdj");
    e.preventDefault();
    try {
      const res = await axios.post("/auth/login", {
        businessEmail,
        password,
        key: "superAdmin",
      });
      if (res.data.token) {
        localStorage.setItem("sapuser", JSON.stringify(res.data));
        history.push("/sap");
      } else {
        console.log(res.data);
      }
    } catch (e) {
      console.log();
    }
  };

  return (
    <div className="saplogin">
      <img
        src={logo}
        alt=""
        className="logo"
        onClick={() => history.push("/")}
      />

      <h1 className="fw__600 color__orange">SAP Login</h1>
      <div className="login__form">
        <label>E-mail</label>
        <input
          name="businessEmail"
          type="email"
          placeholder="Enter your email id"
          className="epass"
          required
          onChange={changeHandler}
        />
        <label>Password</label>
        <input
          name="password"
          type="password"
          placeholder="Enter your password"
          className="epass"
          required
          onChange={changeHandler}
        />
        <div className="log__for">
          <label>
            <input type="checkbox" />
            Keep me logged in
          </label>
        </div>
        <button onClick={submitHandler} type="submit" className="login__btn">
          Login
        </button>
      </div>
    </div>
  );
}

export default SapLogin;
