import React from 'react';
import { useHistory } from "react-router-dom";
import { allTalent,copyWritter,graphicDesigner,softwareEngineer,SMM,brandManager,creativeDirector, Redirect, Frame1, Frame2, Frame3, Vishwa, Ramneek, Navcharan, Abhishek} from '../images';
import './middleComp.css';

const MiddleComp = () => {  
    const images  = [
        copyWritter, creativeDirector, brandManager, SMM, graphicDesigner, softwareEngineer, allTalent,
    ]
    const frames = [
        {
          image: Frame1,
          title1: 'Proof of work',
          title2: 'profiles',
          text: 'Each candidate profile is meticulously curated with real-world projects to further assert their competence',
          buttonTitle: 'Explore projects',
        },
        {
          image: Frame2,
          title1: 'Referred by',
          title2: 'the best',
          text: 'Unispade ensures that every choice is backed by en expert, fostering a workforce tailored for success',
          buttonTitle: 'Explore pools',
        },
        {
          image: Frame3,
          title1: 'Candidates with',
          title2: 'verified skillset',
          text: "Our platform showcases only the best, ensuring you find the perfect fit for your organization's needs.",
          buttonTitle: 'Explore talent',
        },
      ];
      const foundingTeam = [
        // {
        //   text: 'Any time you have an open position in your company, you can hunt down candidates recommended by the experienced industry professionals. Hire only from the best of talents.',
        //   displayImage: Vishwa,
        //   name: 'VISHWA VIJAY RANA',
        //   role: 'Product - Design - Strategy',
        //   linkedinLink: 'https://www.linkedin.com/in/johndoe/',
        //   twitterLink: 'https://twitter.com/johndoe',
        //   instagramLink: 'https://www.instagram.com/johndoe/',
        // },
        {
          text: 'Talent pools on Unispade are curated from ground up. Each candidate added in the pool is personally vetted and verified by an experienced industry professional.',
          displayImage: Ramneek,
          name: 'RAMNEEK SIDDHU',
          role: 'Sales - Client Relations',
          linkedinLink: 'https://www.linkedin.com/in/janesmith/',
          twitterLink: 'https://twitter.com/janesmith',
          instagramLink: 'https://www.instagram.com/janesmith/',
        },
        {
            text: 'Community based talent sourcing is the most time efficient and cost effective way to hire. When everyone claims to be “the best”, peer validation is the only way forward.',
            displayImage: Navcharan,
            name: 'NAVCHARAN SINGH',
            role: 'Marketing - Content',
            linkedinLink: 'https://www.linkedin.com/in/johndoe/',
            twitterLink: 'https://twitter.com/johndoe',
            instagramLink: 'https://www.instagram.com/johndoe/',
          },
          {
            text: 'AI may have its own perks in certain hiring processes but when you are investing in a talent, the human element can not be factored out. Unispade brings that human element.',
            displayImage: Abhishek,
            name: 'ABHISHEK RAJAN',
            role: 'Tech - Product',
            linkedinLink: 'https://www.linkedin.com/in/janesmith/',
            twitterLink: 'https://twitter.com/janesmith',
            instagramLink: 'https://www.instagram.com/janesmith/',
          },
      ];
    return (
        <div className="middleComp">
            <div className='diffTalent'>
                <div className='HPHeading'>
                    <h2>Congregating</h2> 
                    <h2>Quality & Diverse talent at scale</h2>
                </div>
                <div class="grid-container">
                    {images.map((Image, index) => (
                        <div key={index} className="grid-item">
                            <img src={Image} alt={`Image ${index + 1}`} />
                        </div>
                    ))}
                </div>
                <div  className="AllTalent">
                            <img src={images[images.length-1]} alt={`Image ${images.length}`} />
                        </div>
                <button className='exploreTalentBtn'>
                    Explore Talent
                    <img src={Redirect} alt="Explore Talent Icon" width="20" height="20" />
                </button> 
            </div>
            <div className='talentDelivers'>
                <div className='HPHeading'>
                    <h2>Easiest way to find the talent</h2>
                    <h2>that delivers</h2>
                </div>
                <div class="grid-container">
                    {frames.map((frame, index) => (
                        <div key={index} className="grid-item">
                            <img src={frame.image} alt={`Image ${index + 1}`} />
                            <div className='contentDiv'>
                                <h2>{frame.title1}</h2>
                                <h2>{frame.title2}</h2>
                                <p>{frame.text}</p>
                                <button className='exploreTalentBtn'>
                                    {frame.buttonTitle}
                                    <img src={Redirect} alt="Explore Talent Icon" width="20" height="20" />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='foundingTeam'>
                <div className='HPHeading'>
                    <h2>The founding team thesis</h2>
                </div>
                <div className="grid-container">
                    {foundingTeam.map((item, index) => (
                        <div key={index} className="grid-item">
                            <p>{item.text}</p>
                            <div className='founderBio'>
                                <div className='founderDP'>
                                    <img src={item.displayImage} alt={`Image ${index + 1}`} />
                                </div>
                                <p>{item.text}</p>
                                <div className='founderInfo'>
                                    <h2>{item.name}</h2>
                                    <p>{item.role}</p>
                                    <span><a href={item.linkedinLink} target="_blank" rel="noopener noreferrer">LinkedIn |</a></span>
                                    <span><a href={item.twitterLink} target="_blank" rel="noopener noreferrer"> Twitter |</a></span>
                                    <span><a href={item.instagramLink} target="_blank" rel="noopener noreferrer"> Instagram</a></span>    
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default MiddleComp;
