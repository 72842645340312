import React, { useState, useEffect } from "react";
import ProfileCompo from "../../profileImage/ProfileImage";
import "./BasicInfo.css";
import TwoButtonWrapper from "../../../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastStyle from "../../../../assets/toastConfig";
import axios from "../../../../axios";
const linkedInRegex =
  /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;

const BasicInfo = ({
  userData,
  setUserData,
  setUserProfileFormData,
  currentProfileData,
  onsubmitForm,
  setMarketersdata,
}) => {
  console.log("the user data is ", userData);
  const [cropData, setCropData] = useState("");
  const [fieldErrors, setFieldErrors] = useState({
    linkedIn: "",
    email: "",
    phoneNumber: "",
  });

  const Marketer = JSON.parse(localStorage.getItem("marketer"));
  useEffect(() => {
    setUserData(currentProfileData);
  }, [currentProfileData]);

  const syncYourCalendar = async () => {
    const { data } = await axios.get(
      `/google-calendar/setUp/${Marketer?.user?._id}`
    );
    console.log("the data in sync your calendar is ", data);
    if (data) {
      window.location.href = data;
    }
  };

  const validateField = (name, value) => {
    console.log("name", name, "value", value);
    let error = "";
    switch (name) {
      case "linkedIn":
        if (!linkedInRegex.test(value)) {
          error = "Enter valid LinkedIn URL";
        }
        break;
      case "email":
        if (!value.includes("@")) {
          error = "Enter a valid email address.";
        }
        break;
      case "phoneNumber":
        if (!/^\d{10}$/.test(value)) {
          error = "Enter a valid 10-digit phone number.";
        }
        break;
      // Add more cases for other fields if needed
      default:
        break;
    }

    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  console.log("the field errors are ", fieldErrors);
  const OnSubmit = (event) => {
    event.preventDefault();
    setCropData("");
    let valid = true;
    ["linkedIn", "email", "phoneNumber"].forEach((key) => {
      if (fieldErrors[key] !== "") {
        valid = false;
      }
    });

    if (!valid) {
      toast.error("Please enter valid data", toastStyle);
    } else {
      const updatedUserProfile = {
        ...userData,
      };

      onsubmitForm(updatedUserProfile);
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    validateField(name, value); // Validate the field
    let userProfileCopy = { ...userData };
    if (name === "linkedIn") {
      userProfileCopy["socialLinks"]["linkedIn"] = value;
    }

    userProfileCopy[name] = value;
    console.log("the user copy is ", userProfileCopy);
    setUserData(userProfileCopy);
  };

  const resetToPrevData = (event) => {
    event.preventDefault();
    setUserData(currentProfileData);
  };

  const firstMendatoryInfo = [
    {
      Tittle: "LinkedIn Profile",
      placeHolder: "LinkedIn Profile URL",
      type: "text",
      name: "linkedIn",
      disable: false,
    },
    {
      Tittle: "Full Name",
      placeHolder: "First Name + Last Name",
      type: "text",
      name: "name",
      disable: false,
    },
    {
      Tittle: "Username",
      placeHolder: "Choose a unique username",
      type: "text",
      name: "username",
      disable: false,
    },
    {
      Tittle: "Title",
      placeHolder: "About you in few words",
      type: "string",
      name: "title",
      disable: false,
    },
  ];

  const secondMandatoryInfo = [
    {
      Tittle: "Sync Google Calendar",
      subtittle: "",
      placeHolder: `${
        userData?.verifiedContact?.googleMeetId
          ? "Calendar synced, click here to change"
          : "Click on 'Sync calendar' to authenticate"
      }`,
      type: "email",
      name: "SyncCalendar",
      disable: false,
    },
    {
      Tittle: "PRIMARY EMAIL",
      subtittle: "Preferably your personal email ID",
      placeHolder: "Your primary email",
      type: "email",
      name: "email",
      disable: false,
    },
    {
      Tittle: "Recovery Email",
      subtittle: "In case you lose access to your primary mail ID",
      placeHolder: "Your secondary email",
      type: "email",
      name: "officialMailId",
      disable: false,
    },
    {
      Tittle: "Whatsapp Number",
      placeHolder: "Whatspp Number for meeting notifications",
      type: "string",
      name: "phoneNumber",
      disable: false,
    },
  ];

  const handleLinkedInSync = async () => {
    try {
      const url = userData?.["socialLinks"]?.["linkedIn"];
      console.log("the url is ", url);
      const { data } = await axios.post(
        `/marketers/connectViaLinkedIn`,
        { url: url },
        {
          headers: {
            authorization: "Bearer " + Marketer.token,
          },
        }
      );

      setMarketersdata(data);
      toast.success("LinkedIn Synced Successfully", toastStyle);
    } catch (err) {
      console.log("the error is ", err);
      console.log("Error is connecting to linked in");
    }
  };

  return (
    <main className="basicinfo_container">
      <form className="BasicInfo formScrollBarDisplay">
        <div className="Fields">
          <div className="mandatoryField">
            {firstMendatoryInfo?.map((key, index) => (
              <div key={index} className="InfoDiv">
                <p>{key.Tittle}</p>
                <span>{key?.subtittle}</span>
                <div className="inputContainer">
                  <input
                    disabled={key.disable}
                    placeholder={key.placeHolder}
                    type={key.type}
                    name={key.name}
                    onChange={handleChange}
                    value={
                      key.name === "linkedIn"
                        ? userData?.["socialLinks"]?.["linkedIn"]
                        : userData[key.name]
                    }
                  />
                  {key.name === "linkedIn" && (
                    <div
                      className={`iconBoxWithText ${
                        fieldErrors["linkedIn"] ? "Inactive" : ""
                      }`}
                      onClick={() => handleLinkedInSync()}
                    >
                      <span className="material-symbols-outlined">sync</span>
                      <p>Sync linkedIn</p>
                    </div>
                  )}
                </div>

                {fieldErrors[key.name] && (
                  <span className="errorColor">{fieldErrors[key.name]}</span>
                )}
              </div>
            ))}
            <div className="InfoDiv">
              <p>About You</p>
              <textarea
                rows="3"
                cols="50"
                name="about"
                placeholder="A 100 words description about yourself"
                onChange={handleChange}
                value={userData.about}
              ></textarea>
            </div>
            {secondMandatoryInfo?.map((key, index) => (
              <div key={index} className="InfoDiv">
                <p>{key.Tittle}</p>
                <span>{key.subtittle}</span>
                <div className="inputContainer">
                  <input
                    disabled={key.disable}
                    placeholder={key.placeHolder}
                    type={key.type}
                    name={key.name}
                    onChange={handleChange}
                    value={userData[key.name]}
                    readOnly={key.name === "SyncCalendar" ? true : false}
                  />
                  {key.name === "officialMailId" &&
                    (userData?.verifiedContact?.officialMailId ? (
                      <div className="iconBoxWithText">
                        <span className="material-symbols-outlined">
                          exclamation
                        </span>
                        <p>Verified</p>
                      </div>
                    ) : (
                      <div className="iconBoxWithText">
                        <span className="material-symbols-outlined">
                          exclamation
                        </span>
                        <p>Verify Email</p>
                      </div>
                    ))}
                  {key.name === "email" &&
                    (userData?.verifiedContact?.email ? (
                      <div className="iconBoxWithText">
                        <span className="material-symbols-outlined">
                          exclamation
                        </span>
                        <p>Verified</p>
                      </div>
                    ) : (
                      <div className="iconBoxWithText">
                        <span className="material-symbols-outlined">
                          exclamation
                        </span>
                        <p>Verify Email</p>
                      </div>
                    ))}

                  {key.name === "phoneNumber" &&
                    (userData?.verifiedContact?.phoneNumber ? (
                      <div className="iconBoxWithText">
                        <span className="material-symbols-outlined">
                          exclamation
                        </span>
                        <p>Verify Number</p>
                      </div>
                    ) : (
                      <div className="iconBoxWithText">
                        <span className="material-symbols-outlined">
                          exclamation
                        </span>
                        <p>Verify Number</p>
                      </div>
                    ))}

                  {key.name === "SyncCalendar" &&
                    userData?.verifiedContact &&
                    (userData?.verifiedContact["googleMeetId"] ? (
                      <div
                        className={`iconBoxWithText`}
                        onClick={() => syncYourCalendar()}
                      >
                        <span className="material-symbols-outlined">sync</span>
                        <p>Switch email</p>
                      </div>
                    ) : (
                      <div
                        className={`iconBoxWithText`}
                        onClick={() => syncYourCalendar()}
                      >
                        <span className="material-symbols-outlined">sync</span>
                        <p>Sync Calender</p>
                      </div>
                    ))}
                </div>

                {fieldErrors[key.name] && (
                  <span className="errorColor">{fieldErrors[key.name]}</span>
                )}
              </div>
            ))}
            <div className="InfoDiv">
              <p>Profile Picture</p>
              <ProfileCompo
                name="profilePhoto"
                profilePhoto={userData?.profilePhoto}
                setUserProfileFormData={setUserProfileFormData}
                cropData={cropData}
                setCropData={setCropData}
              />
            </div>
          </div>
        </div>
        <div className="newFormButtons">
          <TwoButtonWrapper
            firstComponent={"Cancel"}
            backgroundColor={"white"}
            color={"black"}
            padding={"5px 20px"}
            height={"45px"}
            borderRadius={"10px"}
            componentFunction={(e) => resetToPrevData(e)}
          ></TwoButtonWrapper>
          <TwoButtonWrapper
            firstComponent={"Save"}
            padding={"5px 20px"}
            height={"45px"}
            borderRadius={"10px"}
            componentFunction={OnSubmit}
          ></TwoButtonWrapper>
        </div>
      </form>
      {/* <div className="formButtons">
        <div className="innerFormContainer">
          <button className="formBtn" onClick={(e) => resetToPrevData(e)}>
            Cancel
          </button>
          <button
            disabled={currentProfileData === userData}
            onClick={OnSubmit}
            type="submit"
            className="formBtn"
          >
            Save
          </button>
        </div>
      </div> */}
    </main>
  );
};

export default BasicInfo;
