import React from "react";
import "./DropdownList.css";

const DropdownMenuList = ({ options, handleSelection, onClose }) => {
  const changeTheFormikValue = (value) => {
    handleSelection(value);
    onClose();
  };

  return (
    <div className="dropdownListContainer">
      {options?.map((element) => {
        return (
          <div onClick={() => changeTheFormikValue(element)}>{element}</div>
        );
      })}
    </div>
  );
};

export default DropdownMenuList;
