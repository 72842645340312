import React, { useEffect, useState } from 'react'
import "./CompanyProfile.css"
import axios from "../../../axios"
import Loader from "../../loader/Loader"
import BeforeCompanySelected from './BeforeCompanySelected/BeforeCompanySelected'
import AfterCompanySelected from './AfterCompanySelected/AfterCompanySelected'
import { useLocation } from 'react-router-dom'
const CompanyProfile = ({ selectedCompany, setSelectedCompany }) => {
    const location = useLocation();
    const [activeSearch, setActiveSearch] = useState('')
    const [searchCompany, setSearchCompany] = useState()
    const [Allclients, setAllClients] = useState()
    const [AllAgencies, setAllAgencies] = useState()
    const [loader, setLoader] = useState(true)


    const getAllClients = async () => {
        const { data } = await axios.post("/getAllclientsDetails",{clientNameStartWith:"a"})
        const customComparator = (a, b) => {
            const nameA = a.clientName.trim();
            const nameB = b.clientName.trim();

            // Compare names, ignoring leading spaces and numbers
            if (nameA.match(/^\s*\d/) && !nameB.match(/^\s*\d/)) {
                return 1; // Place names starting with spaces/numbers at the end
            } else if (!nameA.match(/^\s*\d/) && nameB.match(/^\s*\d/)) {
                return -1; // Place names without spaces/numbers before names with spaces/numbers
            } else if (nameA === '' && nameB !== '') {
                return 1; // Place empty strings at the end
            } else if (nameA !== '' && nameB === '') {
                return -1; // Place non-empty strings before empty strings
            } else if (nameA.startsWith('[') && !nameB.startsWith('[')) {
                return 1; // Place empty strings at the end
            } else if (!nameA.startsWith('[') && nameB.startsWith('[')) {
                return -1; // Place non-empty strings before empty strings
            } else {
                // Default comparison (A-Z order)
                return nameA.localeCompare(nameB);
            }
        };
        const sortedCompanies = [...data].sort(customComparator);
        setAllClients(sortedCompanies)
    }

    const getAllAgencies = async () => {
        const { data } = await axios.post("/getAllAgencyBasicDetail",{AgencyNameStartWith:"a"})
        const customComparator = (a, b) => {
            const nameA = a.agencyName.trim();
            const nameB = b.agencyName.trim();

            // Compare names, ignoring leading spaces and numbers
            if (nameA.match(/^\s*\d/) && !nameB.match(/^\s*\d/)) {
                return 1; // Place names starting with spaces/numbers at the end
            } else if (!nameA.match(/^\s*\d/) && nameB.match(/^\s*\d/)) {
                return -1; // Place names without spaces/numbers before names with spaces/numbers
            } else if (nameA === '' && nameB !== '') {
                return 1; // Place empty strings at the end
            } else if (nameA !== '' && nameB === '') {
                return -1; // Place non-empty strings before empty strings
            } else if (nameA.startsWith('[') && !nameB.startsWith('[')) {
                return 1; // Place empty strings at the end
            } else if (!nameA.startsWith('[') && nameB.startsWith('[')) {
                return -1; // Place non-empty strings before empty strings
            } else {
                // Default comparison (A-Z order)
                return nameA.localeCompare(nameB);
            }
        };
        const sortedCompanies = [...data].sort(customComparator);
        setAllAgencies(sortedCompanies)
    }

    useEffect(() => {
        if (location.pathname.split('/')[2].endsWith('new-company-profiles') && !location.search) {
            setActiveSearch('')
        }
        else if (location.search) {
            setActiveSearch(location.search.split('=')[0].split('?')[1].split('selected')[1])
        }
    }, [location])

    useEffect(() => {
        getAllClients()
        getAllAgencies()
    }, [])

    useEffect(() => {
        activeSearch && (activeSearch === "Brand" ? setSearchCompany(Allclients) : setSearchCompany(AllAgencies))
    }, [activeSearch])

    useEffect(() => {
    }, [searchCompany])

    const searchCompanyFunc = async(e) => {
        let searchCompanyCopy
        if (activeSearch === "Brand") {
            if(e.target.value.length===0){
                searchCompanyCopy=Allclients
            }else if (e.target.value.length===1){
                const { data } = await axios.post("/getAllclientsDetails",{clientNameStartWith:e.target.value.toLowerCase()})
                searchCompanyCopy=data
                setAllClients(data)
            }else{
                searchCompanyCopy = Allclients.filter(key => key.clientName.toLowerCase().startsWith(e.target.value.toLowerCase()))
            }
        } else {
            if(e.target.value.length===0){
                searchCompanyCopy=AllAgencies
            }else if (e.target.value.length===1){
                const { data } = await axios.post("/getAllAgencyBasicDetail",{AgencyNameStartWith:e.target.value.toLowerCase()})
                searchCompanyCopy=data;
                setAllAgencies(data);
            }else{
                searchCompanyCopy = AllAgencies.filter(key => key.agencyName.toLowerCase().startsWith(e.target.value.toLowerCase()))
            }
        }

        setSearchCompany(searchCompanyCopy)
    }
    if (!AllAgencies || !Allclients) {
        return <Loader />
    }
    return (
        <div className='CompanyProfile'>
            {!activeSearch ?
                <>
                    <div onClick={() => setActiveSearch("Brand")} className=''>
                        <p>Create a <span>Brand</span> Profile</p>
                        <span>{">"}</span>
                    </div>
                    <div onClick={() => setActiveSearch("Agency")} className=''>
                        <p>Create a <span>Agency</span> Profile</p>
                        <span>{">"}</span>
                    </div>
                </>
                :
                <>
                    {!selectedCompany &&
                        <BeforeCompanySelected activeSearch={activeSearch} searchCompany={searchCompany} setSelectedCompany={setSelectedCompany}
                            searchCompanyFunc={searchCompanyFunc} selectedCompany={selectedCompany} />
                    }
                </>
            }
            {selectedCompany &&
                <AfterCompanySelected activeSearch={activeSearch} selectedCompany={selectedCompany} setSelectedCompany={setSelectedCompany} />
            }


        </div>
    )
}

export default CompanyProfile