import React from 'react';
import { Helmet } from 'react-helmet';
import AgencySidebar from "../CaseStudyComponentsCopy/agencySidebar/AgencySidebar"
import MainContent from '../CaseStudyComponentsCopy/mainContent/MainContent';
import BrandSidebar from '../CaseStudyComponentsCopy/brandSidebar/BrandSidebar';
import './CaseStudyCopy.css';
import   { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../axios.js";
import Loader from '../loader/Loader';
import CaseStudyCard from '../CaseStudyListingComponents/CaseStudyCard/CaseStudyCard';

function CaseStudy() {
  const [data, setData] = useState({});
  const [agencyData,setAgencyData]=useState()
  const id = useParams().id;
  let url = "";
  if (id.includes('-')) {
    url = `/agencyProfile/getnewCaseStudyByKey/${id}`;
  } else {
    url = `/agencyProfile/getnewCaseStudyById/${id}`;
  }
  
  useEffect(() => { 
    const getData = async () => {
      const data = await axios.get(url);
      setData(data.data);
      setAgencyData(data.data.allAgencies)
      // console.log(data.data);
    };
    
    getData();
     
  }, [url]); 

 


if (!data.caseStudyId) {
  return <Loader/>;
} else {
  return (
    <div className="case-study-page">
        <div className="case-study">
      <Helmet>
        <title>{data.caseStudies.projectTitle}</title>
        <meta property="og:title" content={data.caseStudies.projectTitle} />
        <meta property="og:image" content={data.caseStudies.coverImg} />
      </Helmet>
      <BrandSidebar 
      brandLogo={data?.brandData?.clientLogo}
      brandName={data?.brandData?.clientName}
      NoOfProjects={data?.brandProjects}
      />
      <MainContent
      brandName={data.caseStudies.brand}
      agencyName={data.agencyData.agencyName}
      industries={data.caseStudies.industries}
      services={data.caseStudies.services}
      overview={data.caseStudies.overview}
      challenges={data.caseStudies.challenges}
      projectTittle={data.caseStudies.projectTitle}
      statistics={data.caseStudies.statistics}
      research={data.caseStudies.research}
      process={data.caseStudies.process}
      result={data.caseStudies.results}
      id={data.caseStudies.agencyId}
      teamMmembers={data.caseStudies.credits}   
      agencyData={agencyData}
      />
      <AgencySidebar
      agencyLogo={data.agencyData.agencyLogo}
      agencyName={data.agencyData.agencyName}
      NoOfProjects={data.agencyProjects}
      />
    
    </div>
    {/* <div className="case__studies">
       {data.relatedCaseStudy
              .map((caseStudy) => (
                <CaseStudyCard key={caseStudy._id} caseStudy={caseStudy} />
              ))}
      </div>
      <div className="case__studies">
       {data.AgencyrelatedCaseStudy
              .map((caseStudy) => (
                <CaseStudyCard key={caseStudy._id} caseStudy={caseStudy} />
              ))}
      </div> */}
    </div>
    
  )}
}

export default CaseStudy;