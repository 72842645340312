import React, { useEffect, useState } from "react";
import img1 from "./DemoImages/1.png";
import img2 from "./DemoImages/2.png";
import img3 from "./DemoImages/3.png";
import img4 from "./DemoImages/4.png";
import img5 from "./DemoImages/5.png";
import img6 from "./DemoImages/6.png";
import img7 from "./DemoImages/7.png";
import "./userPageContent.css";
import About from "./About/About";
import Consult from "./Consult/Consult";
import TalentPool from "./TalentPool/TalentPool";
import Projects from "./Projects/Projects";
import axios from "../../../axios.js";
import { useLocation, useHistory } from "react-router-dom";
import Mentoring from "./Mentoring/Mentoring.jsx";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";

const UserPageContent = ({ caseStudies, user, setCurrentTab , currentTab}) => {
  const location = useLocation();
  const { tab } = useParams(); // Get the tab parameter from the URL
  const [activeBtn, setActiveBtn] = useState(tab || "about");
  const history = useHistory();
  const [btns, setBtns] = useState([
    {
      id: "about",
      name: "About",
    },
  ]);

  console.log("The users are ", user);

  const [agencyData, setAgencyData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [allLogos, setAllLogos] = useState([]);

  useEffect(() => {
    setCurrentTab(tab || "about");
    if (user?.talentPoolCount > 0) {
      setBtns((prev) => {
        return [{ id: "talentPool", name: "Talent Pool" }, ...prev];
      });
    }
    if (user?.mentoringSessionCount > 0) {
      setBtns((prev) => {
        return [{ id: "mentoring", name: "Mentoring" }, ...prev];
      });
    }
  }, []);

  useEffect(() => {
    if (location.search) {
      setActiveBtn("talentPool");
      setCurrentTab("talentPool");
    }
  }, [location.search]);

  useEffect(() => {
    let agencyTempArr = [];
    const fetchdAgencyData = async () => {
      await Promise.all(
        caseStudies?.map(async (caseStudy) => {
          let url = `/agencies/getAgencyById/${caseStudy.agencyId}`;
          const { data } = await axios.get(url);
          data && agencyTempArr.push(data);
          // console.log('data of agency', data);
        })
      );
      setAgencyData(agencyTempArr);
    };
    fetchdAgencyData();
  }, [caseStudies]);

  useEffect(() => {
    let clientTempArr = [];
    const fetchdClientData = async () => {
      await Promise.all(
        caseStudies?.map(async (caseStudy) => {
          const { data } = await axios.post("/getClientDetailsbyclientsName", {
            clientname: caseStudy.brand,
          });
          data && clientTempArr.push(data);
          // console.log('data of client', data);
        })
      );
      setClientData(clientTempArr);
    };
    fetchdClientData();
  }, [caseStudies]);

  useEffect(() => {
    let tempArr = [];
    agencyData.map((agencyLogo) => {
      if (
        tempArr.lenth === 0 ||
        !tempArr.some((item) => item._id === agencyLogo._id)
      ) {
        tempArr.push({
          _id: agencyLogo._id,
          name: agencyLogo.agencyName,
          logo: agencyLogo.agencyLogo,
        });
      }
    });
    clientData.map((clientLogo) => {
      if (
        tempArr.lenth === 0 ||
        !tempArr.some((item) => item._id === clientLogo._id)
      ) {
        tempArr.push({
          _id: clientLogo._id,
          name: clientLogo.clientName,
          logo: clientLogo.clientLogo,
        });
      }
    });
    setAllLogos(tempArr);
  }, [agencyData, clientData]);

  function handleShow(e) {
    if (e.target.id !== activeBtn) {
      const id = e.target.id;
      setActiveBtn(id);
      localStorage.setItem("activeBtn", id);
      setCurrentTab(id);
      history.push(`/userProfile/${user.username}/${id}`);
    }
  }
  console.log(location);

  // console.log(caseStudies, 'this is case');

  return (
    <>
      <section className="userProfileSection">
        {/* This companies logo section will be used later */}
        {/* <div>
          <span className="companiesLogo">
            {allLogos.map((company) => (
              // <img key={logo._id} src={logo.logo} alt="" />
              <div className="logoWrapper">
                {company?.logo.includes("unispade-react-js-s3") ? (
                  <p>{company.name[0].toUpperCase()}</p>
                ) : (
                  <img src={company.logo} />
                )}
                {company?.logo.includes("unispade-react-js-s3") && <span>{company.name}</span>}
              </div>
            ))}
          </span>
          
        </div> */}

        <div className="buttons">
          {btns.map((btn) => (
            <button
              key={btn.id}
              id={btn.id}
              onClick={handleShow}
              className={activeBtn === btn.id ? "active" : ""}
            >
              {btn.name}
            </button>
          ))}
        </div>

        <div>
          {activeBtn === "about" ? (
            <About user={user} caseStudies={caseStudies} />
          ) : activeBtn === "consult" ? (
            <Consult />
          ) : activeBtn === "talentPool" ? (
            <TalentPool user={user} />
          ) : activeBtn === "mentoring" ? (
            <Mentoring user={user}></Mentoring>
          ) : (
            <TalentPool />
          )}
        </div>
      </section>
    </>
  );
};

export default UserPageContent;
