import React from "react";
import "../../styles/SapSidebar.css";
import logo from "../../images/logo.png";
import { Link, useHistory } from "react-router-dom";

function SapSidebar() {
  const history = useHistory();

  // quatations swtichtab
  const switchTab = e => {
    const tabItem = e.target;

    // For btn activation
    const alltablinks = document.querySelectorAll(".sapsidebarlink");

    alltablinks.forEach(link => {
      link.classList.remove("active"); //deactivating rest of the links
    });

    tabItem.classList.add("active"); //activating the link which is clicked

    const destid = e.target.getAttribute("dest"); //getting the destination id which needs to be activated.
    history.push(`/sap/${destid}`);
  };

  return (
    <div className="sapsidebar">
      <div className="sapsidebar__top">
        <img
          src={logo}
          className="sapsidebarlogo"
          alt=""
          onClick={() => (window.location.pathname = "/")}
        />
      </div>
      <div className="sapsidebarlinks">
        <p
          className="sapsidebarlink para adgrey cursor__pointer text__left active"
          onClick={switchTab}
          dest="agencies"
        >
          Agencies
        </p>
        <p
          className="sapsidebarlink para adgrey cursor__pointer text__left"
          onClick={switchTab}
          dest="brands"
        >
          Brands
        </p>
        <p
          className="sapsidebarlink para adgrey cursor__pointer text__left"
          onClick={switchTab}
          dest="leads"
        >
          Leads
        </p>
        <p
          className="sapsidebarlink para adgrey cursor__pointer text__left"
          onClick={switchTab}
          dest="service"
        >
          Service Pages
        </p>
        <p
          className="sapsidebarlink para adgrey cursor__pointer text__left"
          onClick={switchTab}
          dest="filters"
        >
          Filters
        </p>
        <p
          className="sapsidebarlink para adgrey cursor__pointer text__left"
          onClick={switchTab}
          dest="content"
        >
          Content
        </p>
        <p className="sapsidebarlink para adgrey cursor__pointer text__left"
         onClick={()=>{
          localStorage.removeItem("sapuser")
          history.push('/')
          window.location.reload()
        }}>
          Log out
         
        </p>
      </div>
    </div>
  );
}

export default SapSidebar;
