import React from "react";
import "../../styles/SapAgencies.css";
import searchgrey from "../../images/searchgrey.png";
import blogworks from "../../images/blogworks.png";
import { Link, useHistory } from "react-router-dom";

function SapServiceAddEdit() {
  const history = useHistory();

  const goback = () => {
    history.push("/sap/service");
  };
  return (
    <div className="sapagencies">
      <div className="sapagencies__top">
        <div className="sapagencies__dataselect__cont">
          <form className="sapagencies__data__inpcont">
            <img className="search__icon" src={searchgrey} alt="" />
            <input className="search__input" placeholder="Search" />
          </form>
          <div className="dflex justify__between">
            <p className="para color__orange cursor__pointer">+ Add New Page</p>
          </div>
        </div>
        <select className="sap__select">
          <option defaultValue="Sort">Sort</option>
          <option>Popular</option>
          <option>Popular</option>
        </select>
      </div>
      <div className="dflex justify__between">
        <p className="para color__orange cursor__pointer" onClick={goback}>
          {"< Back"}
        </p>
      </div>
      <div className="saptable__lists casestudy sapservicestable sapservicesaddedit">
        <table className="pricing__table">
          <tbody className="">
            <tr className="table__heading__row ">
              <th className="heading__row__part">
                <p className="theading ">Page Title</p>
              </th>
              <th className="heading__row__part">
                <p className="theading ">Service</p>
              </th>
              <th className="heading__row__part">
                <p className="theading ">URL</p>
              </th>
              <th className="heading__row__part">
                <p className="theading "></p>
              </th>
            </tr>
            <tr className="text__center saptablerow">
              <td className="para adgrey image__name__cont pad2row textleftfirr">
                Best digital marketing agwncies in Noida
              </td>
              <td className="para adgrey pad2row">Digital Marketing</td>
              <td className="para adgrey pad2row">https//:</td>
              <td className="para adgrey pad2row">
                <div className="dflex align__center justify__center">
                  <p className="para color__orange cursor__pointer">Edit</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SapServiceAddEdit;
