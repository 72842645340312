import React from "react";
import "../../styles/Pricing.css";
import PricingCard from "./PricingCard";
import { Link, useHistory } from "react-router-dom";

function Pricing() {
  const history = useHistory();

  var accountfeature = [
    [
      "Full Profile (3 Reviews)",
      "Profile Analytics",
      "Upto 5 organic Leads",
      "1 Guest Blogging"
    ],
    [
      "Full Profile (upto 10 reviews)",
      "Profile Analytics",
      "Upto 10 organic Leads",
      "2 Guest Blogging",
      "Marketing Support",
      "Community Access"
    ],
    [
      "Full Profile (10+ reviews)",
      "Profile Analytics",
      "Unlimited organic Leads",
      "4 Guest Blogging",
      "Marketing Support",
      "Community Access"
    ]
  ];

  return (
    <div className="pricing__cont">
      <div className="pricing__top__cont">
        <div className="pricing__top">
          <h1 className="color__white">
            <span className="color__orange">Pricing</span> to suit all sizes of
            business
          </h1>

          <p className="para color__white">
            upgrades are as simple as installing new plugins
          </p>
          <button className="reg__btn" onClick={() => history.push("/signup")}>
            Try for Free
          </button>
        </div>
      </div>
      <div className="pricing">
        <h2 className="pricing__title">
          <span className="color__orange">Compare</span> features
        </h2>

        <div className="pricing__filters__cont">
          <div className="pricing__filters">
            <h4 className="pricing__filter active">Monthly</h4>
            <h4 className="pricing__filter">Quarterly</h4>
            <h4 className="pricing__filter ">Semi-Annually</h4>
            <h4 className="pricing__filter">Annually</h4>
          </div>
        </div>

        <div className="pricing__table__cont">
          <table className="pricing__table">
            <tbody className="">
              <tr className="table__heading__row">
                <th className="heading__row__part">Account features</th>
                <th className="heading__row__part">
                  <h2>Free</h2>
                  <h5>Starter</h5>
                  <button className="reg__btn2 mart1">Choose Starter</button>
                </th>
                <th className="heading__row__part">
                  <h2>₹5000 + GST</h2>
                  <h5>Basic</h5>
                  <button className="reg__btn2 mart1">Choose Basic</button>
                </th>
                <th className="heading__row__part">
                  <h2>₹7500 + GST</h2>
                  <h5>Premium</h5>
                  <button className="reg__btn2 mart1">Choose Premium</button>
                </th>
              </tr>
              <tr className=" text__left">
                <td className="">Full profile</td>
                <td className="">Yes</td>
                <td className="">Yes</td>
                <td className="">Yes</td>
              </tr>
              <tr className=" text__left">
                <td className="">Analytics</td>
                <td className="">profile visitor</td>
                <td className="">profile visitor + campaign</td>
                <td className="">visitor+campaign+industry</td>
              </tr>
              <tr className=" text__left">
                <td className="">Guest Blogging</td>
                <td className="">1/month</td>
                <td className=""> 2/month </td>
                <td className="">4/month</td>
              </tr>
              <tr className=" text__left">
                <td className="">Marketing support</td>
                <td className="">No</td>
                <td className=""> Yes </td>
                <td className="">Yes</td>
              </tr>
              <tr className=" text__left">
                <td className="">Project notifications</td>
                <td className="">No</td>
                <td className=""> No </td>
                <td className="">Yes</td>
              </tr>
              <tr className=" text__left">
                <td className="">Job vacancies</td>
                <td className="">No</td>
                <td className=""> Yes </td>
                <td className="">Yes</td>
              </tr>
              <tr className=" text__left">
                <td className="">Community access</td>
                <td className="">Yes</td>
                <td className=""> Yes </td>
                <td className="">Yes</td>
              </tr>
              <tr className=" text__left">
                <td className="">Dedicated account manager</td>
                <td className="">No</td>
                <td className=""> No </td>
                <td className="">Yes</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="pricingcards">
          <PricingCard
            type="Free"
            pack="Starter"
            features={accountfeature[0]}
          />
          <PricingCard
            type="₹5000 + GST /month"
            pack="Basic"
            features={accountfeature[1]}
          />
          <PricingCard
            type="₹7500 + GST /month"
            pack="Premium"
            features={accountfeature[2]}
          />
        </div>
      </div>
      {/* Search agencies */}
      <div className="mar5__cont">
        <div className="searchagencies__cont ">
          <div className="searchagencies">
            <p className="mwidthsmall">
              Are you a <span className="color__orange">marketing Agency?</span>{" "}
              Let's set up your profile. It's easy & quick.
            </p>
            <div>
              <button onClick={() => history.push("/signup")}>
                Register Here
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
