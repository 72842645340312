import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/Login.css";
import "../styles/error404.css";
import girl2 from "../images/girl2.png";
import dots from "../images/dots.png";
import axios from "../axios"
import { useHistory } from "react-router-dom";
import authHeader from "../login-auth.js";
import error404 from "../images/error404.png"

function Error404() {
    return (
        <div className="login__cont">
            <div className="login">
                <div className="sec__left_new" id="error_content">
                    <div className="upper">
                        <h1 className="heading">So, you finally found it!
                        </h1>
                        <h4>A rare <span className="color__orange">Error 404 page </span> on our website 🔍
                        </h4>
                    </div>
                    <div className="content">
                        <div className="lower" style={{ marginTop: "30px" }}>
                            <span> What were you looking for?</span>

                        </div>
                    </div>
                    <div className="content" >
                        <div  style={{ marginTop: "20px" }}>

                        💼<span className="lower" 
> <Link to="/search?services=All&locations=All&industries=All&agencySize=All" style={{textDecoration:"none",color:"black"}}> Agencies ➜</Link></span>
                        </div>
                        <div >

                            📄 <span className="lower"> <Link to="/campaigns" style={{textDecoration:"none",color:"black"}}> Case Studies ➜ </Link></span>
                        </div>
                        <div >

                            🖼️ <span className="lower"> <Link to="/brandlisting" style={{textDecoration:"none",color:"black"}}>Brands ➜ </Link></span>
                        </div>
                    </div>
                    <div className="content">
                        <div className="lower" style={{ marginTop: "30px" }}>
                            <p  style={{ fontWeight:"900",fontSize:"19px"}}> We’d love to her from you.</p>
                            <p style={{ marginTop: "-30px" }} >Call us @9824292969 or </p><p style={{ marginTop: "-30px" }}> Email us at vishwavijay@unispade.com
                            </p>
                        </div>
                    </div>


                </div>
                <div id="error404_img">

                    <img src={error404}></img>

                </div>
            </div>

        </div >
    );
}

export default Error404;
