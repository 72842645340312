import React, { useEffect, useState } from 'react'
import './LocalPoolAnalysis.css'
import axios from "../../../../axios";


const LocalPoolAnalysis = ({category_data, setLocalCategory}) => {

  const [localPoolData, setLocalPoolData] = useState();

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  useEffect(() => {
    (async() => {
      const {data} = await axios.get(`talentPool/get_local_pool_data_for_analysis/${category_data._id}`, {
        headers : {
          authorization: "Bearer " + Marketer.token
        }
      })

      setLocalPoolData(data)
    })()
  }, [])

  console.log("the local pool data is ", localPoolData)

  return (
    <div className="LocalPoolAnalysisComponent">
      <div className="nameHeading">
        <h4>{category_data.name}</h4>
        <button onClick={() => setLocalCategory(null)}> &larr; Back</button>
      </div>
    <div className="selectDateRange">
      <select name="dateRange" id="date-select">
        <option value="1">1 Day</option>
        <option value="7">7 Days</option>
        <option value="30">30 Days</option>
      </select>
    </div>
    <div className="lineGraph"></div>
    <div className="overallPoolData">
      <div>
        <p>Pool earnings</p>
        <h2>{category_data.earnings}</h2>
      </div>
      <div>
        <p>Pool Views</p>
        <h2>{category_data?.views}</h2>
      </div>
    </div>
    <div className="totalPools">
      <h6>Candidate Stats</h6>
      <h6>{category_data?.user_count}</h6>
    </div>
    <div className="poolDataTable">
      <table className="categoryDetailTable">
        <thead>
          <tr>
            <th>Candidate</th>
            <th>Views</th>
            <th>Unlocks</th>
            <th>Expand</th>
          </tr>
        </thead>
        <tbody>
          {localPoolData?.map((data) => (
            <>
              <tr>
                <td className='nameAndEmail'>
                  <img src={data.user_profilePhoto} alt="user_profile" />
                  <span>{data.user_name} | </span>
                  <span>{data.user_email}</span>
                </td>
                <td>
                  <div className="poolCategoryColumn">
                    <span>25</span>
                  </div>
                </td>
                <td>
                  <div className="poolCategoryColumn">
                    <span>{data.unlocks}</span>
                  </div>
                </td>

                <td>
                  <div className="poolCategoryColumn">
                    <span>m</span>
                  </div>
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  </div>

  )
}

export default LocalPoolAnalysis
