import React, { useState } from 'react';
import axios from '../../../../axios'
import './LinkedInUrl.css';

const LinkedInUrl = ({setOpenLinkedInUrl, setMarketersdata}) => {
  const [url, setUrl] = useState('');

  const Marketer = JSON.parse(localStorage.getItem("marketer"))

  const handleChange = (event) => {
    setUrl(event.target.value);
  };

  const handleClick = async () => {
    try {
        const {data} = await axios.post(`/marketers/connectViaLinkedIn`, {url : url}, {
          headers : {
            authorization : "Bearer " + Marketer.token
          }
        })

        console.log("the shit is ", data)
        setMarketersdata(data);
    }
    catch(err) {
        console.log("Error is connecting to linked in")
    }
    setOpenLinkedInUrl(false)
    // Add your connect logic here
  };

  return (
    <div className="LinkInUrlPage">
        <div className='LinkedInUrlContainer'>
        <div className="input-group">
        <input 
          type="text" 
          value={url} 
          onChange={handleChange} 
          placeholder="Enter LinkedIn URL" 
          className="input-field"
        />
        <button onClick={handleClick} className="connect-button">Connect</button>
      </div>
        </div>

    </div>
  );
};

export default LinkedInUrl;
