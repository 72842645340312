import React from "react";
import axios from "../../axios.js";
const SapAgenciesDataUpload = () => {
  const uploadCSV = async () => {
    var formData = new FormData();
    var fileInput = document.getElementById("csv");
    console.log(fileInput.files[0]);
    formData.append("file", fileInput.files[0]);
    try {
      await axios
        .post("/upload-agencies", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => console.log("succes"))
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e.message);
    }
  };
  return (
    <div className="v-100">
      <p>Select local CSV File:</p>
      <input id="csv" type="file"></input>
      <button onClick={uploadCSV}>Upload</button>
    </div>
  );
};
export default SapAgenciesDataUpload;
