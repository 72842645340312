import AWS from 'aws-sdk';


AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION
  });
  
 const BUCKET_NAME ="unispade-s3"
  
 export const uploadImage = (file, folderName) => {
  const s3 = new AWS.S3();
  const timestamp = new Date().getTime(); // Get current timestamp
  const fileName = `${timestamp}_${file.name}`; // Append timestamp to file name
  const params = {
    Bucket: BUCKET_NAME,
    Key: folderName + fileName,
    Body: file,
    ACL: 'public-read'
  };

  return new Promise((resolve, reject) => {
    s3.upload(params, (err, data) => {
      if (err) {
        console.log(err);
        reject(err);
      } else {
        resolve(data.Location);
      }
    });
  });
};

  
export const deleteImage = (url) => {
    const s3 = new AWS.S3();
    const params = {
      Bucket: BUCKET_NAME,
      Key: url.split('/').pop()
    };
  
    return new Promise((resolve, reject) => {
      s3.deleteObject(params, (err, data) => {
        if (err) {
          console.log(err);
          reject(err);
        } else {
          console.log(data);
          resolve(data);
        }
      });
    });
};
