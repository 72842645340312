import React, { useState } from "react";
import "./PaymentPage.css";
import arrowIcon from "../img/arrow_back_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import calendar from "../img/calendar_month_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import time from "../img/schedule_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import PaymentDone from "./paymentDone"; // Assuming paymentDone.jsx is in the same directory
import axios from "../../../../../axios.js";
import mobileArrow from "../img/chevron_left_40dp_FILL0_wght400_GRAD0_opsz40.svg";

function PaymentPage({
  selectedDate,
  selectedTimeSlot,
  requestBody,
  setRequestBody,
  sessionDetails,
  setShowPaymentPage
}) {
  const [redirect, setRedirect] = useState(false);
  const [agenda, setAgenda] = useState(null);

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  const handlePayment = async () => {
    // Redirect logic here

    const theRequestObject = { ...requestBody, agenda_insights: agenda };
    const {data} = await axios.post("/mentoring/set-appointment", theRequestObject, {
      headers : {
        authorization : "Bearer " + Marketer.token
      }
    })
    console.log("New create appointment is ", data)
    console.log("the session details are ", sessionDetails)
    

   
    
  
      const gstAmount = (sessionDetails?.price * 18) / 100;
  
      const payload = {
        appointment_id : data?._id,
        session_id : data?.session_id, 
        user_id : data?.user_id, 
        mentor_id : data?.mentor_id,
        amount: sessionDetails?.price,
        gst: gstAmount,
        totalAmount: sessionDetails?.price + gstAmount,
      };
  
      console.log("the razor pay payload is ", payload);
  
      try {
        const response = await axios
          .post("/mentoring/create-mentoring-order", payload, {
            headers: { authorization: "Bearer " + Marketer?.token },
          })
          .then((res) => {
            if (res?.status === 200) {
              const options = {
                key: process.env.REACT_APP_RAZORPAY_KEY_ID,
                amount: res?.data?.totalAmount,
                currency: "INR",
                name: "Unispade",
                description: "RazorPay",
                order_id: res?.data?.razorPayOrderId,
                async handler(response) {
                  const verifydata = {
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_signature: response.razorpay_signature,
                  };
                  try {
                    console.log("Verify payment ke just uppar");
                    const verifyResponse = await axios.post(
                      `/mentoring/verify-mentoring-payment/${data?._id}`,
                      verifydata,
                      {
                        headers: { authorization: "Bearer " + Marketer?.token },
                      }
                    );
  
                    // console.log(verifyResponse, "verifyResponse");
  
                    if (verifyResponse?.status === 200) {

                      console.log(
                        "updating talent payment after razorpay success"
                      );
                      setRedirect(true);

                    } else {

                      await axios.delete(`/mentoring/delete-appointment/${data?._id}`, {
                        headers : {
                          authorization : "Bearer " + Marketer.token
                        }
                      })
                      // history.push("/payment-failed");
                    }
                  } catch (verifyError) {
                    console.error('Error while verifying payment:', verifyError);
                  }
                },
                notes: {
                  address: "Razorpay Corporate Office",
                },
                theme: {
                  color: "#121212",
                },
                modal: {
                  ondismiss: async function () {
                    console.log("Payment popup closed");
                    await axios.delete(`/mentoring/delete-appointment/${data?._id}`, {
                      headers: {
                        authorization: "Bearer " + Marketer.token,
                      },
                    });
                  },
                },
              };
              const razor = new window.Razorpay(options);
              razor.open();
            }
            else {
              console.log("payment ke if mei aa chuke hai ham bayankar")
            }
          });
      } catch (error) {
        console.error('Error while creating payment:', error);
        

        await axios.delete(`/mentoring/delete-appointment/${data?._id}`, {
          headers : {
            authorization : "Bearer " + Marketer.token
          }
        })
      }
    
  
  };

  const formatDate = (date) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-IN", options);
  };

  const createAgenda = (event) => {
    const newAgenda = event.target.value;
    setAgenda(newAgenda);
  };

  // Redirect to paymentDone.jsx if redirect state is true
  if (redirect) {
    return (
      <PaymentDone
        selectedDate={selectedDate}
        selectedTimeSlot={selectedTimeSlot}
        sessionDetails = {sessionDetails}
      />
    );
  }

  return (
    <div className="userPaymentSingleSession">
      <div className="sessionHeader">
        <div className="sessionHeader-title">
          <button className="backButton" onClick={() => setShowPaymentPage(false)}>
            <img src={arrowIcon} alt="left arrow" />
          </button>
          <h1 className="sessionHeader-title heading">{sessionDetails?.title}</h1>
          <div className="mobile-icon">
            <img src={mobileArrow} alt="mobile arrow" />
          </div>
        </div>
        <div className="duration-slot">
          <div className="duration-item">
            <span>
              <img src={calendar} alt="calendar" />{" "}
              {formatDate(selectedDate)}
            </span>
          </div>
          <div className="duration-item">
            <span>
              <img src={time} alt="time" /> {selectedTimeSlot}
            </span>
          </div>
        </div>
      </div>
      <div className="payment-section">
        <div className="duration-footer1">
          <div className="duration-item-footer1">
            <span>
              <img src={calendar} alt="calendar" />{" "}
              {selectedDate.toLocaleDateString()}
            </span>
          </div>
          <div className="duration-item-footer1">
            <span>
              <img src={time} alt="time" /> {selectedTimeSlot}
            </span>
          </div>
        </div>
        <div className="payment-section-title">
          <p>Agenda and Insights</p>
          <textarea
            placeholder="In a few lines, describe: 
• something about you 
• your expectations from the call 
• details that can help the mentor guide you better"
            onChange={createAgenda}
            className="agenda-input"
            onFocus={(e) => {
              if (e.target.value === e.target.defaultValue) {
                e.target.value = "";
                e.target.style.color = "#000";
              }
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.value = e.target.defaultValue;
                e.target.style.color = "#999999";
              }
            }}
          />
        </div>
      </div>

      <div className="sessionFooter">
        <div className="price">
          <span>&#8377; {sessionDetails?.price}</span>
        </div>
        <button className="bookButton" onClick={handlePayment}>
          Make Payment
        </button>
      </div>
    </div>
  );
}

export default PaymentPage;
