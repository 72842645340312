import React, { useState } from "react";
import "../../styles/Login.css";
import girl2 from "../../images/girl2.png";
import dots from "../../images/dots.png";
import axios from "../../axios.js";
import { useHistory,Link } from "react-router-dom";
import authHeader from "../../login-auth.js";

function AgencyLogin() {
    const history = useHistory();

    const [submitbtn, setSubmitbtn] = useState(false);

    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isPasswordValid, setIsPasswordValid] = useState(true);

    const [newAgency, setNewAgency] = useState({
        businessEmail: "",
        password: "",
    });

    const changeHandler = (event) => {
        setIsEmailValid(true);
        setIsPasswordValid(true);
        const { name, value } = event.target;
        setNewAgency((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const submitHandler = async (e) => {
        // loading icon starts
        setSubmitbtn(true);
        // loading icon ends

        e.preventDefault();
        // const loginAgency = newAgency;
        var loginAgency = {
            businessEmail: newAgency.businessEmail,
            password: newAgency.password,
            key: "agency",
        };
        try {
            // history.push("/adashboard");
            // }else{

            const response = await axios.post("/auth/login/", loginAgency);

            if (response.data.message !== undefined) {
                setIsEmailValid(false);
                if (response.data.message === "Incorrect email or password")
                    setIsPasswordValid(false);
            }
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
                try {
                    history.push("/adashboard/aeditprofile");
                } catch (err) {
                    console.log(err);
                }
            }
        } catch (err) {
            console.log(err);
        }

        // loading icon starts
        setSubmitbtn(false);
        // loading icon ends
    };

    return (
        <div className="login__cont">
            <div className="login">
                <div className="sec__left_new "id="left-section">
                    <div className="upper">
                        <h1 className="heading">Welcome back to Unispade!
                        </h1>
                    </div>
                    <div className="content">
                        <div className="lower" style={{marginTop:"30px"}}>
                            <span> Good to see you here again</span>

                        </div>
                    </div>
                    <div className="content" >
                        <div className="lower" style={{marginTop:"20px"}}>

                        ↗️<span> We help your agency to scale up</span>
                        </div>
                        <div className="lower">

                            🎨️ <span>We help you with creative executing</span>
                        </div>
                        <div className="lower">

                            💰 <span>We help you convert more clients</span>
                        </div>
                    </div>



                </div>
                <div className="sec__right_new">

                    <h1 className="fw__600 logreg__title color__black">
                        Agency Login
                    </h1>
                    <form className="login__form" onSubmit={submitHandler}>
                        <label>Business E-mail</label>
                        <input
                            style={{ backgroundColor: !isEmailValid ? "#F9B0B0" : "" }}
                            onChange={changeHandler}
                            name="businessEmail"
                            type="email"
                            value={newAgency.businessEmail}
                            placeholder="Enter your work email id"
                            className="epass"
                            required
                        />
                        <label>Password</label>
                        <input
                            style={{ backgroundColor: !isPasswordValid && "#F9B0B0" }}
                            onChange={changeHandler}
                            name="password"
                            value={newAgency.password}
                            type="password"
                            placeholder="Enter your password"
                            className="epass"
                            required
                        />
                        {(!isEmailValid || !isPasswordValid) && (
                            <p>Incorrect Username or Password</p>
                        )}
                        <div className="log__for">
                            <label>
                                <input type="checkbox" />
                                Keep me logged in
                            </label>
                            <Link to="/forgotpassword" style={{ textDecoration: 'none' }}>
                            <label className="color__black">Forgot Password?</label>
                            </Link>                        </div>
                        <button type="submit" className="login__btn">
                            {submitbtn && (
                                <i className="fas fa-circle-notch donebtnclient"></i>
                            )}
                            {submitbtn && " Logging In"}
                            {!submitbtn && "Login"}
                        </button>
                        <div className="account_status">

                            <p>Don't Have an Account ? <span className="color__black">Register</span> </p>
                        </div>
                    </form>
                </div>
            </div>

        </div >
    );
}

export default AgencyLogin;
