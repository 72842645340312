import { useEffect, useState } from "react";
import "./FindMentor.css";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import axios from "../../../axios";

function FindMentor() {
  const [profileDropdown, setProfileDropdown] = useState({});

  const [searchCompany, setSearchCompany] = useState("");
  const [searchExpertise, setSearchExpertise] = useState("");
  const [searchIndustry, setSearchIndustry] = useState("");
  const [searchAvailability, setSearchAvailability] = useState("");

  const [filterSearchActivated, setFilterSearchActivated] = useState({});
  const [searchedFilterOptions, setSearchedFilterOptions] = useState([]);
  const [providedFilterOptions, setProvidedFilterOptions] = useState({}); // will be used for { location , expertise, availability }

  useEffect(() => {
    (async () => {
      const { data } = await axios.get("/common/all-mentors");
      setProvidedFilterOptions(data.filters);
    })();
  }, []);

  const handleSearchFilter = (filter_value, filter_type) => {
    if (filter_type === "Company") {
      setSearchCompany(filter_value);
    }
    if (filter_type === "Expertise") {
      setSearchExpertise(filter_value);
    }
    if (filter_type === "Industry") {
      setSearchIndustry(filter_value);
    }
    if (filter_value === "") {
      setFilterSearchActivated({});
      setSearchedFilterOptions([]);
      return;
    }

    setFilterSearchActivated({
      [filter_type]: true,
    });
    const filterOptions = providedFilterOptions[filter_type].filter(
      (option) => {
        return option.toLowerCase().includes(filter_value.toLowerCase());
      }
    );
    setSearchedFilterOptions([...filterOptions]);
  };

  const handleSelectFilter = (filter_value, filter_type) => {
    if (filter_type === "Company") {
      setSearchCompany(filter_value);
    }
    if (filter_type === "Expertise") {
      setSearchExpertise(filter_value);
    }
    if (filter_type === "Industry") {
      setSearchIndustry(filter_value);
    }
    setFilterSearchActivated({});

    setSearchedFilterOptions([]);
    setProfileDropdown({});
  };

  const handleFilterDropdown = (filter_name) => {
    setProfileDropdown({
      [filter_name]: !profileDropdown[filter_name],
    });
  };

  const redirectToMentorPage = () => {
    window.location.href = `/mentors?Company=${encodeURIComponent(
      searchCompany
    )}${
      searchExpertise ? `&Expertise=${encodeURIComponent(searchExpertise)}` : ""
    }${
      searchIndustry ? `&Industry=${encodeURIComponent(searchIndustry)}` : ""
    }`;
  };

  return (
    <div className="find-mentor-container">
      <p className="find-mentor-description">
        Connect with seasoned mentors who bring hands-on experience across
        industries. They will help and encourage you to achieve your career
        aspirations and equip you with the insights to manage your path to
        success.
      </p>
      <div className="dropdown-container">
        <div
          style={{
            position: "relative",
          }}
        >
          <button className="dropdown-button">
            <div>
              <input
                type="text"
                placeholder="Company"
                className="filterSeachInput"
                value={searchCompany}
                onChange={(e) => handleSearchFilter(e.target.value, "Company")}
              />
            </div>
            <div onClick={() => handleFilterDropdown("Company")}>
              {profileDropdown["Company"] ? <MdExpandLess /> : <MdExpandMore />}
            </div>
          </button>
          {filterSearchActivated["Company"]
            ? searchedFilterOptions.length > 0 && (
                <div className="dropdown-menu">
                  {searchedFilterOptions?.map((option) => {
                    return (
                      <p
                        className="dropdown-item"
                        onClick={() => handleSelectFilter(option, "Company")}
                      >
                        {option}
                      </p>
                    );
                  })}
                </div>
              )
            : profileDropdown["Company"] && (
                <div className="dropdown-menu">
                  {providedFilterOptions["Company"]?.map((option) => {
                    return (
                      <p
                        className="dropdown-item"
                        onClick={() => handleSelectFilter(option, "Company")}
                      >
                        {option}
                      </p>
                    );
                  })}
                </div>
              )}
        </div>
        <div
          style={{
            position: "relative",
          }}
        >
          <button className="dropdown-button">
            <div>
              <input
                type="text"
                placeholder="Expertise"
                className="filterSeachInput"
                value={searchExpertise}
                onChange={(e) =>
                  handleSearchFilter(e.target.value, "Expertise")
                }
              />
            </div>
            <div onClick={() => handleFilterDropdown("Expertise")}>
              {profileDropdown["Expertise"] ? (
                <MdExpandLess />
              ) : (
                <MdExpandMore />
              )}
            </div>
          </button>
          {filterSearchActivated["Expertise"]
            ? searchedFilterOptions.length > 0 && (
                <div className="dropdown-menu">
                  {searchedFilterOptions?.map((option) => {
                    return (
                      <p
                        className="dropdown-item"
                        onClick={() => handleSelectFilter(option, "Expertise")}
                      >
                        {option}
                      </p>
                    );
                  })}
                </div>
              )
            : profileDropdown["Expertise"] && (
                <div className="dropdown-menu">
                  {providedFilterOptions["Expertise"]?.map((option) => {
                    return (
                      <p
                        className="dropdown-item"
                        onClick={() => handleSelectFilter(option, "Expertise")}
                      >
                        {option}
                      </p>
                    );
                  })}
                </div>
              )}
        </div>

        <div
          style={{
            position: "relative",
          }}
        >
          <button className="dropdown-button">
            <div>
              <input
                type="text"
                placeholder="Industry"
                className="filterSeachInput"
                value={searchIndustry}
                onChange={(e) => handleSearchFilter(e.target.value, "Industry")}
              />
            </div>
            <div onClick={() => handleFilterDropdown("Industry")}>
              {profileDropdown["Industry"] ? (
                <MdExpandLess />
              ) : (
                <MdExpandMore />
              )}
            </div>
          </button>
          {filterSearchActivated["Industry"]
            ? searchedFilterOptions.length > 0 && (
                <div className="dropdown-menu">
                  {searchedFilterOptions?.map((option) => {
                    return (
                      <p
                        className="dropdown-item"
                        onClick={() => handleSelectFilter(option, "Industry")}
                      >
                        {option}
                      </p>
                    );
                  })}
                </div>
              )
            : profileDropdown["Industry"] && (
                <div className="dropdown-menu">
                  {providedFilterOptions["Industry"]?.map((option) => {
                    return (
                      <p
                        className="dropdown-item"
                        onClick={() => handleSelectFilter(option, "Industry")}
                      >
                        {option}
                      </p>
                    );
                  })}
                </div>
              )}
        </div>
      </div>

      <button className="find-mentor-button" onClick={redirectToMentorPage}>
        Find Your Mentor
      </button>
    </div>
  );
}

export default FindMentor;
