import React, { useState, useEffect } from "react";
import "../../styles/ASidebar.css";
import { Link, useHistory, useParams } from "react-router-dom";
import authHeader from "../../login-auth.js";
import axios from "../../axios.js";
import logo from "../../images/logos.png"
import AWS from 'aws-sdk';

const bucket = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
});

async function uploadImage(params) {
  return new Promise((resolve, rejatc) => {
    bucket.upload(params, function (err, data) {
      if (err) {
          console.log('There was an error uploading your file: ', err);
          return false;
      }
      resolve(data.Location)
  });
  })
}

function ASidebar(props) {
  const params = useParams();
  const [agencyName, setAgencyName] = useState("");
  const history = useHistory();
  const [agencyLogo, setAgencyLogo] = useState("");
  const [submitlogo, setsubmitlogo] = useState(false);

  useEffect(()=>{
    setAgencyLogo(props.onUpdateLogo)
  },[props.onUpdateLogo])

  // quatations swtichtab
  const switchTab = (e) => {
    const tabItem = e.target;

    // For btn activation
    const alltablinks = document.querySelectorAll(".asidelink");

    alltablinks.forEach((link) => {
      link.classList.remove("active"); //deactivating rest of the links
    });

    tabItem.classList.add("active"); //activating the link which is clicked

    let destid

    if (params?._id) {
      destid = params._id      
    }
    else{
      destid = e.target.getAttribute("dest"); //getting the destination id which needs to be activated.
    }
    history.push(`/adashboard/${destid}`);



    // To deactivation sidebar after clicking
    const sidebarComp = document.querySelector(".asidebartranslate");
    sidebarComp.classList.toggle("asidebarshowtranslate");
  };
  const config = {
    bucketName: "unispade-react-js-s3",
    dirName: "clientLogos" /* optional */,
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  };

  const ChangeAgencyLogo = async (e) => {
    const { value, name } = e.target;
    if (name === "clientLogo") {
      try {
        // const res = await ReactS3.uploadFile(e.target.files[0], config);
        const uniqueKey = new Date().getTime();
        const fileName = uniqueKey + '-' + e.target.files[0].name;
        const params = {
          Bucket: "unispade-react-js-s3",
          Key: 'clientMedia/' + fileName,
          Body: e.target.files[0],
          ContentType: e.target.files[0].type
      };
      const fileUrl = await uploadImage(params);
      setAgencyLogo(fileUrl);

      } catch (e) {
        console.log(e);
      }
    }
  };
  const updateAgencyLogo = async (e) => {
    setsubmitlogo(true);

    const res = await axios.post(
      "/agencyEditProfileLogo",
      {
        agencyId: params?._id ? params._id : authHeader().User.agencyId,
        basicAgencyDetails: {
          agencyLogo: agencyLogo,
        },
      },
      {
        headers: { authorization: "Bearer " + authHeader().Token },
      }
    );
    console.log(res.data);
    setsubmitlogo(false)
  };

  useEffect(() => {
    const getInfo = async () => {
      try {
        const res = await axios.post(
          "/agencyOnboarding/getAgencyById",
          {
            id: params?._id ? params._id : authHeader().User.agencyId,
          },
          { headers: { authorization: "Bearer " + authHeader().Token } }
        );
        console.log(res.data.agencyLogo, "snsdks")
        setAgencyName(res.data.agencyName);
        setAgencyLogo(res.data.agencyLogo);
      } catch (e) {
        console.log(e);
      }
    };
    getInfo();
  }, []);

  return (
    <div className="asidebar asidebartranslate" >
      <div className="a__sidebar__mob"></div>
      <div className="asidebar__top">
        <div class="profile-image">
          {agencyLogo ? <img src={agencyLogo} className="asidelogo" alt="" /> :
            <img src={logo} className="asidelogo" alt="" />}
          {/* <img src={agencyLogo} className="asidelogo" alt="" /> */}
          <div class="image_hover_bg"> <label htmlFor="filePicker" style={{ cursor: "pointer", padding: "55px 50px" }}>
           
          </label></div>
        </div>
        <p className="adgrey fw-bolder">{agencyName}</p>
        <div className="btn">
         
          <input
            id="filePicker" 
            style={{ visibility: "hidden" }}
            multiple="multiple"
            type="file"
            onChange={(e) => {
              ChangeAgencyLogo(e);
            }}
            name="clientLogo"
          ></input>

          <button
            style={{border: "none", padding: "5px 10px",marginTop:"-20px" }}
            type="button"
            onClick={() => {
              updateAgencyLogo();
            }}
            name="clientLogo"
            value="Update Logo"
          > {submitlogo && (
            <i className="fas fa-circle-notch donebtnclient"></i>
          )}
            {submitlogo && "Updating"}
            {!submitlogo && "Update Logo"}</button>

        </div>
      </div>
      <div className="asidelinks">

        <p

          className="asidelink para adgrey cursor__pointer text__left active fw-bolder "
          onClick={switchTab}
          dest="aeditprofile"
        >
          Edit Profile
        </p>

        {/* <p
          className="asidelink para adgrey cursor__pointer text__left fw-bolder"
          onClick={switchTab}
          dest="anewproject"
        >
          Content
        </p> */}

        {/* <p
          className="asidelink para adgrey cursor__pointer text__left fw-bolder"
          onClick={switchTab}
          dest="anewbusiness"
        >
          New Business
        </p> */}

        {/* <p
          className="asidelink para adgrey cursor__pointer text__left "
          onClick={switchTab}
          dest="aproposals"
        >
          Proposals
        </p> */}

{/* 
        <p
          className="asidelink para adgrey cursor__pointer text__left"
          onClick={switchTab}
          dest="dashboard"
        >
          Pitch Concept
        </p> */}
{/*         
        <p
          className="asidelink para adgrey cursor__pointer text__left"
          onClick={switchTab}
          dest="dashboard"
        >
          Analytics
        </p> */}


        {/* <p
          className="asidelink para adgrey cursor__pointer text__left"
          onClick={switchTab}
          dest="asubmitreferences"
        >
          Submit References
        </p> */}

{/* 
        <p
          className="asidelink para adgrey cursor__pointer text__left "
          // onClick={switchTab}
          dest="dashboard"
        >
          Dashboard
        </p> */}


        {/* <p
          className="asidelink para adgrey cursor__pointer text__left"
          // onClick={switchTab}
          dest="aonboarding"
        >
          Partner Program
        </p> */}

        <p
          className="asidelink para adgrey cursor__pointer text__left fw-bolder"
          onClick={switchTab}
          dest="ateam"
        >
          Team
        </p>
        {/* <p
          className="asidelink para adgrey cursor__pointer text__left"
          onClick={switchTab}
          dest="ayourprofile"
        >
          Your Profile
        </p> */}

        {/* <p
          className="asidelink para adgrey cursor__pointer text__left"
          onClick={switchTab}
          dest="dashboard"
        >
          Upgrade Plan
        </p> */}

      </div>
      <p className=" report__issue para color__black text__center cursor__pointer">
        Report Issues
      </p>
    </div>
  );
}

export default ASidebar;
