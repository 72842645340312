import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./Mentors.css";
import TopComp from "../HomePageComponents/TopComp/topComp";
import { RxCross2 } from "react-icons/rx";
import { CiChat1 } from "react-icons/ci";
import {
  MdDoneAll,
  MdOutlineWavingHand,
  MdOutlineDone,
  MdExpandMore,
  MdDelete,
  MdExpandLess,
} from "react-icons/md";

import { IoFunnelOutline } from "react-icons/io5";
import UserProfile from "../../assets/profile.png";
import TwoButtonWrapper from "../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import axios from "../../axios";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import LinkedInUrl from "../../assets/linkedInLogo.png";

export const Mentors = () => {
  const [mentorList, setMentorList] = useState([]);
  const [filteredShowData, setFilteredShowData] = useState([]);
  const history = useHistory();
  const location = useLocation();

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const companyParam = queryParams.get("Company");
    const expertiseParam = queryParams.get("Expertise");
    const industryParam = queryParams.get("Industry");

    const filters = [
      { filterData: companyParam, type: "Company" },
      { filterData: expertiseParam, type: "Expertise" },
      { filterData: industryParam, type: "Industry" },
    ].filter((filter) => filter.filterData);

    if (filters.length > 0) {
      // setSearchCompany(companyParam);
      // setSearchExpertise(expertiseParam);
      // setSearchIndustry(industryParam);
      setFilterList(filters);

      const filteredData = applyFilters(mentorList, filters);
      setFilteredShowData(filteredData);
    }
  }, [location.search, mentorList]);

  useEffect(() => {
    let mounted = true;

    (async () => {
      try {
        const { data } = await axios.get("/common/all-mentors");
        if (mounted) {
          setMentorList(data.data);
          setFilteredShowData(data.data);
          setProvidedFilterOptions(data.filters);
        }
      } catch (error) {
        console.error("Error fetching mentors:", error);
      }
    })();

    return () => {
      mounted = false; // cleanup
    };
  }, []);

  const redirectToSessionBooking = (username) => {
    history.push(`/userprofile/${username}/mentoring`);
  };

  const redirectToProfile = (username) => {
    history.push(`/userprofile/${username}`);
  };

  const redirectToLinkedin = (url) => {
    window.open(url, "_blank");
  };

  const redirectToParticularSession = (username, sessionId) => {
    history.push(`/userprofile/${username}/mentoring/${sessionId}`);
  };

  const [providedFilterOptions, setProvidedFilterOptions] = useState({});
  const [profileDropdown, setProfileDropdown] = useState({});
  const [filterList, setFilterList] = useState([]);

  const [searchCompany, setSearchCompany] = useState("");
  const [searchExpertise, setSearchExpertise] = useState("");
  const [searchIndustry, setSearchIndustry] = useState("");
  const [filterSeachActivated, setFilterSearchActivated] = useState({});
  const [searchedFilterOptions, setSearchedFilterOptions] = useState([]);

  const handleFilterDropdown = (filter_name) => {
    setProfileDropdown({
      ...profileDropdown,
      [filter_name]: !profileDropdown[filter_name],
    });
  };

  const addFilter = (filterData, type) => {
    setFilterList((prevFilters) => [...prevFilters, { filterData, type }]);
    setProfileDropdown({});
    setFilterSearchActivated({});
    setSearchedFilterOptions([]);
    setSearchCompany("");
    setSearchExpertise("");
    setSearchIndustry("");
  };

  useEffect(() => {
    if (filterList.length === 0) {
      setFilteredShowData(mentorList);
    } else {
      const filteredData = applyFilters(mentorList, filterList);
      setFilteredShowData(filteredData);
    }
  }, [filterList]);

  const applyFilters = (mentorList, filterList) => {
    let filteredData = mentorList;
    filterList.forEach((filter) => {
      filteredData = filteredData.filter((mentor) => {
        if (filter.type === "Company") {
          return mentor.mentor.experience.some(
            (exp) => exp.companyName === filter.filterData
          );
        }
        if (filter.type === "Expertise") {
          return mentor.mentor.expertise.some(
            (exp) => exp === filter.filterData
          );
        }
        if (filter.type === "Industry") {
          return mentor.mentor.industries.some(
            (exp) => exp === filter.filterData
          );
        }
      });
    });

    return filteredData;
  };

  const removeFilter = (filter_name) => {
    setFilterList((prevFilters) =>
      prevFilters.filter((item) => item.filterData !== filter_name)
    );
  };

  const handleSearchFilter = (filter_value, type) => {
    if (type === "Company") {
      setSearchCompany(filter_value);
    }
    if (type === "Expertise") {
      setSearchExpertise(filter_value);
    }
    if (type === "Industry") {
      setSearchIndustry(filter_value);
    }
    if (filter_value === "") {
      setFilterSearchActivated({});
      setSearchedFilterOptions([]);
      return;
    }

    setFilterSearchActivated({ [type]: true });
    const filterOptions = providedFilterOptions[type].filter((option) => {
      return option.toLowerCase().includes(filter_value.toLowerCase());
    });
    setSearchedFilterOptions([...filterOptions]);
  };
  return (
    <div className="MentorsPage homePage">
      <Helmet>
      <link rel="canonical" href="https://www.unispade.com/mentors" />
      </Helmet>
      <div className="filterSection">
        <div className="filterTab">
          <div className="filterIcon">
            <IoFunnelOutline />
            <p>Filtered By</p>
          </div>

          <div className="filteringOption">
            <div className="filterTabDropdown">
              <div>
                <input
                  type="text"
                  placeholder="Search Company"
                  className="filterSeachInput"
                  value={searchCompany}
                  onChange={(e) =>
                    handleSearchFilter(e.target.value, "Company")
                  }
                />
              </div>
              <div onClick={() => handleFilterDropdown("Company")}>
                {profileDropdown["Company"] ? (
                  <MdExpandLess />
                ) : (
                  <MdExpandMore />
                )}
              </div>

              {filterSeachActivated["Company"]
                ? searchedFilterOptions.length > 0 && (
                    <div className="variousOptions">
                      {searchedFilterOptions?.map((option) => {
                        return (
                          <div onClick={() => addFilter(option, "Company")}>
                            {option}
                          </div>
                        );
                      })}
                    </div>
                  )
                : profileDropdown["Company"] && (
                    <div className="variousOptions">
                      {providedFilterOptions["Company"]?.map((option) => {
                        return (
                          <div onClick={() => addFilter(option, "Company")}>
                            {option}
                          </div>
                        );
                      })}
                    </div>
                  )}
            </div>

            <div className="filterTabDropdown">
              <div>
                <input
                  type="text"
                  placeholder="Search Expertise"
                  className="filterSeachInput"
                  value={searchExpertise}
                  onChange={(e) =>
                    handleSearchFilter(e.target.value, "Expertise")
                  }
                />
              </div>
              <div onClick={() => handleFilterDropdown("Expertise")}>
                {profileDropdown["Expertise"] ? (
                  <MdExpandLess />
                ) : (
                  <MdExpandMore />
                )}
              </div>

              {filterSeachActivated["Expertise"]
                ? searchedFilterOptions.length > 0 && (
                    <div className="variousOptions">
                      {searchedFilterOptions?.map((option) => {
                        return (
                          <div onClick={() => addFilter(option, "Expertise")}>
                            {option}
                          </div>
                        );
                      })}
                    </div>
                  )
                : profileDropdown["Expertise"] && (
                    <div className="variousOptions">
                      {providedFilterOptions["Expertise"]?.map((option) => {
                        return (
                          <div onClick={() => addFilter(option, "Expertise")}>
                            {option}
                          </div>
                        );
                      })}
                    </div>
                  )}
            </div>

            <div className="filterTabDropdown">
              <div>
                <input
                  type="text"
                  placeholder="Search Industry"
                  className="filterSeachInput"
                  value={searchIndustry}
                  onChange={(e) =>
                    handleSearchFilter(e.target.value, "Industry")
                  }
                />
              </div>
              <div onClick={() => handleFilterDropdown("Industry")}>
                {profileDropdown["Industry"] ? (
                  <MdExpandLess />
                ) : (
                  <MdExpandMore />
                )}
              </div>

              {filterSeachActivated["Industry"]
                ? searchedFilterOptions.length > 0 && (
                    <div className="variousOptions">
                      {searchedFilterOptions?.map((option) => {
                        return (
                          <div onClick={() => addFilter(option, "Industry")}>
                            {option}
                          </div>
                        );
                      })}
                    </div>
                  )
                : profileDropdown["Industry"] && (
                    <div className="variousOptions">
                      {providedFilterOptions["Industry"]?.map((option) => {
                        return (
                          <div onClick={() => addFilter(option, "Industry")}>
                            {option}
                          </div>
                        );
                      })}
                    </div>
                  )}
            </div>
          </div>

          {filterList?.length > 0 && (
            <>
              <div></div>
              <div className="selectedFilters">
                {filterList?.map((filter) => {
                  return (
                    <div className={`singleFilter ${filter?.type}`}>
                      {filter?.filterData}
                      <RxCross2
                        className="crossIcon"
                        onClick={() => removeFilter(filter?.filterData)}
                      ></RxCross2>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="mentorsPageContainer">
        {filteredShowData?.map((element) => {
          return (
            <div className="mentorCard">
              <div className="gridChild mentorUserInfo">
                <img
                  src={element?.mentor?.profilePhoto}
                  alt=""
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                  }}
                />
                <div className="nameAndRole">
                  <p className="mentorName">{element?.mentor?.name}</p>
                  <p className="mentorRole">
                    <p>{element?.mentor?.experience[0]?.role}</p>
                    <p>
                      at{" "}
                      <strong>
                        {element?.mentor?.experience[0]?.companyName}
                      </strong>{" "}
                    </p>
                  </p>
                </div>

                <div className="companiesAssociated">
                  {element?.mentor?.experience.map((exp) => {
                    {
                      return <p className="companyName">{exp?.companyName}</p>;
                    }
                  })}
                </div>
              </div>
              <div className="gridChild mentorAboutInfo">
                <p className="aboutPara">{element?.mentor?.about}</p>

                <button
                  className="linkedInButton"
                  onClick={() =>
                    redirectToLinkedin(element?.mentor?.socialLinks?.linkedIn)
                  }
                >
                  <img
                    src={LinkedInUrl}
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "3px",
                    }}
                  />
                  <p>LinkedIn</p>
                </button>
              </div>
              <div className="gridChild mentorSessionInfo">
                <p className="sessionHeading">Sessions</p>
                <div className="sessionList">
                  {element?.sessions?.map((session) => {
                    return (
                      <TwoButtonWrapper
                        firstComponent={session?.session_name}
                        secondComponent={
                          <span class="material-symbols-outlined">
                            call_made
                          </span>
                        }
                        secondComponentFunction={() =>
                          redirectToParticularSession(
                            element?.mentor?.username,
                            session?._id
                          )
                        }
                        backgroundColor={"white"}
                        color={"black"}
                        height={"40px"}
                        hoverActivate={true}
                      />
                    );
                  })}
                </div>

                <div className="sessionButtons">
                  <TwoButtonWrapper
                    firstComponent={"View Profile"}
                    componentFunction={() =>
                      redirectToProfile(element?.mentor?.username)
                    }
                    backgroundColor={"white"}
                    color={"black"}
                    height={"35px"}
                    borderRadius={"10px"}
                    fontSize={"12px"}
                    padding={"0 10px"}
                  />

                  <TwoButtonWrapper
                    firstComponent={"Book a session"}
                    componentFunction={() =>
                      redirectToSessionBooking(element?.mentor?.username)
                    }
                    height={"35px"}
                    borderRadius={"10px"}
                    fontSize={"12px"}
                    padding={"0 10px"}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
