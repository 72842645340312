import React from "react";
import "../styles/Reviews.css";
import prevbtn from "../images/prevbtn.png";
import nextbtn from "../images/nextbtn.png";
import girl from "../images/girl.png";
import girlwork from "../images/girlwork.png";
import girlwork2 from "../images/girlwork2.png";

// import { Swiper, SwiperSlide } from "swiper/react";
import Swiper, { SwiperOptions } from "swiper";

// Import Swiper styles
import "swiper/swiper.min.css";
// import "swiper/components/navigation/navigation.min.css";

// import Swiper core and required modules
// import SwiperCore, { Navigation } from "swiper/core";
import ReviewCard from "./ReviewCard";

// install Swiper modules
// SwiperCore.use([Navigation]);

function Reviews() {
  const reviews = [
    {
      name: "Vivek Bathla",
      position: "Marketing Head",
      place: "Amity University",
      img: girl,
      desc:
        "At times when every other agency claims to be the best, it is difficult to identify the quality agencies from the average ones. Unispade is helping brands to cut through the clutter and patching us through to the best suited agencies."
    },
    {
      name: "Rami Reddy",
      position: "Marketing Head",
      place: "Noida University",
      img: girlwork,
      desc:
        "Unispade is helping brands to cut through the clutter and patching us through to the best suited agencies. It was a great pleasure working with the agency and I loved the services they provide during the project duration."
    },
    {
      name: "Vijay Seth",
      position: "Marketing Head",
      place: "IIIT Delhi",
      img: girlwork2,
      desc:
        "Great pleasure working with the agency and I loved the services they provide during the project duration. It was a great pleasure working with the agency and I loved the services they provide during the project duration."
    }
  ];

  return (
    <div className="reviews">
      <h2 className="normal__heading">
        Don’t take our <span className="color__orange fw__600">word</span> for
        it.
      </h2>

      <div class="swiper-wrapper">
        <div class="swiper-slide columns carousel-cnt">
          <ReviewCard data={reviews[0]} />
        </div>
        <div class="swiper-slide columns carousel-cnt">
          <ReviewCard data={reviews[1]} />
        </div>
        <div class="swiper-slide columns carousel-cnt">
          <ReviewCard data={reviews[2]} />
        </div>
      </div>
      {/* <Swiper navigation={true} className="mySwiper">
        <SwiperSlide>
          <ReviewCard />
        </SwiperSlide>
        <SwiperSlide>
          <ReviewCard />
        </SwiperSlide>
        <SwiperSlide>
          <ReviewCard />
        </SwiperSlide>
      </Swiper> */}
    </div>
  );
}

export default Reviews;

var mySwiper = new Swiper(".single-carousel", {
  // Optional parameters
  loop: true,
  // If we need pagination
  pagination: {
    el: ".swiper-pagination"
  },

  // Navigation arrows
  navigation: {
    nextEl: ".single-next",
    prevEl: ".single-prev"
  },

  // And if we need scrollbar
  scrollbar: {
    el: ".swiper-scrollbar"
  }
});

var swiper = new Swiper(".responsive-carousel", {
  slidesPerView: 1.5,
  spaceBetween: 10,
  loop: true,
  // init: false,
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  },
  breakpoints: {
    640: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 24
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 24
    },
    1440: {
      slidesPerView: 4,
      spaceBetween: 24
    }
  }
});
