import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import axios from "../../../../axios.js"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import rightArrow from '../../img/rightArrow.png'
import { Link } from 'react-router-dom';


const AfterCompanySelected = ({ activeSearch, selectedCompany, setSelectedCompany }) => {
    const [isEmailVerified, setIsEmailVerified] = useState(false)

    const toast_style = {
        position: "bottom-right",
        autoClose: 4000,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        width: "10rem",
    }

    const handleAccessSelectedComapany = () => {
        setSelectedCompany('')
        window.open(`${activeSearch === 'Brand' ? '/bdashboard' : '/adashboard'}`, '_blank')
    }

    const [companyDetails, setCompanyDetails] = useState({
        officialEmail: "",
        otp: ""
    })
    const [emailVerified, setEmailVerified] = useState(false)
    const history = useHistory();

    useEffect(() => {
        const queryParams = new URLSearchParams();
        const selectedValue = activeSearch === 'Brand' ? selectedCompany.clientName : selectedCompany.agencyName;
        queryParams.set('selected' + activeSearch, selectedValue);

        history.push({
            pathname: "/user-dashboard/new-company-profiles",
            search: `?${queryParams.toString()}`,
        });
    }, [selectedCompany])

    const handleChangeDetails = (e) => {
        const { name, value } = e.target
        setCompanyDetails(prevValue => ({ ...prevValue, [name]: value }))
    }

    const sendOtp = async () => {
        const Marketer = JSON.parse(localStorage.getItem("marketer"));
        try {
            const { data } = await axios.post("/marketers/addOfficalEmail",
                {
                    officialEmail: companyDetails.officialEmail,
                    claimProfile: activeSearch
                },
                {
                    headers: {
                        Authorization:
                            "Bearer " +
                            Marketer.token, // Replace with your actual token
                    },
                }
            )
            // toast message
            toast.success(data.message, toast_style);
            setEmailVerified(true)

        } catch (error) {
            // tooastt error
            toast.error(error.response.data.message, toast_style)
        }
    }
    // console.log(ToastContainer, toast);

    const verifyOtp = async () => {
        const Marketer = JSON.parse(localStorage.getItem("marketer"));
        try {
            const { data } = await axios.post('/marketers/verifyCompanyAccess',
                {
                    ...companyDetails,
                    claimProfile: activeSearch
                },
                {
                    headers: {
                        Authorization:
                            "Bearer " +
                            Marketer.token, // Replace with your actual token
                    },
                }
            )
            // toast message
            toast.success(data.message, toast_style);
            setIsEmailVerified(true)

        } catch (error) {
            // tooastt error
            toast.error(error.response.data.message, toast_style)
        }
    }

    return (
        <>
            <p>Get access to your Brand's profile</p>
            <div className='SelectedCompany'>
                {activeSearch === "Brand" ? <button>
                    <img
                        src={selectedCompany.clientLogo} alt="" />
                    <p>{selectedCompany.clientName}</p>
                    <span>{selectedCompany.clientEmailHost}</span>
                </button>
                    : <button>
                        <img
                            src={selectedCompany.agencyLogo} alt="" />
                        <p>{selectedCompany.agencyName}</p>
                        <span>{selectedCompany.agencyEmailHost}</span>
                    </button>}
            </div>

            <p>Your Official Mail ID</p>
            <span>Authenticate yourself as the employee of your brand via your official mail</span>
            <div className='emaiVerify'>
                <input
                    placeholder='Enter your official mail ID'
                    type="email"
                    name='officialEmail'
                    onChange={handleChangeDetails}
                />
                <p onClick={sendOtp} style={{ cursor: 'pointer' }}>Send OTP</p>
            </div>
            {emailVerified && <p className='verifyLine'>A mail has been sent to you for verification.</p>}
            {emailVerified && <div className='emaiVerify otpWrapper' style={{border: '1px solid black'}}>
                <input
                    placeholder='Enter OTP'
                    type="number"
                    name="otp"
                    onChange={handleChangeDetails}
                />
                <p onClick={verifyOtp} style={{ cursor: 'pointer' }}>Verify</p>
            </div>}
            {isEmailVerified && (
                <Link to='/user-dashboard/credits' className='accessSelectedCompany'>
                    <p onClick={handleAccessSelectedComapany}>Access the <span>{activeSearch === "Brand" ? selectedCompany.clientName : selectedCompany.agencyName}</span> dashboard</p>
                    <img src={rightArrow} />
                </Link>)
            }
        </>
    )
}

export default AfterCompanySelected
