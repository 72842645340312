import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Redirect,
  talent11,
  talent12,
  talent13,
  talent21,
  talent22,
  talent23,
  Raj,
  Mansi,
  Tushar,
} from "../images";
import "./talentPoolComp.css";

const TalentPoolComp = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideTransition, setSlideTransition] = useState("fade-in");

  const expertsData = [
    {
      expertImage: Raj,
      expertName: "RAJ OZA",
      roles: [
        "Advanced Software Engineer | Aptiv",
        "Software Engineer | KPIT",
        "Sr. Engineer | Bosch",
      ],
      talentShowcase: [talent11, talent12, talent13],
      expertFName: "Raj",
    },
    {
      expertImage: Mansi,
      expertName: "MANSI SHAH",
      roles: ["iOS Engineer | Adidas", "Sr. Software Engineer | Crewscale"],
      talentShowcase: [talent21, talent22, talent23],
      expertFName: "Mansi",
    },
    // {
    //   expertImage: Tushar,
    //   expertName: "TUSHAR DOARA",
    //   roles: [
    //     "Marketing Director | C2FO",
    //     "Sr. Marketing Manager | GoDaddy",
    //     "Marketing Manager | Microsoft",
    //   ],
    //   talentShowcase: [talent13, talent23, talent12],
    //   expertFName: "Tushar",
    // },
    // Add more experts as needed
  ];

  const nextSlide = () => {
    setSlideTransition("fade-out");
    setTimeout(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % expertsData.length);
      setSlideTransition("fade-in");
    }, 750); // Adjust the duration to match your CSS transition duration
  };

  const prevSlide = () => {
    setSlideTransition("fade-out");
    setTimeout(() => {
      setCurrentSlide(
        (prevSlide) => (prevSlide - 1 + expertsData.length) % expertsData.length
      );
      setSlideTransition("fade-in");
    }, 750); // Adjust the duration to match your CSS transition duration
  };

  return (
    <div className="talentPoolComp">
      <div className="HPHeading">
        <h2>Talent pools</h2>
        <h2>from trusted industry experts</h2>
      </div>
      <div className="mainCarousel">
        <div
          // key={index}
          className={`expertSlide `}
          // style={{ transform: `translateX(${-currentSlide * 100}%)` }}
        >
          <div className="expertBio">
            <img
              src={expertsData[currentSlide].expertImage}
              className={slideTransition}
              alt={`Expert`}
            />
            <div className="respnsiveCrouselPointerHeader">
              <h2 className={slideTransition}>
                {expertsData[currentSlide].expertName}
              </h2>
              <div className="carouselPointers">
                <button onClick={prevSlide} className="prevButton">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                    fill="none"
                  >
                    <rect
                      width="20"
                      height="20"
                      transform="translate(14.1421 28.2843) rotate(-135)"
                      fill="white"
                    />
                    <path
                      d="M10.0585 18.3082L10.0585 14.973L21.5608 14.973L21.5549 13.3054L10.0585 13.2995L10.0585 9.97611L5.89251 14.1421L10.0585 18.3082Z"
                      fill="black"
                    />
                  </svg>
                </button>
                <button onClick={nextSlide} className="nextButton">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                    fill="none"
                  >
                    <rect
                      width="20"
                      height="20"
                      transform="translate(14.1421) rotate(135)"
                      fill="white"
                    />
                    <path
                      d="M18.2256 9.97612L18.2256 13.3113L6.72336 13.3113L6.72925 14.9789L18.2256 14.9848L18.2256 18.3082L22.3917 14.1422L18.2256 9.97612Z"
                      fill="black"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="talentRoles">
              {expertsData[currentSlide].roles.map((role, roleIndex) => (
                <p className={slideTransition} key={roleIndex}>
                  {role}
                </p>
              ))}
            </div>
            <span className={slideTransition}>
              <h3>
                View {expertsData[currentSlide].expertFName}'s talent pool
              </h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M11.6666 14.1167V10.7833H4.23325L4.20825 9.10832H11.6666V5.78333L15.8333 9.94999L11.6666 14.1167Z"
                  fill="black"
                />
              </svg>
            </span>
            {/* <div className="carouselPointers">
              <button onClick={prevSlide} className="prevButton">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                >
                  <rect
                    width="20"
                    height="20"
                    transform="translate(14.1421 28.2843) rotate(-135)"
                    fill="white"
                  />
                  <path
                    d="M10.0585 18.3082L10.0585 14.973L21.5608 14.973L21.5549 13.3054L10.0585 13.2995L10.0585 9.97611L5.89251 14.1421L10.0585 18.3082Z"
                    fill="black"
                  />
                </svg>
              </button>
              <button onClick={nextSlide} className="nextButton">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                >
                  <rect
                    width="20"
                    height="20"
                    transform="translate(14.1421) rotate(135)"
                    fill="white"
                  />
                  <path
                    d="M18.2256 9.97612L18.2256 13.3113L6.72336 13.3113L6.72925 14.9789L18.2256 14.9848L18.2256 18.3082L22.3917 14.1422L18.2256 9.97612Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div> */}
          </div>
          <div className="talentShowcase">
            {expertsData[currentSlide].talentShowcase.map(
              (talent, talentIndex) => (
                <div key={talentIndex} className="talentItem">
                  <img
                    className={slideTransition}
                    src={talent}
                    alt={`Talent ${talentIndex + 1}`}
                  />
                  {/* Add other talent details rendering logic here */}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TalentPoolComp;
