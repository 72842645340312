import React, { useEffect, useState } from 'react';
import './MediaComponent.css';
import imagelayout1 from "../../../images/imageLayout1.png";
import imagelayout2 from "../../../images/imageLayout2.png";
import imagelayout3 from "../../../images/imageLayout3.png";
import imagelayout4 from "../../../images/imageLayout4.png";
import imagelayout5 from "../../../images/imageLayout5.png";
import imagelayout1Selected from "../../../images/imageLayoutSelected1.png";
import imagelayout2Selected from "../../../images/imageLayoutSelected2.png";
import imagelayout3Selected from "../../../images/imageLayoutSelected3.png";
import imagelayout4Selected from "../../../images/imageLayoutSelected4.png";
import imagelayout5Selected from "../../../images/imageLayoutSelected5.png";
import uploadIcon from '../../../images/upload.png';
import embedVideo from '../../../images/embed.png';
import Delete from '../../../images/delete.png';
import playIcon from '../../../images/playIcon.png';
import ReactS3 from "react-s3";
import AWS from 'aws-sdk';

const config = {
    bucketName: "unispade-react-js-s3",
    dirName: "clientMedia" /* optional */,
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
};

const bucket = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION
});

const MediaComponent = (props) => {
    const [selectedLayout, setSelectedLayout] = useState("landscape100");
    const [uploadedMedia, setUploadedMedia] = useState([]);
    const [selectedMedia, setSelectedMedia] = useState([]);
    const [embedInput, setEmbedInput] = useState([]);
    const [collapse, setCollapse] = useState(true);
    const [uploadOption, setUploadOption] = useState('');

    useEffect(()=>{
        let imgType=props.caseStudyForm[props.name].imgType
        if(imgType!==""){
            setCollapse(false)
            setSelectedLayout(imgType)
        }else{ 
        }
       
    },[props.caseStudyForm])

    const layoutTypes = [
        { imageSrc: imagelayout1, value: "landscape100", layoutName: 'Image | GIF | Video', imageSrcSelected: imagelayout1Selected },
        { imageSrc: imagelayout2, value: "landscape80", layoutName: 'Image | GIF | Video', imageSrcSelected: imagelayout2Selected },
        { imageSrc: imagelayout3, value: "grid", layoutName: 'Image | GIF', imageSrcSelected: imagelayout3Selected },
        { imageSrc: imagelayout4, value: "vertical", layoutName: 'Image | GIF', imageSrcSelected: imagelayout4Selected },
        { imageSrc: imagelayout5, value: "landscapeGrid", layoutName: 'Image | GIF', imageSrcSelected: imagelayout5Selected },
    ]

    const uploadButtonHandler = () => {
        let elem = document.getElementById(`${props.name}fileInput`);
        if (elem) elem.click();
    }

    const fileInputOnchange = async (e) => {
        const files = e.target.files;
        let reader;
        let file;
        let uploadedMediaCopy = [...uploadedMedia];
        let newFiles = [];

        for (let i = 0; i < files.length; i++) {
            let obj = {
                thumbnail: '',
                name: '',
            }
            if (files[i].type.includes('video/mp4')) {
                obj.name = files[i].name;
                obj.thumbnail = await getVideoCover(files[i], 1.5);
                newFiles.push(obj);
            } else {
                file = files[i];
                reader = new FileReader();
                reader.onload = (function (file) {
                    return function (e) {
                        obj.thumbnail = e.target.result;
                        obj.name = file.name;
                        newFiles.push(obj);
                    };
                })(file);
                reader.readAsDataURL(file);
            }
        }
        if (reader) {
            reader.onloadend = (() => {
                setUploadedMedia([...uploadedMediaCopy,...newFiles]);
            })
        } else if (!reader) {
            setUploadedMedia([...uploadedMediaCopy,...newFiles]);
        }

        setTimeout(() => {
            for (let i = 0; i < files.length; i++) {
                var progressDiv = document.getElementById(props.name + "myProgress-" + i);
                progressDiv.style.display = "block";
                var progressBar = document.getElementById(props.name + "myBar-" + i);
                let file = files[i];
                let folderName = "clientMedia/";
                let fileUpload = {
                    id: "",
                    name: file.name,
                    nameUpload: file.name,
                    size: file.size,
                    type: "",
                    timeReference: 'Unknown',
                    progressStatus: 0,
                    displayName: file.name,
                    status: 'Uploading..',
                }
                uploadfile(file, folderName)
                    .on('httpUploadProgress', function (progress) {
                        let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                        progressBar.style.width = progressPercentage + "%";
                        if (progressPercentage < 100) {
                            fileUpload.progressStatus = progressPercentage;

                        } else if (progressPercentage == 100) {
                            fileUpload.progressStatus = progressPercentage;
                            fileUpload.status = "Uploaded";
                        }
                    })
            }
        }, 1000)
    }

    const uploadfile = (file, folderName) => {
        const uniqueKey = new Date().getTime();
        const params = {
            Bucket: "unispade-react-js-s3",
            Key: folderName + uniqueKey + '-' + file.name,
            Body: file,
            ContentType: file.type
        };
        return bucket.upload(params, function (err, data) {
            if (err) {
                console.log('There was an error uploading your file: ', err);
                return false;
            }
            console.log('Successfully uploaded file.', data);
            setSelectedMedia([...selectedMedia, data.Location]);
            let uploadedMediaCopy = [...uploadedMedia];
            uploadedMediaCopy.push({location: data.Location, name: getMediaName(data.Location), type: 'image'});
            setUploadedMedia(uploadedMediaCopy);
            return true;
        });
    }

    const deletefile = (fileName, folderName) => {
        const params = {
            Bucket: "unispade-react-js-s3",
            Key: folderName + fileName,
        };
        return bucket.deleteObject(params, function (err, data) {
            if (err) {
                console.log('There was an error uploading your file: ', err);
                return false;
            }
            console.log('Successfully uploaded file.', data);
            // setSelectedMedia([...selectedMedia, data.Location]);
            return true;
        });
    }

    const getVideoCover = async (file, seekTo = 0.0) => {
        return new Promise((resolve, reject) => {
            const videoPlayer = document.createElement('video');
            videoPlayer.setAttribute('src', URL.createObjectURL(file));
            videoPlayer.load();
            videoPlayer.addEventListener('error', (ex) => {
                reject("error when loading video file", ex);
            });
            // load metadata of the video to get video duration and dimensions
            videoPlayer.addEventListener('loadedmetadata', () => {
                // seek to user defined timestamp (in seconds) if possible
                if (videoPlayer.duration < seekTo) {
                    reject("video is too short.");
                    return;
                }
                // delay seeking or else 'seeked' event won't fire on Safari
                setTimeout(() => {
                    videoPlayer.currentTime = seekTo;
                }, 200);
                // extract video thumbnail once seeking is complete
                videoPlayer.addEventListener('seeked', () => {
                    console.log('video is now paused at %ss.', seekTo);
                    // define a canvas to have the same dimension as the video
                    const canvas = document.createElement("canvas");
                    canvas.width = videoPlayer.videoWidth;
                    canvas.height = videoPlayer.videoHeight;
                    // draw the video frame to canvas
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                    let jpegFile = canvas.toDataURL("image/jpeg");
                    resolve(jpegFile);
                });
            });
        });
    }

    const discardSection = () => {
        setSelectedLayout(0);
        setSelectedMedia([]);
        setUploadedMedia([]);
        let caseStudyFormCopy = { ...props.caseStudyForm };
        caseStudyFormCopy[props.name].imgType = '';
        caseStudyFormCopy[props.name].image = [];
        props.setCaseStudyFormData(caseStudyFormCopy);
    }

    const handleSave = () => {
        let caseStudyFormCopy = { ...props.caseStudyForm };
        caseStudyFormCopy[props.name].imgType = selectedLayout;
        if (uploadedMedia?.length) {
            uploadedMedia.forEach((media) => {
                if (media.type == 'image') {
                    if (caseStudyFormCopy[props.name].image) {
                        caseStudyFormCopy[props.name].image.push(media.location);
                    } else {
                        caseStudyFormCopy[props.name].image = [media.location];
                    }
                } else {
                    if (caseStudyFormCopy[props.name].videos) {
                        caseStudyFormCopy[props.name].videos.push(media.name);
                    } else {
                        caseStudyFormCopy[props.name].videos = [media.name];
                    }
                }
            })
        }
        props.setCaseStudyFormData(caseStudyFormCopy);
        setUploadOption('');
        setUploadedMedia([]);
        setSelectedMedia([]);
    }

    const deleteMediaHandler = async (ind) => {
        let folderName = "clientMedia/"
        let uploadedMediaCopy = [...uploadedMedia];
        let fileName = getMediaName(uploadedMedia[ind].location);
        deletefile(fileName, folderName)
        uploadedMediaCopy.splice(ind, 1);
        setUploadedMedia(uploadedMediaCopy);
    }

    const embedVideoHandler = () => {
        if (embedInput !== '') {
            let obj = {
                name: embedInput,
                type: 'embed'
            }
            let uploadedMediaCopy = [...uploadedMedia];
            uploadedMediaCopy.push(obj);
            setUploadedMedia(uploadedMediaCopy);
            setEmbedInput('');
        }
    }

    const onUploadSelection = (option) => {
        if(option !== uploadOption){
            setUploadOption(option);
            setSelectedMedia([]);
            let caseStudyFormCopy = { ...props.caseStudyForm };
            caseStudyFormCopy[props.name].imgType = '';
            if (!caseStudyFormCopy[props.name].image) {
                caseStudyFormCopy[props.name].image = [];
            }
            if (!caseStudyFormCopy[props.name].videos) {
                caseStudyFormCopy[props.name].videos = [];
            }
            props.setCaseStudyFormData(caseStudyFormCopy);
        }
    }

    function getMediaName(url) {
        const fileName = url.split('/').pop();
        return fileName;
    }

    function deleteImages(url, index) {
        let folderName = "clientMedia/";
        const fileName = getMediaName(url);
        deletefile(fileName, folderName);
        let caseStudyFormCopy = { ...props.caseStudyForm };
        caseStudyFormCopy[props.name].image.splice(index, 1);
        props.setCaseStudyFormData(caseStudyFormCopy);
    }

    function deleteVideo(index) {
        let caseStudyFormCopy = { ...props.caseStudyForm };
        caseStudyFormCopy[props.name].videos.splice(index, 1);
        props.setCaseStudyFormData(caseStudyFormCopy);
    }

    return <>
        {collapse ? <div className='image-sec-collapse' onClick={() => setCollapse(!collapse)}>
            <span className='text-title'>Images | Videos | GIFs</span>
            <span className='arrow down' onClick={() => setCollapse(!collapse)}></span>
        </div> :
            <div className='media-component'>
                <div className='title'  onClick={() => setCollapse(!collapse)}>
                    <span>Select Media Layout</span>
                    <span className='arrow up' onClick={() => setCollapse(!collapse)}></span>
                </div>
                <div className="image-layout">
                    {layoutTypes.map(layout => {
                        return <div>
                            <input
                                type="radio"
                                id={props.name + "img" + layout.value}
                                name={"imageLayout"}
                                value={layout.value}
                                checked={layout.value === selectedLayout}
                                className={`layout-radio`}
                                onChange={(e) => {
                                    setSelectedLayout(e.target.value)
                                    setUploadOption('');
                                }}
                            />
                            <label for={props.name + "img" + layout.value}><img src={layout.value === selectedLayout ? layout.imageSrcSelected : layout.imageSrc}></img></label>
                            <span className={`layout-name ${layout.value === selectedLayout ? "selected-layout" : ''}`}>{layout.layoutName}</span>
                        </div>
                    })}
                </div>
                <div className='image-selection-wrapper'>
                    <div className='upload-wrapper'>
                        <div className={`upload ${uploadOption === 'upload' || uploadOption === '' ? 'enable' : 'disable'}`}
                            onClick={() => onUploadSelection('upload')}>
                            <img src={uploadIcon} className="upload-icon" />
                            <input type="file" hidden id={`${props.name}fileInput`} onChange={fileInputOnchange} multiple="multiple" accept={selectedLayout === "landscape100" || selectedLayout === "landscape80" ? ".jpeg,.png,.gif,.mov,.mp4,.jpg,.jfif" : ".jpeg,.png,.gif,.jpg,.jfif"} />
                            <button className='upload-btn' onClick={uploadButtonHandler}>Upload</button>
                            <span className='formats'>{selectedLayout === "landscape100" || selectedLayout === "landscape80" ? ".jpeg | .png | .gif | .mov | .mp4" : ".jpeg | .png | .gif"}</span>
                        </div>
                        {selectedLayout === "landscape100" && <div className={`upload ${uploadOption === 'embed' || uploadOption === '' ? 'enable' : 'disable'}`}
                            onClick={() => onUploadSelection('embed')}>
                            <img src={embedVideo} className="upload-icon" />
                            <input type="text" value={embedInput} onChange={(e) => setEmbedInput(e.target.value)} className="input-style" />
                            <button className='upload-btn' onClick={embedVideoHandler}>Embed Video</button>
                            <span className='formats'>YouTube | Vimeo</span>
                        </div>}
                    </div>
                    <div className='upload-list-wrapper'>
                        <div>
                            {
                                props.caseStudyForm[props.name].image.map((media, ind) => {
                                    return <div className='upload-list-item'>
                                        <img src={media} id="uploaded" height="200" alt="Image preview..." className='upload-item-img' />
                                        <div className="upload-image-details">
                                            <div className='upload-item-name'>
                                                <span>{getMediaName(media)}</span>
                                                <img src={Delete} onClick={() => deleteImages(media, ind)} />
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                            {
                                props.caseStudyForm[props.name].videos.map((media, ind) => {
                                    return <div className='upload-list-item'>
                                        <div className='play-button-wrapper'><img src={playIcon} /></div>
                                        <div className="upload-image-details">
                                            <div className='upload-item-name'>
                                                <span>{media}</span>
                                                <img src={Delete} onClick={() => deleteVideo(ind)} />
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                            {
                                uploadedMedia.map((media, ind) => {
                                    return <div className='upload-list-item'>
                                        {
                                            media.type == 'image' || media.thumbnail ? <img src={media.location} id="uploaded" height="200" alt="Image preview..." className='upload-item-img' /> :
                                                <div className='play-button-wrapper'><img src={playIcon} /></div>
                                        }
                                        <div className="upload-image-details">
                                            <div className='upload-item-name'>
                                                <span>{media.name}</span>
                                                <img src={Delete} onClick={() => deleteMediaHandler(ind)} />
                                            </div>
                                            <div id={`${props.name}myProgress-${ind}`} className="myProgress" style={{ display: 'none' }}>
                                                <div id={`${props.name}myBar-${ind}`} className="myBar"></div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        {selectedLayout === 1 && uploadOption === 'embed' && <div className='embed-link'>
                            <span className='title'>Video Link</span>
                            <div className='input-sec'>
                                <input type="text" value={embedInput} onChange={(e) => setEmbedInput(e.target.value)} className="input-style" />
                                <span className='embed-btn' onClick={embedVideoHandler}>Embed</span>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="save_delete_button">
                    <button onClick={() => discardSection()}>Discard Section</button>
                    <button onClick={() => handleSave()}>Save</button>
                </div>
            </div>}
    </>
}

export default MediaComponent;
