import React from 'react'
import './consult.css'

const Consult = () => {
  const consultancies = [
    {
      for: 'Brand Strategy',
      description: 'A detailed discussion on Brand strategy from scratch.',
      price: 1000,
      timeInMinutes: '30'
    },
    {
      for: 'Community Building',
      description: 'A detailed discussion on Brand strategy from scratch.',
      price: 500,
      timeInMinutes: '30'
    },
    {
      for: 'Social Media Marketing',
      description: 'A detailed discussion on Brand strategy from scratch.',
      price: 1000,
      timeInMinutes: '30'
    }
  ]

  return (
    <div className='consult container'>
      {
        consultancies.map((consult, index) => (
          <div key={index} className='box'>
            <div>1:1 call</div>
            <h6 className='darkText'>{consult.for}</h6>
            <p className='fontStart'>{consult.description}</p>
            <div>
              <div>
                <span className='darkText'>₹{consult.price}/</span>
                <span>{consult.timeInMinutes} min</span>
              </div>
              <button>Book now</button>
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default Consult
