import React from "react";
import "./userSection.css";
import CoverImage from "../newImg/CoverImage.png";
import ProfileImage from "../newImg/ProfileImage.png";
import ShareImage from "../newImg/share.png";
import BookmarkImage from "../newImg/bookmark.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CiChat1 } from "react-icons/ci";

const UserSection = ({ userData, setApplyToTalentPoolPage, currentTab }) => {
  function handleCopy(userData) {
    navigator.clipboard.writeText(
      `https://www.unispade.com/userprofile/${userData.username}`
    );
    toast.success("Link Copied");
  }

  const redirectToUserLink = (url) => {
    window.open(url, "_blank");
  };

  const fieldNameToLabel = {
    linkedIn: "LinkedIn",
    instagram: "Instagram",
    xProfile: "Twitter",
  };

  console.log("the current tab is ", currentTab);

  return (
    <>
      <div className="UserProfileSidebar">
        <div className="userProfile">
          <img
            className="profile_image"
            src={userData?.profilePhoto}
            alt="profile_image"
          />
          <div className="UserProfileInfo">
            <p>{userData?.name}</p>
            <p>{userData?.title}</p>
          </div>
        </div>

        <div
          className="ApplyToUserPool"
          onClick={() => setApplyToTalentPoolPage(true)}
        >
          <button>Apply to Talent Pool</button>
        </div>

        {currentTab === "about" && (
          <div className="userAboutSideBar">
            <div className="location">
              <span className="material-symbols-outlined">location_on</span> {userData?.workDetails?.location}
            </div> 
            <div className="about">{userData?.about}</div>
            {/* Social Links Section */}
            <div className="userSocialLinks">
              {/* Loop through the main social links */}
              {Object.keys(userData?.socialLinks).map((key) => {
                return (
                  userData?.socialLinks[key] &&
                  ["linkedIn", "instagram", "xProfile"].includes(key) && (
                    <div
                      className="socialLinkItem" // Class for styling
                      onClick={() =>
                        redirectToUserLink(userData?.socialLinks[key])
                      }
                    >
                      <div className="socialLinkLabel">
                        {fieldNameToLabel[key]}
                      </div>
                      <span className="material-symbols-outlined socialLinkIcon">
                        call_made
                      </span>
                    </div>
                  )
                );
              })}

              {/* Loop through additional otherLinks */}
              {userData?.socialLinks?.otherLinks &&
                userData.socialLinks.otherLinks.map((link) => {
                  return (
                    <div
                      className="socialLinkItem" // Class for styling
                      onClick={() => redirectToUserLink(link.linkUrl)}
                    >
                      <div className="socialLinkLabel">{link.linkTitle}</div>
                      <span className="material-symbols-outlined socialLinkIcon">
                        call_made
                      </span>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default UserSection;
