import AWS from 'aws-sdk';

let profileRedirect = null;

const bucket = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION
});

async function uploadImageToAws(params) {
    return new Promise((resolve, rejatc) => {
        bucket.upload(params, function (err, data) {
            if (err) {
                console.log('There was an error uploading your file: ', err);
                return false;
            }
            resolve(data.Location)
        });
    })
}

export async function uploadImage(imgBuffer) {
    const uniqueKey = new Date().getTime();
    const fileName = uniqueKey + '-' + imgBuffer.name;
    const params = {
        Bucket: "unispade-react-js-s3",
        Key: 'clientMedia/' + fileName,
        Body: imgBuffer,
        ContentType: imgBuffer.type
    };
    const fileUrl = await uploadImageToAws(params);
    return fileUrl;
}

export function getProfileRedirect() {
    return profileRedirect;
}

export function setProfileRedirect(value) {
    profileRedirect = value;
}