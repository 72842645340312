import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
// import onelogo from "../images/one.png";
// import star from "../images/star.png";
// import starempty from "../images/starempty.png";
import heartempty from "../images/heartempty.png";
import bag from "../images/bag.png";
import location2 from "../images/location2.png";
import watch from "../images/watch.png";
import { Link } from "react-router-dom";
// import logo from "../images/logos.png"
import colourheart from "../images/colourheart.png"
import axios from "../axios.js";

function ResCard(props) {

  const history = useHistory()
  function capitalize(input) {
    if (input === undefined) return "";
    var words = input.split(" ");
    var CapitalizedWords = [];
    words.forEach((element) => {
      CapitalizedWords.push(
        element[0]?.toUpperCase() + element.slice(1, element.length)
      );
    });
    return CapitalizedWords.join(" ");
  }
  // const sendingLink = "/agencyprofile/" + props.agency.agencyName + '/' + props.agency._id;
  const sendingLink = "/agencyprofile/" + props.agency.agencyName.split(" ").join("-").toLowerCase();

  const [services, setServices] = useState([]);
  const [colorHeart, setColorHeart] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("branduser") != null) {
      setColorHeart(!props?.liked?.includes(props.agency._id))
    } else {
      setColorHeart(true)
    }

  }, [])


  const changeHeartcolor = () => {
    if (localStorage.getItem("branduser") == null) {
      history.push('/brandlogin');
      window.location.reload()
    }
    // Like Request Here
    const like = {
      agencyId: props.agency._id,
      brandId: JSON.parse(localStorage.getItem('branduser')).user.brandId
    }

    axios.post("/brands/likeAgency", like)
      .then(res => {
        console.log(res.data)
        setColorHeart(false)
      })
  }
  const changeHeart = () => {
    // Unlike Request
    const unlike = {
      agencyId: props.agency._id,
      brandId: JSON.parse(localStorage.getItem('branduser')).user.brandId
    }
    axios.post("/brands/unlikeAgency", unlike)
      .then(res => {
        setColorHeart(true)
      })
  }

  useEffect(async () => {
    setServices(props && props.agency && props.agency.services);
  });

  const getInitialAgencyName = (agencyName) => {
    let nameArr = agencyName.split(' ');
    let initial = '';
    if(nameArr.length === 1){
      initial = nameArr[0][0].toUpperCase();
    }else if(['/','\\','&'].includes(nameArr[1][0])){
      initial = nameArr[0][0].toUpperCase();
    }else{
      initial = `${nameArr[0][0].toUpperCase()} . ${nameArr[1][0].toUpperCase()}`;
    }
    return initial;
  }

  return (
    <div className="res__card">
      <div className="sec__left">
        {props.agency.agencyLogo ? <img src={props.agency.agencyLogo} className="res__logo" style={{ width: "72px", height: "72px" }} alt="" /> :
          // <img src={logo} className="res__logo" style={{width:"72px",height:"72px"}}></img>
          <div className="res_text">{getInitialAgencyName(props.agency.agencyName)}</div>
        }

        <div className="details">
          <h4>
            {capitalize(props.agency.agencyName)}
            {colorHeart ?
              <img src={heartempty} alt="" className="heartempty" onClick={() => changeHeartcolor()} />
              :
              <img src={colourheart} alt="" className="heartcolor" onClick={() => changeHeart()} />
            }
          </h4>

          <div className="tags">
            {services.map((service, index) => {
              if (index > 2) return;
              return <p className="tag">{service?.toUpperCase()}</p>;
            })}
          </div>
          <div className="misc__details">
            <div className="part">
              <img src={location2} alt="" />
              <p className="para color__grey" style={{ height: "15px", paddingTop: "5px" }}>
                {/* {console.log(props.agency.locations)} */}
                {props.agency.locations && props.agency.country ? capitalize(props.agency.locations[0]) + " , " + capitalize(props.agency.country) : capitalize(props.agency.locations[0])}


              </p>
            </div>
            <div className="part">
              <img src={bag} alt="" />
              <p className="para color__grey" style={{ height: "15px", paddingTop: "5px" }}>
                {props.agency.foundedYear
                  ? new Date().getFullYear() -
                  props.agency.foundedYear?.substring(0, 4)
                  : 0}{" "}
                Years
              </p>
            </div>
            <div className="part">
              <img src={watch} alt="" />
              <p className="para color__grey" style={{ height: "15px", paddingTop: "5px" }}>
                {props.agency.agencySize} Employees
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="sec__right">
        {localStorage.getItem("branduser") == null ?
          <Link to="/brandlogin"> <button>Get Quote</button></Link> :
          <Link to="/newprojectform"> <button>Get Quote</button></Link>}


        <Link to={sendingLink}>
          <button type="button">View Profile</button>
        </Link>
      </div>
    </div>
  );
}

export default ResCard;
