import React from 'react'
import './NotInterestedConfirmation.css'
export const NotInterestedConfirmation = ({onClose, onConfirm }) => {
    return (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Are you sure?</h2>
            <p>Do you really want to mark this message as not interested?</p>
            <div className="modal-buttons">
              <button onClick={onConfirm}>Yes</button>
              <button onClick={onClose}>No</button>
            </div>
          </div>
        </div>
      );
  
}
