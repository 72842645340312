import React, { useEffect, useState } from "react";
import "./YourAvailability.css";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdOutlineCancel } from "react-icons/md";
import axios from "../../../../../axios.js";
import moment from "moment";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { ContactSupportOutlined } from "@material-ui/icons";
import { toast } from "react-toastify";
import NewLoader from "../../../../loader/NewLoader/NewLoader.js";
import Select from "react-select";

// add two plus two

function YourAvailability() {
  const timeZones = [
    // "AFST:+04:30 (Kabul)",
    // "BST:+06:00 (Dhaka)",
    // "CST:+08:00 (Beijing, Shanghai, Hong Kong)",
    // "HKT:+08:00 (Hong Kong)",
    "IST:+05:30 (Kolkata)",
    // "WITA:+08:00 (Bali, Makassar)",
    // "JST:+09:00 (Tokyo)",
    // "ALMT:+06:00 (Almaty)",
    // "ICT:+07:00 (Vientiane)",
    // "ULAT:+08:00 (Ulaanbaatar)",
    // "PKT:+05:00 (Karachi, Islamabad)",
    // "SGT:+08:00 (Singapore)",
    // "SST:+08:00 (Singapore)",
    // "LKT:+05:30 (Colombo)",
    // "THA:+07:00 (Bangkok)",
  ];

  const calendars = ["Google Calendar", "Apple Calendar"];

  const initialSchedule = {
    Monday: { checked: false, slots: [{ startTime: "", endTime: "" }] },
    Tuesday: { checked: false, slots: [{ startTime: "", endTime: "" }] },
    Wednesday: { checked: false, slots: [{ startTime: "", endTime: "" }] },
    Thursday: { checked: false, slots: [{ startTime: "", endTime: "" }] },
    Friday: { checked: false, slots: [{ startTime: "", endTime: "" }] },
    Saturday: { checked: false, slots: [{ startTime: "", endTime: "" }] },
    Sunday: { checked: false, slots: [{ startTime: "", endTime: "" }] },
  };

  const [loader, setLoader] = useState(true);
  const [schedule, setSchedule] = useState(initialSchedule);
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    "IST:+05:30 (Kolkata)"
  );
  const [maxBookingPeriod, setMaxBookingPeriod] = useState(0);
  const bookingPeriods = [1, 2, 3, 4, 5, 6, 7];
  const [noticePeriod, setNoticePeriod] = useState(null);
  const noticePeriods = [12, 24, 48, 72];
  const [applyToAll, setApplyToAll] = useState(false);

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  const toast_style = {
    position: "top-right",
    autoClose: 4000,
    draggable: true,
    theme: "light",
    width: "10rem",
  };

  const syncYouCalendar = async () => {
    const { data } = await axios.get(
      `/google-calendar/setUp/${Marketer?.user?._id}`
    );
    console.log("the data in sync your calendar is ", data);
    if (data) {
      window.location.href = data;
    }
  };

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `/mentoring/get-general-availability/${Marketer.user._id}`,
        {
          headers: {
            authorization: "Bearer " + Marketer.token,
          },
        }
      );

      const conditionData = await axios.get(
        `/mentoring/get-availability-conditions/${Marketer.user._id}`,
        {
          headers: {
            authorization: "Bearer " + Marketer.token,
          },
        }
      );

      const newSchedule = { ...initialSchedule };
      data.forEach((item) => {
        if (newSchedule[item.week_day]) {
          newSchedule[item.week_day] = {
            checked: true,
            slots: item.slots.map((slot) => ({
              ...slot,
              startTime: moment(slot.startTime, "hh:mm a").format("HH:mm"),
              endTime: moment(slot.endTime, "hh:mm a").format("HH:mm"),
            })),
          };
        }
      });
      setSchedule(newSchedule);
      setMaxBookingPeriod(conditionData?.data?.maximum_booking_period / 7);
      setNoticePeriod(conditionData?.data?.minimum_notice_period);
      setLoader(false);
      console.log("The updated schedule is ", newSchedule);
    })();
  }, []);

  const handleScheduleChange = (day, field, value, index) => {
    setSchedule((prev) => {
      const newSchedule = { ...prev };
      if (newSchedule[day]) {
        newSchedule[day].slots[index][field] = value;
      }
      return newSchedule;
    });
  };

  const handleApplyToAllChange = (checked, day) => {
    if (checked) {
      const selectedDaySchedule = schedule[day].slots.map((slot) => ({
        ...slot,
      }));
      setSchedule((prev) => {
        const newSchedule = { ...prev };
        Object.keys(newSchedule).forEach((d) => {
          newSchedule[d] = {
            ...newSchedule[d],
            checked: true,
            slots: [...selectedDaySchedule],
          };
        });
        return newSchedule;
      });
    } else {
      setSchedule(initialSchedule);
    }

    setApplyToAll(checked);
  };

  const addTimeSlot = (day) => {
    // Check if the last slot in the array has both startTime and endTime filled
    const lastSlot = schedule[day].slots[schedule[day].slots.length - 1];
    if (lastSlot.startTime !== "" && lastSlot.endTime !== "") {
      setSchedule((prev) => ({
        ...prev,
        [day]: {
          ...prev[day],
          slots: [...prev[day].slots, { startTime: "", endTime: "" }],
        },
      }));
    } else {
      // Optionally show a message or disable the add button when conditions are not met
      alert("Please fill the previous time slot before adding a new one.");
    }
  };

  const removeTimeSlot = (day, slotIndex) => {
    setSchedule((prev) => {
      const newSchedule = { ...prev };
      if (slotIndex !== 0) {
        newSchedule[day].slots.splice(slotIndex, 1);
      }
      return newSchedule;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission logic here
    // Extract and print the schedule information
    const checkedDays = [];
    const filteredSchedule = {};
    Object.keys(schedule).forEach((day) => {
      if (schedule[day].checked) {
        checkedDays.push(day);
        filteredSchedule[day] = { ...schedule[day] };
      }
    });

    const { data } = await axios.post(
      "/mentoring/add-availability/",
      filteredSchedule,
      {
        headers: {
          authorization: "Bearer " + Marketer.token,
        },
      }
    );

    if (data.success === false) {
      toast.error(data.message, toast_style);
    } else {
      const newSchedule = { ...initialSchedule };
      data.data.forEach((item) => {
        if (newSchedule[item.week_day]) {
          newSchedule[item.week_day] = {
            checked: true,
            slots: item.slots.map((slot) => ({
              ...slot,
              startTime: moment(slot.startTime, "hh:mm a").format("HH:mm"),
              endTime: moment(slot.endTime, "hh:mm a").format("HH:mm"),
            })),
          };
        }
      });

      toast.success(data.message, toast_style);
      setSchedule(newSchedule);
    }

    const conditionData = await axios.post(
      "/mentoring/add-availability-conditions/",
      {
        maximum_booking_period: maxBookingPeriod * 7,
        minimum_notice_period: noticePeriod,
      },
      {
        headers: {
          authorization: "Bearer " + Marketer.token,
        },
      }
    );

    setMaxBookingPeriod(conditionData?.data?.maximum_booking_period / 7);
    setNoticePeriod(conditionData?.data?.minimum_notice_period);
  };

  const MaxBookingOptions = bookingPeriods.map((period) => ({
    value: period,
    label: (
      <span className="custom-option">
        <strong>
          {period} {period === 1 ? "week" : "weeks"}
        </strong>{" "}
        in the future
      </span>
    ),
  }));

  const MinNoticePeriodOptions = noticePeriods.map((period) => ({
    value: period,
    label: (
      <span className="custom-option">
        <strong>
          {period} {"Hours"}
        </strong>{" "}
        before the call
      </span>
    ),
  }));
  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "16px",
      color: "#7F7F7F",
      border: "1px solid #ccc",
      borderRadius: "4px",
      backgroundColor: "#FFFFF",
      cursor: "pointer",
      scrollbarWidth: "none",
      padding: "0 0.5rem",
      height: "50px",
      outline: "none",
      "&:focus": {
        outline: "none",
        boxShadow: "none",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "16px",
      padding: "0 0.5rem",
      color: state.isSelected ? "#1f2937" : "#7F7F7F",
      backgroundColor: state.isSelected ? "#e5e7eb" : "#FFFFF",
      borderBottom: "1px solid #ccc", // Add bottom border
      "&:last-child": {
        borderBottom: "none", // Remove bottom border for the last option
      },
      height: "50px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#7F7F7F",
      padding: "0 0.5rem",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "0",
      scrollbarWidth: "none", // Hide scrollbar for Firefox
      "::-webkit-scrollbar": {
        display: "none", // Hide scrollbar for Chrome, Safari, and Opera
      },
    }),
    indicatorSeparator: () => ({
      display: "none", // Remove the "|" separator
    }),
  };

  return (
    <>
      {loader ? (
        <NewLoader></NewLoader>
      ) : (
        <form
          className="availability-form-unique"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="form-div-unique">
            <div className="form-group-unique form-timezone-unique">
              <label>TimeZone:</label>
              {/* <select
            value={selectedTimeZone}
            onChange={(e) => setSelectedTimeZone(e.target.value)}
            className="custom-select-unique"
          >
            {timeZones.map((tz) => (
              <option key={tz} value={tz}>
                {tz}
              </option>
            ))}
          </select> */}
              <Dropdown
                options={timeZones}
                onChange={(e) => setSelectedTimeZone(e.value)}
                value={selectedTimeZone}
                className="custom-dropdown"
              />
            </div>

            <label className="label-schedule-unique">Schedule:</label>
            <br></br>
            <div className="form-group-unique schedule-group-unique">
              {Object.keys(schedule).map((day, index) => (
                <React.Fragment key={day}>
                  <div className="schedule-item-unique">
                    <label>
                      <input
                        type="checkbox"
                        checked={schedule[day].checked}
                        onChange={(e) => {
                          const { checked } = e.target;
                          setSchedule((prev) => ({
                            ...prev,
                            [day]: { ...prev[day], checked },
                          }));
                        }}
                      />
                      {day}
                    </label>
                    <div>
                      <div className="time-slots-icon-unique">
                        <div className="time-selectors-unique">
                          {schedule[day].slots &&
                            schedule[day].slots.map((slot, slotIndex) => (
                              <div
                                key={slotIndex}
                                className="time-selector-unique"
                              >
                                <input
                                  type="time"
                                  value={slot.startTime || "00:00"}
                                  onChange={(e) =>
                                    handleScheduleChange(
                                      day,
                                      "startTime",
                                      e.target.value,
                                      slotIndex
                                    )
                                  }
                                  // disabled={!schedule[day].checked}
                                  placeholder="00"
                                />
                                <span> - </span>
                                <input
                                  type="time"
                                  value={slot.endTime || "00:00"}
                                  onChange={(e) =>
                                    handleScheduleChange(
                                      day,
                                      "endTime",
                                      e.target.value,
                                      slotIndex
                                    )
                                  }
                                  // disabled={!schedule[day].checked}
                                  placeholder="00"
                                />
                                {slotIndex !== 0 && (
                                  <MdOutlineCancel
                                    className="cancel-slot-icon-unique"
                                    onClick={() =>
                                      removeTimeSlot(day, slotIndex)
                                    }
                                  />
                                )}
                                {slotIndex === 0 && (
                                  <IoIosAddCircleOutline
                                    className={`add-slot-icon-unique ${
                                      slot.startTime === "" ||
                                      slot.endTime === ""
                                        ? "disabled"
                                        : ""
                                    }`}
                                    onClick={() => addTimeSlot(day)}
                                  />
                                )}
                              </div>
                            ))}
                        </div>
                      </div>
                      {day === "Monday" && (
                        <div className="apply-to-all-unique">
                          <label className="title" style={{ fontWeight: 400 }}>
                            <input
                              type="checkbox"
                              checked={applyToAll}
                              onChange={(e) =>
                                handleApplyToAllChange(e.target.checked, day)
                              }
                            />
                            <span>Apply to all other days</span>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>

            <div className="form-group-unique">
              <label>Maximum booking period: </label>
              <div className="form-caption-unique">
                <Select
                  value={MaxBookingOptions.find(
                    (option) => option.value === maxBookingPeriod
                  )}
                  onChange={(selectedOption) =>
                    setMaxBookingPeriod(selectedOption.value)
                  }
                  options={MaxBookingOptions}
                  styles={customStyles}
                  className="custom-select"
                />
                <span className="note-unique">
                  How far in the future do you want to allow the bookings to be
                  made?
                </span>
              </div>
            </div>

            <div className="form-group-unique">
              <label>Minimum Notice Period: </label>
              <div className="form-caption-unique">
                <Select
                  value={MinNoticePeriodOptions.find(
                    (option) => option.value === noticePeriod
                  )}
                  onChange={(selectedOption) =>
                    setMaxBookingPeriod(selectedOption.value)
                  }
                  options={MinNoticePeriodOptions}
                  styles={customStyles}
                  className="custom-select"
                />
                <span className="note-unique">
                  What is the minimum notice period you want between the call
                  booking and the actual call?
                </span>
              </div>
            </div>

            <div className="form-group-unique">
              <label>Sync your calendar:</label>
              <div className="form-caption-unique">
                <div className="custom-div-unique">
                  {Marketer?.user?.verifiedContact?.googleMeetId ? (
                    <div className="handleSyncCalender">
                      <input
                        type="email"
                        placeholder="Calendar synced!"
                        readOnly={true}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                      <button type="button" onClick={() => syncYouCalendar()}>
                      Switch email
                      </button>
                    </div>
                  ) : (
                    <div className="handleSyncCalender">
                      <input
                        type="email"
                        placeholder="Click here to sync"
                        readOnly={true}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                      <button type="button" onClick={() => syncYouCalendar()}>
                        Sync Calendar
                      </button>
                    </div>
                  )}
                </div>
                <span className="note-unique">
                  Candidates will not be able to book sessions during the slots
                  with existing events on your calendar
                </span>
              </div>
            </div>

            {/* <div className="form-group-unique">
          <label>Integrate your meeting tool: </label>
          <div className="form-caption-unique">
            <input
              type="text"
              placeholder="Enter meeting tool integration details"
            />
            <span className="note-unique">
              Candidates will not be able to book sessions during the slots with
              existing events on your calendar
            </span>
          </div>
        </div> */}

            <div className="form-buttons-unique">
              <button
                type="button"
                className="cancel-button-unique"
                onClick={() => {
                  /* handle cancel logic */
                }}
              >
                Cancel
              </button>
              <button type="submit" className="save-button-unique">
                Save
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default YourAvailability;
