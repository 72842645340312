import React from "react";
import { useHistory } from "react-router-dom";
import { Redirect, whiteArrow } from "../images";
import "./topComp.css";

const TopComp = () => {
  return (
    <div className="topComp borderWithGradient">
            <div className='heroSection'>
                <h1>
                    The community driven hiring platform
                </h1>
        <p>
                    Discover the talent recommended by the top 10%. 
                    Hire from talent pools created by the industry leaders. 
                    Building the biggest referral led talent network in the world.
        </p>
                <a href='https://forms.gle/io3mjcjwLZgwuLqi9' target='blank'>
                <button className='exploreTalentBtn'>
            Explore Talent
            <img
              src={whiteArrow}
              alt="Explore Talent Icon"
              width="20"
              height="20"
              style={{ border: "1px solid #fff" }}
            />
          </button>
        </a>
      </div>
            <div className='statsSection'>
                <div className='leftStatsSection'>
                    <div className='numberStats'>
                        10x
                    </div>
                    <div className='infoStats'>
            Candidates with referrals are 10x more hirable
          </div>
        </div>
                <div className='rightStatsSection'>
                <div className='numberStats'>
                    50%
                </div>
                <div className='infoStats'>
            Referrals can reduce the time to hire an employee by 50%
          </div>

        </div>
      </div>
    </div>
    )
}

export default TopComp;
