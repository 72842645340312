import React, { useState, useEffect } from "react";
import "../../styles/ProjectList.css";
import arrowdown from "../../images/arrowdown.png";
import ProjectCard from "./ProjectCard";
import { Link, useHistory } from "react-router-dom";
import axios from "../../axios.js";
import Loader from "../loader/Loader";

function ProjectList() {
  const history = useHistory();
  //////////  Filters open functionality for mobile view starts////////
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [AllAgencies, setAllAgencies] = useState();
  const openFilterPart = () => {
    setIsOpenFilter(prev => setIsOpenFilter(!prev));

    // closing all others open dropdown filters
    setIsOpenprotype(false);
    setIsOpenBrand(false);
  };
  const [isOpenBrand, setIsOpenBrand] = useState(false);
  const isBrandtype = () => {
    setIsOpenBrand(prev => setIsOpenBrand(!prev));
  };
  const [isOpenprotype, setIsOpenprotype] = useState(false);
  const isProtype = () => {
    setIsOpenprotype(prev => setIsOpenprotype(!prev));
  };
  const [status, setStatus] = useState(undefined);
  const [type, setType] = useState(undefined);

  const changeHandler = (e) => {
    if (e.target.name == "status") {
      setStatus(e.target.id)
    } else {
      setType(e.target.id)
    }
  }

  const resetFilter = () => {
    // setStatus(undefined);
    // setType(undefined);
    setStatus("All");
    setType("all");
  }

  //////////  Filters open functionality for mobile view ends //////////

  // open new project datas starts
  // const opennprodatas = e => {
  //   const item = e.target;
  //   item.classList.toggle("col__pink");

  //   const npdata = document.querySelector(".newproject__datas");
  //   npdata.classList.toggle("shownpdata");
  //   // console.log("npdata", npdata);
  // };
  // const opennprodatasalt = e => {
  //   const item = e.target;
  //   item.classList.toggle("col__pink");

  //   const npdata = document.querySelector(".newproject__datasalt");
  //   npdata.classList.toggle("shownpdata");
  //   // console.log("npdataalt", npdata);
  // };
  // open new project datas ends

  useEffect(() => {
    setStatus("All");
    setType("all");
  }, [])

  useEffect(() => {

    if (localStorage.getItem("branduser") === null) {
      history.push('/brandlogin');
      return <></>;
    }
    const reqBrand = {
      brandId: JSON.parse(localStorage.getItem("branduser")).user.brandId,
    };

    if (status !== 'All') reqBrand.status = status;
    if (type !== 'all') reqBrand.type = type;


    // try {
    //   const res = axios.post(
    //     "/brands/getAllProjects",  reqBrand);

    //     setAllAgencies(res);
    // } catch (e) {
    //   console.log(e);
    // }
    axios.post("/brands/getAllProjects", reqBrand)
      .then(response => {
        setAllAgencies(response.data)
      })
      .catch(error => {
        console.log(error)
      })


  }, [status, type]);

  if (AllAgencies) {
    return (
      <div className="ProjectList__cont">
        <div className="newproject__cont">

          <p className="para newprojectbtn"
          // onClick={opennprodatas}
          >
            <Link to="/newproject" style={{ textDecoration: "none", color: "black" }}>
              + New Project
            </Link>
          </p>

          {/* <div className="newproject__datas">
            <p className="para newprojectbtn">My Profile</p>
            <p className="para newprojectbtn">Members</p>
            <p className="para newprojectbtn">Saved Items</p>
          </div> */}
        </div>

        <div className="ProjectList">
          <div className="fil__res__cont">
            <div className="filter__sec">
              <div className="filter__top ">
                <p
                  className="text__left cursor__pointer fw__600"
                  onClick={openFilterPart}
                >
                  Filters{" "}
                  <img
                    className={`arrow__down ${isOpenFilter && "rotate180"}`}
                    src={arrowdown}
                  />
                </p>
                <button className="resetfilters" onClick={resetFilter}>Reset Filters</button>
              </div>
              <div
                className={`filter__parttab filter__pro__type  ${isOpenFilter &&
                  "dflex"}`}
              >
                <div
                  className={`part__top cursor__pointer ${isOpenFilter &&
                    "dflex"}`}
                  onClick={isBrandtype}
                >
                  <p className="text__left">Project Status</p>{" "}
                  <img
                    className={`arrow__down ${isOpenBrand && "rotate180"}`}
                    src={arrowdown}
                  />
                </div>
                <div
                  className={`filter__parttab filter__pro__type  part__bottom ${isOpenFilter && "dflex"}`}>
                  <div className="inp__field">
                    <input type="radio" id="All" name="status" onChange={changeHandler} checked={(status === "All")} />
                    <label htmlFor="All"> All </label>
                  </div>
                  <div className="inp__field">
                    <input type="radio" id="Planned" name="status" onChange={changeHandler} checked={(status === "Planned")} />
                    <label htmlFor="Planned"> Complete </label>
                  </div>
                  <div className="inp__field">
                    <input type="radio" id="Incomplete" name="status" onChange={changeHandler} checked={(status === "Incomplete")} />
                    <label htmlFor="Incomplete"> Incomplete </label>
                  </div>
                </div>
              </div>
              <div className={`filter__parttab filter__pro__type  ${isOpenFilter && "dflex"}`}>
                <div
                  className={`part__top cursor__pointer ${isOpenFilter && "dflex"}`}
                  onClick={isProtype}
                >
                  <p className="text__left">Project Type</p>{" "}
                  <img
                    className={`arrow__down ${isOpenprotype && "rotate180"}`}
                    src={arrowdown}
                  />
                </div>

                <div className={`part__bottom  ${isOpenprotype ? "dflex" : "dnoneimp"}`}>
                  <div className="inp__field">
                    <input type="radio" id="all" name="type" value="all" onChange={changeHandler} checked={(type === "all")}></input>
                    <label htmlFor="all">All</label>
                  </div>
                  <div className="inp__field">
                    <input type="radio" id="request" name="type" value="request" onChange={changeHandler} checked={(type === "request")}></input>
                    <label htmlFor="request">Requested</label>
                  </div>
                  <div className="inp__field">
                    <input type="radio" id="open" name="type" onChange={changeHandler} value="open" checked={(type === "open")}></input>
                    <label htmlFor="open">Open</label>
                  </div>
                  {/* <div className="inp__field">
                  <input type="checkbox" id="leadfom" />
                  <label htmlFor="leadfom" className="para">
                    Lead Form
                  </label>
                </div> */}
                </div>
              </div>
            </div>

            {/* Alt new project btn starts */}
            <div className="newproject__contalt">
              <p
                className="para color__orange newprojectbtnalt"
              // onClick={opennprodatasalt}
              >
                + New Project
              </p>
              <div className="newproject__datasalt">
                <p className="para newprojectbtnalt">My Profile</p>
                <p className="para newprojectbtnalt">Members</p>
                <p className="para newprojectbtnalt">Saved Items</p>
              </div>
            </div>
            {/* Alt new project btn ends */}

            <div className="search__res__sec">
              <div className="search__res">
                <div className="card__sec">

                  {AllAgencies.map((agency, i) => {
                    return (
                      <ProjectCard key={i} project={AllAgencies[i]} />
                    );
                  }
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (<Loader/>)
}

export default ProjectList;
