import React, {useEffect} from "react";
import "../../styles/BUserProfile.css";
import girl from "../../images/girl.png";
import linkedinbs from "../../images/linkedinbs.png";
import UNotifyTab from "./UNotifyTab";
import UActivityTab from "./UActivityTab";
import UEditProfile from "./UEditProfile";
import USavedItems from "./USavedItems";
import {useHistory} from "react-router-dom";

function BUserProfile() {
  // tab switching
  const history = useHistory();

  useEffect(() => {
    if( localStorage.getItem("branduser") === null ) {
      history.push('/brandlogin');
      return <></>;
    }
  }, [] );

  const switchTabs = e => {
    const tabItem = e.target;

    // For btn activation
    const alltablinks = document.querySelectorAll(".cat__btn");

    alltablinks.forEach(link => {
      link.classList.remove("activecat"); //deactivating rest of the links
    });

    tabItem.classList.add("activecat"); //activating the link which is clicked

    //for component activation
    const destid = e.target.getAttribute("dest"); //getting the destination id which needs to be activated.
    const tabelement = document.getElementById(destid); //element which we need to activate

    const allstatedatas = document.querySelectorAll(".usertab");

    allstatedatas.forEach(item => {
      item.classList.remove("activetab");
    });

    tabelement.classList.add("activetab"); //activating the link which is clicked
  };

  return (
    <div className="buserprofile">
      <div className="sec__left">
        <img src={girl} className="profilepic" alt="" />
        <div className="name__link">
          <p className="para fw__600">Pooja Sharma</p>
          <img src={linkedinbs} className="linkedinlogo" alt="" />
        </div>
        <p className="para">Chief Marketing Officer</p>
        <p className="para fw__600">Nike</p>
      </div>
      <div className="sec__right">
        <div className="cat__btns">
          <p
            className="cat__btn para activecat"
            onClick={switchTabs}
            dest="notifytab"
          >
            Notifications
          </p>
          <p className="cat__btn para" onClick={switchTabs} dest="activitytab">
            Activity
          </p>
          <p className="cat__btn para" onClick={switchTabs} dest="edittab">
            Edit Profile
          </p>
          <p className="cat__btn para" onClick={switchTabs} dest="savedtab">
            Saved Items
          </p>
        </div>
        <div className="var__tabs">
          <UNotifyTab />
          <UActivityTab />
          <UEditProfile />
          <USavedItems />
        </div>
      </div>
    </div>
  );
}

export default BUserProfile;
