import React from "react";
import { Helmet } from "react-helmet";
import '../../../src/components/ThankYouPage/ThankYouPage.css'
import handShake from '../../../src/components/ThankYouPage/images/handshake.svg'
import topArrow from '../../../src/components/ThankYouPage/images/arrow-top-right-thin.svg'
import { useHistory } from "react-router-dom";

const PaymentFailed = () => {
    const history = useHistory();

    function handleRedirect() {
        history.push('/user-dashboard/talent')
    }

    return (
        <>
            <Helmet>
                <title>Sorry</title>
            </Helmet>

            <div className="thank_you_container">
                <div className='handshakeImg'>
                    <img src={handShake} alt="handshake" />
                </div>

                <h3>Unable to Process Payment!</h3>

                <p>Sorry!
                    <br />
                    <span>Your payment is unsuccessful.Please try again.</span>
                </p>

                <button type="button" className="viewTalentPoolBtn" onClick={handleRedirect}>
                    <span>Go back to TalentPool</span>
                    <img src={topArrow} alt="top arrow" />
                </button>
            </div>
        </>
    );
};

export default PaymentFailed;
