import React, {useEffect, useState} from "react";
import "../../styles/ADashboard.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect, useHistory
} from "react-router-dom";
// import notificationg from "../../images/notificationg.png";
// import dotg from "../../images/dotg.png";
// import messageg from "../../images/messageg.png";
import BSidebar from "./BSidebar";
// import ASidebarAlt from "./BSidebarAlt";
// import ADashboardComp from "./BDashboardComp";
import AOnboarding from "./BOnboarding";
// import AProposals from "./BProposals";
// import ANewBusiness from "./BNewBusiness";
import BEditProfile from "./BEditProfile";
// import ASubmitReferences from "./BSubmitReferences";
import BTeam from "./BTeam";
// import ATeamAddMemb from "./BTeamAddMemb";
// import AYourProfile from "./BYourProfile";
// import AProposalTemplate from "./BProposalTemplate";
import ScrollToTop from "../ScrollToTop";
import axios from "../../axios";
import Loader from "../loader/Loader";

function ADashboard() {

  const history = useHistory();
  const branduser = JSON.parse(localStorage.getItem("branduser"));
  const [brand,setBrand]=useState()

  useEffect( () => {
    if( branduser=== null ) {
      history.push('/brandlogin');
      return <></>;
    }
  }, [])


  useEffect(() => {
    const getInfo = async () => { 
      try {
        const getBrandData=await axios.post("/brands/getBrandProfile",{id:branduser.user.brandId})
        setBrand(getBrandData.data.Brand)
      } catch (e) {
        console.log(e);
      }
    };
    getInfo();
  }, []);

   

  const logoUpdate = async (logo) => {
    try {
      // Make an API call to update the brand's logo
      const res = await axios.post(
        "/brands/BrandEditProfile",
        {
          id: brand._id,
          updateProfile:{brandLogo:logo}
        },
       
      );    
  
      // Update the brand state with the new logo
      setBrand(prevValue=>({...prevValue,brandLogo:logo}));
      return res
    } catch (error) {
      console.log(error);
    }
  };


  if(brand){
    return (
      <Router>
        <div className="adashboard__cont">
          <ScrollToTop />
          <BSidebar brandData={brand}  logoUpdate={logoUpdate}/> 
          <Switch>
            <Route path="/adashboard/aonboarding" exact>
              <AOnboarding />
            </Route>
            <Route path="/bdashboard/beditprofile" exact>
              <BEditProfile  brandData={brand} logoUpdate={logoUpdate}/>
            </Route>
            <Route path="/bdashboard/bteam" exact>
              <BTeam brandId={branduser.user.brandId} />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }else{
    return <Loader/>
  }
 
}

export default ADashboard;
