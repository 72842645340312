import React, { useEffect } from 'react'
import SearchImg from "../../../../images/searchgrey.png"
import PlusImg from "../../img/plus-box.png"
import CancelImg from "../../img/cancel.png"
import { useLocation } from 'react-router-dom'

const BeforeCompanySelected = ({
    activeSearch,
    searchCompany,
    setSelectedCompany,
    searchCompanyFunc
}) => {

    return (
        <>
            <p>Search Your {activeSearch}</p>
            <div className='searchCompany'>
                <img src={SearchImg} alt="" />
                <input onChange={searchCompanyFunc} placeholder={`Search and see if your ${activeSearch} profile already exists...`} type="text" />
            </div>
            {searchCompany && <div className='existingCompany'>
                {searchCompany.length > 0 ? <>
                    {activeSearch === "Brand" ? searchCompany.map((key, index) => (
                        <button onClick={() => setSelectedCompany(key)} >
                            <img
                                src={key.clientLogo} alt="" />
                            <p>{key.clientName}</p>
                            <span>{key.clientEmailHost}</span>
                        </button>
                    )) : searchCompany.map((key, index) => (
                        <button onClick={() => setSelectedCompany(key)}>
                            <img
                                src={key.agencyLogo} alt="" />
                            <p>{key.agencyName}</p>
                            <span>{key.agencyEmailHost}</span>
                        </button>
                    ))
                    }</> : <>
                    <button >
                        <img
                            src={CancelImg} alt="" />
                        <p>No results</p>

                    </button>
                    <button >
                        <img
                            src={PlusImg} alt="" />
                        <p>Create New Profile</p>
                    </button>
                </>}

            </div>}
        </>
    )
}

export default BeforeCompanySelected
