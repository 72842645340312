import React from "react";
import './EditPoolCategory.css';

const EditPoolCategory = ({
  dialog,
  dialogRef,
  category,
  handleSetCategoryInput,
  id,
  editFunction,
}) => {
  return (
    <>
      <div className="dialogOverlay" />
      <dialog ref={dialogRef} className="editPoolDialog">
        <div className="editInputFields">
          <input
            type="text"
            placeholder="Category Name"
            name="name"
            value={category.name}
            onChange={handleSetCategoryInput}
          />
          <input
            type="number"
            placeholder="Price Per User"
            name="price_per_user"
            value={category.price_per_user}
            onChange={handleSetCategoryInput}
          />
          <input
            type="number"
            placeholder="Access Limit"
            name="accessLimit"
            value={category.accessLimit}
            onChange={handleSetCategoryInput}
          />
        </div>
        <div className="editPoolButtons">
          <button
            type="button"
            className="dialogCloseBtn dialogButton"
            onClick={() => dialog.close()}
          >
            Cancel
          </button>
          <button
            type="button"
            className="dialogDeleteBtn dialogButton"
            onClick={() => editFunction(id)}
          >
            Edit
          </button>
        </div>
      </dialog>
    </>
  );
};

export default EditPoolCategory;
