import React, { useState, useEffect } from "react";
import "./MarketerDashb.css";
import Sidebar from "../MarketerDashbCompo/Sidebar/Sidebar";
import Pannel from "../MarketerDashbCompo/pannel/pannel";
import axios from "../../axios";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import toastStyle from "../../assets/toastConfig";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../loader/Loader";
import { useLocation } from "react-router-dom";

const MarketerDashb = () => {
  const [selectedCompany, setSelectedCompany] = useState();
  const [breadCrumbNextRoute, setBreadCrumbNextRoute] = useState();
  const location = useLocation();

  let history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");
  console.log("the id is ", id);
  const [query, setQuery] = useState(location.search.split("?")[1]);

  console.log("the query is ", query);
  const Fields = [
    // { name: 'credits', text: "Credits", disable: id !== (undefined || null) },
    {
      name: "talent",
      text: "Talent Pools",
      disable: id !== (undefined || null),
    },
    {
      name: "mentoring",
      text: "Mentoring",
      disable: id !== (undefined || null),
    },
    { name: "activity", text: "Activity", disable: id !== (undefined || null) },
    { name: "message", text: "Message", disable: id !== (undefined || null) },

    { name: "profile", text: "Profile", disable: false },
    { name: "setting", text: "Settings", disable: id !== (undefined || null) },

    {
      name: "bank-details",
      text: "Bank Details",
      disable: id !== (undefined || null),
    },
    {
      name: "pool-analytics",
      text: "Pool Analytics",
      disable: id !== (undefined || null),
    },
    {
      name: "new-company-profiles",
      text: "New Company Profiles",
      disable: id !== (undefined || null),
    },
  ];

  const [Marketersdata, setMarketersdata] = useState();
  const [userData, setUserData] = useState({});
  const [currentProfileData, setCurrentProfileData] = useState({});
  const [Credits, setCredits] = useState();

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  useEffect(() => {
    if (Marketersdata) {
      setUserData({
        name: Marketersdata.name ? Marketersdata.name : "",
        email: Marketersdata.email ? Marketersdata.email : "",
        role: Marketersdata.role ? Marketersdata.role : "",
        username: Marketersdata.username ? Marketersdata.username : "",
        about: Marketersdata.about ? Marketersdata.about : "",
        officialMailId: Marketersdata.officialMailId
          ? Marketersdata.officialMailId
          : "",
        password: Marketersdata.password ? Marketersdata.password : "",
        profilePhoto: Marketersdata.profilePhoto
          ? Marketersdata.profilePhoto
          : "",
        experience: Marketersdata.experience ? Marketersdata.experience : [],
        education: Marketersdata.education ? Marketersdata.education : [],
        socialLinks: Marketersdata.socialLinks ? Marketersdata.socialLinks : {},
        workDetails: Marketersdata.workDetails ? Marketersdata.workDetails : "",
        verifiedContact : Marketersdata?.verifiedContact ? Marketersdata?.verifiedContact : {},

        resume: Marketersdata.resume ? Marketersdata.resume : "",
        phoneNumber: Marketersdata.phoneNumber ? Marketersdata.phoneNumber : "",
        title: Marketersdata.title ? Marketersdata.title : "",
        avatar: Marketersdata.avatar ? Marketersdata.avatar : "",
      });

      setCurrentProfileData({
        name: Marketersdata.name ? Marketersdata.name : "",
        email: Marketersdata.email ? Marketersdata.email : "",
        role: Marketersdata.role ? Marketersdata.role : "",
        username: Marketersdata.username ? Marketersdata.username : "",
        about: Marketersdata.about ? Marketersdata.about : "",
        officialMailId: Marketersdata.officialMailId
          ? Marketersdata.officialMailId
          : "",
        password: Marketersdata.password ? Marketersdata.password : "",
        profilePhoto: Marketersdata.profilePhoto
          ? Marketersdata.profilePhoto
          : "",
        experience: Marketersdata.experience ? Marketersdata.experience : [],
        education: Marketersdata.education ? Marketersdata.education : [],
        socialLinks: Marketersdata.socialLinks ? Marketersdata.socialLinks : {},
        workDetails: Marketersdata.workDetails ? Marketersdata.workDetails : "",
        resume: Marketersdata.resume ? Marketersdata.resume : "",
        phoneNumber: Marketersdata.phoneNumber ? Marketersdata.phoneNumber : "",
        title: Marketersdata.title ? Marketersdata.title : "",
        avatar: Marketersdata.avatar ? Marketersdata.avatar : "",
        verifiedContact : Marketersdata?.verifiedContact ? Marketersdata?.verifiedContact : {},
      });
    }
  }, [Marketersdata]);

  useEffect(() => {
    let MarketerResponse;
    const fetchUserData = async () => {
      try {
        // checking that if user is Coming from Claim Profile or the Login
        if (id === undefined || id == null) {
          // getting marketers Data from LocalStorage

          // checking if marketers data is null then it will redirect it to the login
          if (Marketer === null) {
            history.push("/user-login");
          }
          // but if data is there then get  all data by Username its username stored in localStorage
          MarketerResponse = await axios.get(
            `/marketers/getMarketerById/${Marketer.user._id.valueOf()}`,
            { headers: { authorization: "Bearer " + Marketer.token } }
          );
        } else {
          // if user has come from the claimProfile than get its data by id
          MarketerResponse = await axios.get(`/credits/getCreditById/${id}`);
          // here we are checking user has already created the profile and again he is claiming the profile
          if (MarketerResponse && MarketerResponse.data.marketers_userName) {
            history.push("/user-login");
          }
          // if user is coming here to claim his profile than he can only access his profile
          setPannelname("Basic Info");
        }
        setMarketersdata(MarketerResponse.data);

        // getting all the Credits alloted to users here
        const creditsResponse = await axios.get(
          `/credits/getMarketersCredits?email=${MarketerResponse?.data.email}`
        );
        setCredits(creditsResponse.data);
      } catch (error) {
        console.log(error?.response.status);
        if (error?.response.status === 401) {
          history.push("/user-login");
        }
      }
    };
    fetchUserData();
  }, []);

  console.log("the markter data is ", Marketersdata);

  const [pannelName, setPannelname] = useState(
    window.location.pathname.split("/")[2]
  );
  // handling pannel name for User
  const handleActivePannel = (name) => {
    setPannelname(name);
  };

  // here we are saving and updating user Profile according to which he has com either for claim or edit profile
  console.log("The user data inside markter dashb is ", userData);
  const onMarktersFormSubmit = async (formData) => {
    try {
      let updateMarketer;
      if (
        !formData.name ||
        !formData.username ||
        !formData.email ||
        !formData.officialMailId ||
        !formData.password ||
        !formData.title ||
        !formData.phoneNumber
      ) {
        toast.error("Please Fill all the mandatory Details", toastStyle);
      } else if (id === null) {
        const data = { ...formData };
        formData.username === Marketersdata.username && delete data.username;
        updateMarketer = await axios.post("/marketers/updateMarketer", data, {
          headers: { authorization: "Bearer " + Marketer.token },
        });

        if (updateMarketer.status === 200) {
          if (!updateMarketer.data.isUpdated) {
            toast.error(updateMarketer.data.msg, toastStyle);
          } else {
            toast.success(updateMarketer.data.msg, toastStyle);
            setMarketersdata(updateMarketer.data.data);
          }
        } else {
          toast.error("Internal Server Error", toastStyle);
        }
      } else {
        if (formData.password !== formData.confirmPassword) {
          toast.error("Password and Confirm Password didn't match", toastStyle);
        } else {
          updateMarketer = await axios.post(`/marketers/addMarketer`, {
            ...formData,
            verify: true,
          });
          if (updateMarketer.data.isClaimed === false) {
            toast.error(updateMarketer.data.msg, toastStyle);
          } else {
            toast.success(updateMarketer.data.msg, toastStyle);
            setTimeout(() => {
              history.push("/user-login");
            }, 2000);
          }
        }
      }
    } catch (error) {
      toast.error("Something went wrong ", toastStyle);
      console.log(error);
    }
  };

  // for rejecting caseStudy from Credits
  const rejectCasestudy = async (casestudyid) => {
    try {
      const RejectCaseStudy = await axios.post(
        `/credits/updateCreditStatus`,
        { id: casestudyid, status: "rejected" },
        { headers: { authorization: "Bearer " + Marketer.token } }
      );
    } catch (error) {
      console.log(error);
      toast.error("Something went Wrong ", toastStyle);
    }
  };

  // updating Password
  const updatePassword = async (formData) => {
    try {
      if (
        !formData.CurrentPassword ||
        !formData.password ||
        !formData.confirmPassword
      ) {
        toast.error("Fill all the Fields", toastStyle);
      } else if (formData.password !== formData.confirmPassword) {
        toast.error("Password and Confirm Password didn't match", toastStyle);
      } else {
        formData.id = Marketersdata._id;
        const UpdatPassw = await axios.post(
          "/marketers/updatePassword",
          formData,
          { headers: { authorization: "Bearer " + Marketer.token } }
        );
        if (UpdatPassw.data.isSuccess) {
          toast.success(UpdatPassw.data.msg, toastStyle);
        } else {
          toast.error(UpdatPassw.data.msg, toastStyle);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went Wrong ", toastStyle);
    }
  };

  return (
    <>
      {!Marketersdata ? (
        <Loader />
      ) : (
        <div className="MarketerDashb">
          <Sidebar
            query={query}
            setQuery={setQuery}
            Fields={Fields}
            profilePhoto={
              Marketersdata.profilePhoto ? Marketersdata.profilePhoto : ""
            }
            activePannel={handleActivePannel}
            usernameExist={Marketersdata.userName ? false : true}
            setBreadCrumbNextRoute={setBreadCrumbNextRoute}
            Marketersdata={Marketersdata}
          />
          <Pannel
            query={query}
            setQuery={setQuery}
            Fields={Fields}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
            pannelName={pannelName}
            userData={userData}
            setUserData={setUserData}
            currentProfileData={currentProfileData}
            setCurrentProfileData={setCurrentProfileData}
            caseStudies={Credits}
            type={Marketersdata.type}
            onsubmitForm={onMarktersFormSubmit}
            rejectCasestudy={rejectCasestudy}
            updatePassword={updatePassword}
            setBreadCrumbNextRoute={setBreadCrumbNextRoute}
            breadCrumbNextRoute={breadCrumbNextRoute}
            setMarketersdata={setMarketersdata}
            socialLinks={
              Marketersdata.socialLinks ? Marketersdata.socialLinks : {}
            }
          />
          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default MarketerDashb;
