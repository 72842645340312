import CarrerResoure from "./sub-components/CarrerResoure.js";
import FindMentor from "./sub-components/FindMentor.js";
import LastSect from "./sub-components/LastSect.js";
import Main from "./sub-components/Main.js";
import Mentor from "./sub-components/Mentor.js";
import Mentor2 from "./sub-components/Mentor2.js";
import WorkFlow from "./sub-components/WorkFlow.js";
import ClientTestimonials from "./sub-components/ClientTestimonial.js";
import Faq from "./sub-components/Faq.js";
import "./MentorProductPage.css";
function MentorProductPage() {
  return (
    <div className="app-main">
      <Main></Main>
      <FindMentor></FindMentor>
      <WorkFlow></WorkFlow>
      <Mentor></Mentor>
      <Mentor2></Mentor2>
      <ClientTestimonials />
      <CarrerResoure></CarrerResoure>
      <Faq></Faq>
      <LastSect></LastSect>
    </div>
  );
}

export default MentorProductPage;
