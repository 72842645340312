import React, { useEffect, useState } from "react";
import "./HeaderNew.css";
import avatar from "../../images/avatar.png";
import { Link, useHistory } from "react-router-dom";
import { UnispadeBlack } from "../HomePageComponents/images";
import { MdExpandLess, MdExpandMore } from "react-icons/md";

const HeaderNew = ({ setSelectedNavbarTab, ...props }) => {
  console.log("Props:", props);
  const history = useHistory();
  const [openMobBar, setOpenMobBar] = useState(false);

  const [TabDropdown, setTabDropdown] = useState({});

  useEffect(() => {
    let outerElem = document.getElementsByClassName("App")[0];
    if (window.innerWidth <= 425) {
      outerElem.addEventListener("click", hideHeaderMob);
    }
    return () => {
      if (window.innerWidth <= 425)
        outerElem.removeEventListener("click", hideHeaderMob);
    };
  }, []);

  const hideHeaderMob = (event) => {
    setOpenMobBar(false);
  };

  const logoutHandler = () => {
    localStorage.clear();
    window.location.reload();
    props.setLoggedIn(false);
    history.push("/");
  };

  const handleTabDropdown = (tab) => {
    setTabDropdown({ [tab]: !TabDropdown[tab] });
  };

  return (
    <>
      <div className="header_new">
        <img
          onClick={() => {
            setOpenMobBar(false);
            history.push("/");
            setSelectedNavbarTab("");
          }}
          src={UnispadeBlack}
          alt="unispade_logo"
          className="unispade_logo"
        />
        <div className="header_wrapper">
          <div className="header_links">
            {props.loggedIn && (
              <>
                <Link
                  to={props.dashboardRoute}
                  className={
                    props.selectedNavbarTab === "dashboard" ? "bold" : ""
                  }
                  onClick={() => setSelectedNavbarTab("dashboard")}
                >
                  Dashboard
                </Link>
                <Link
                  to="/pools"
                  className={props.selectedNavbarTab === "pool" ? "bold" : ""}
                  onClick={() => setSelectedNavbarTab("pool")}
                >
                  Pools
                </Link>
                <div className="header-tab-dropdown">
                  <Link
                    to="/mentors"
                    className={
                      props.selectedNavbarTab === "mentor" ? "bold" : ""
                    }
                    onClick={() => setSelectedNavbarTab("mentor")}
                    style={{
                      marginRight: "0px",
                    }}
                  >
                    Mentors
                  </Link>
                  <div onClick={() => handleTabDropdown("Mentor")}>
                    {TabDropdown["Mentor"] ? (
                      <MdExpandLess />
                    ) : (
                      <MdExpandMore />
                    )}
                  </div>

                  {TabDropdown["Mentor"] && (
                    <div className="navbar-tab-dropdown-menu">
                      <Link
                        to="/mentor/products"
                        className={
                          props.selectedNavbarTab === "mentor-product"
                            ? "bold"
                            : ""
                        }
                        onClick={() => setSelectedNavbarTab("mentor-product")}
                      >
                        Product Mentor
                      </Link>
                    </div>
                  )}
                </div>
              </>
            )}
            {/* <Link to="/search">Agencies</Link> */}
            {!props.loggedIn && (
              <>
                <Link
                  to="/pools"
                  className={props.selectedNavbarTab === "pool" ? "bold" : ""}
                  onClick={() => setSelectedNavbarTab("pool")}
                >
                  Pools
                </Link>
                <div className="header-tab-dropdown">
                  <Link
                    to="/mentors"
                    className={
                      props.selectedNavbarTab === "mentor" ? "bold" : ""
                    }
                    onClick={() => setSelectedNavbarTab("mentor")}
                    style={{
                      marginRight: "0px",
                    }}
                  >
                    Mentors
                  </Link>
                  <div onClick={() => handleTabDropdown("Mentor")}>
                    {TabDropdown["Mentor"] ? (
                      <MdExpandLess />
                    ) : (
                      <MdExpandMore />
                    )}
                  </div>

                  {TabDropdown["Mentor"] && (
                    <div className="navbar-tab-dropdown-menu">
                      <Link
                        to="/mentor/products"
                        className={
                          props.selectedNavbarTab === "mentor-product"
                            ? "bold"
                            : ""
                        }
                        onClick={() => setSelectedNavbarTab("mentor-product")}
                      >
                        Product Mentor
                      </Link>
                    </div>
                  )}
                </div>
                <Link
                  to="/user-login"
                  className={props.selectedNavbarTab === "login" ? "bold" : ""}
                  onClick={() => setSelectedNavbarTab("login")}
                >
                  Login
                </Link>
                <Link
                  to="/user-registration"
                  className={
                    props.selectedNavbarTab === "register" ? "bold" : ""
                  }
                  onClick={() => setSelectedNavbarTab("register")}
                >
                  Register
                </Link>
              </>
            )}
            {props.loggedIn && (
              <span
                className="logout"
                onClick={() => {
                  logoutHandler();
                }}
              >
                Logout
              </span>
            )}
          </div>
          {/* <div className="profile_img_wrapper">
                        <img className="profile_img" src={avatar} alt="profile_img" />
                    </div> */}
        </div>
      </div>
      <div className="header_new_mobile">
        <img
          onClick={() => {
            setOpenMobBar(false);
            history.push("/");
          }}
          src={UnispadeBlack}
          alt="unispade_logo"
          className="unispade_logo"
        />
        <div className="header_wrapper">
          <div className="profile_img_wrapper">
            <img className="profile_img" src={avatar} alt="profile_img" />
          </div>
          <div
            className="navbar__burger"
            onClick={() => setOpenMobBar((prev) => setOpenMobBar(!prev))}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>
        </div>
        {openMobBar && (
          <div className="header_new_mob_dropdown header_links">
            {/* <Link to="">Marketers</Link> */}
            <Link to="/search">Agencies</Link>
            {!props.loggedIn && (
              <>
                <Link to="/user-login">Login</Link>
                <Link to="/user-registration">Register</Link>
              </>
            )}
            {props.loggedIn && (
              <span
                onClick={() => {
                  logoutHandler();
                }}
              >
                Logout
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderNew;
