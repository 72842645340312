import React, { useRef, useState } from 'react';
import './MediaComponent.css';
import uploadIcon from '../../../images/upload.png';
import Delete from '../../../images/delete.png';
import AWS from 'aws-sdk';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const config = {
    bucketName: "unispade-react-js-s3",
    dirName: "clientMedia" /* optional */,
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
};

const bucket = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION
});

const ImageComponent = (props) => {
    const [selectedLayout, setSelectedLayout] = useState("landscape100");
    const [uploadedMedia, setUploadedMedia] = useState([]);
    const [selectedMedia, setSelectedMedia] = useState([]);
    const [uploadOption, setUploadOption] = useState('');
    const [croppedImage, setCroppedImage] = useState('');
    let imageData = {};

    const uploadButtonHandler = (field) => {
        let elem = document.getElementById('imageInput');
        if (elem) elem.click();
    }

    const fileInputOnchange = async (e) => {
        let isCoverImage = false;
        const files = e.target.files;
        let reader;
        let file;
        let uploadedMediaCopy = [...uploadedMedia];
        let newFiles = [];

        for (let i = 0; i < files.length; i++) {
            let obj = {
                thumbnail: '',
                name: '',
            }
            file = files[i];
            reader = new FileReader();
            reader.onload = (function (file) {
                return function (e) {
                    obj.thumbnail = e.target.result;
                    obj.name = file.name;
                    newFiles.push(obj);
                };
            })(file);
            reader.readAsDataURL(file);
        }
        if (reader) {
            reader.onloadend = (() => {
                setUploadedMedia([...uploadedMediaCopy, ...newFiles]);
            })
        } else if (!reader) {
            setUploadedMedia([...uploadedMediaCopy, ...newFiles]);
        }

        setTimeout(() => {
            for (let i = 0; i < files.length; i++) {
                var progressDiv = document.getElementById("imgProgress-" + i);
                progressDiv.style.display = "block";
                var progressBar = document.getElementById("imgBar-" + i);
                let file = files[i];
                let folderName = "clientMedia/";
                let fileUpload = {
                    id: "",
                    name: file.name,
                    nameUpload: file.name,
                    size: file.size,
                    type: "",
                    timeReference: 'Unknown',
                    progressStatus: 0,
                    displayName: file.name,
                    status: 'Uploading..',
                }
                uploadfile(file, folderName, isCoverImage, isCoverImage)
                    .on('httpUploadProgress', function (progress) {
                        let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                        progressBar.style.width = progressPercentage + "%";
                        if (progressPercentage < 100) {
                            fileUpload.progressStatus = progressPercentage;

                        } else if (progressPercentage == 100) {
                            fileUpload.progressStatus = progressPercentage;
                            fileUpload.status = "Uploaded";
                        }
                    })
            }
        }, 1000)
    }

    const uploadfile = (file, folderName, isCoverImage) => {
        const uniqueKey = new Date().getTime();
        const fileName = uniqueKey + '-' + file.name;
        const params = {
            Bucket: "unispade-react-js-s3",
            Key: folderName + fileName,
            Body: file,
            ContentType: file.type
        };
        return bucket.upload(params, function (err, data) {
            if (err) {
                console.log('There was an error uploading your file: ', err);
                return false;
            }
            saveImage(data.Location);
            imageData = { ...data, name: fileName };
            return true;
        });
    }

    const deletefile = (fileName, folderName) => {
        const params = {
            Bucket: "unispade-react-js-s3",
            Key: folderName + fileName,
        };
        return bucket.deleteObject(params, function (err, data) {
            if (err) {
                console.log('There was an error uploading your file: ', err);
                return false;
            }
            console.log('Successfully uploaded file.', data);
            // setSelectedMedia([...selectedMedia, data.Location]);
            return true;
        });
    }

    function saveImage(url) {
        let caseStudyFormCopy = { ...props.caseStudyForm };
        caseStudyFormCopy.coverImg = url;
        props.setCaseStudyFormData(caseStudyFormCopy);
    }

    function deleteImage(url) {
        let folderName = "clientMedia/";
        const fileName = url.split('/').pop();
        deletefile(fileName, folderName);
        let caseStudyFormCopy = { ...props.caseStudyForm };
        caseStudyFormCopy.coverImg = '';
        props.setCaseStudyFormData(caseStudyFormCopy);
    }

    const deleteImageHandler = async (img) => {
        let folderName = "clientMedia/";
        deletefile(img.name, folderName);
        imageData = {};
    }

    const onUploadSelection = (option) => {
        if (option !== uploadOption) {
            setUploadOption(option);
            setSelectedMedia([]);
            setUploadedMedia([]);
            let caseStudyFormCopy = { ...props.caseStudyForm };
            caseStudyFormCopy[props.name] = '';
            props.setCaseStudyFormData(caseStudyFormCopy);
        }
    }

    return <>
        <div className='media-component'>
            <div style={{ width: "100%", display: "flex", gap: "10rem", justifyContent: "space-between", height: "145px" }} className='image-selection-wrapper'>
                <div style={{ width: "40%" }} className='upload-wrapper'>
                    <div style={{ width: "100%" }} className={`upload ${uploadOption === 'upload' || uploadOption === '' ? 'enable' : 'disable'} coverImg`}
                        onClick={() => onUploadSelection('upload')}>
                        <img src={uploadIcon} className="upload-icon" />
                        <input type="file" hidden id="imageInput" onChange={fileInputOnchange} multiple="multiple" accept=".jpeg,.png,.gif,.jpg" />
                        <button className='upload-btn' onClick={uploadButtonHandler}>Upload</button>
                        <span className='formats'>.jpeg | .png | .gif | .jpg</span>
                    </div>
                </div>
                <div className='upload-list-wrapper'>
                    <div>
                        {
                            !imageData?.Location && props.caseStudyForm.coverImg ?
                                <div className='upload-list-item'>
                                    {
                                        <img src={props.caseStudyForm.coverImg} id="coverImgMedia" height="200" alt="Image preview..." className='upload-item-img' />
                                    }
                                    <div className="upload-image-details">
                                        <div className='upload-item-name'>
                                            <span>{props.caseStudyForm.coverImg.split('/').pop()}</span>
                                            <img src={Delete} onClick={() => deleteImage(props.caseStudyForm.coverImg)} />
                                        </div>
                                    </div>
                                </div>
                                : ''
                        }
                        <div className='upload-list-item'>
                            <div className="upload-image-details">
                                {imageData?.Location ? <div>
                                    <img src={imageData?.Location || ''} id="coverImgMedia" height="200" alt="Image preview..." className='upload-item-img' />
                                    <div className='upload-item-name'>
                                        <span>{imageData?.name || ''}</span>
                                        <img src={Delete} onClick={() => deleteImageHandler(imageData)} />
                                    </div>
                                </div> : ''}
                                <div id='imgProgress-0' className="imgProgress" style={{ display: 'none' }}>
                                    <div id='imgBar-0' className="imgBar"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></div>
    </>
}

export default ImageComponent;