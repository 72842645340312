import React, { useState } from "react";
import { Link } from "react-router-dom"
import "../../styles/Login.css";
import "../../styles/AgencyRegistration.css";
import circlebig from "../../images/Ellipse_4.png" 
import circle1 from "../../images/Ellipse_3.png" 
import circle2 from "../../images/Ellipse_5.png" 
import boy2 from "../../images/boy2.png";
import dots from "../../images/dots.png";
import axios from "../../axios.js";
import { useHistory, useLocation } from "react-router-dom";

function ConfirmPassword() {
    const location = useLocation();
    const history = useHistory();
    const [message,setmessage]=useState("")
    const [alert,setalert]=useState("")
    const [submitbtn, setSubmitbtn] = useState(false);

    const [user, setUser] = useState({
        businessEmail: "",
        otp: "",
        password: "",
        confirmpassword: "",
    });

    const changeHandler = (event) => {
        const { name, value } = event.target;
        setUser((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const SubmitHandler = async (e) => {
     
        e.preventDefault();
        if(user.password === user.confirmpassword && user.password!=""){
            try {
                const response = await axios.post("/changePassword", {
                    email: location.state.email,
                    otpCode: location.state.otp,
                    password: user.password
                })
                .catch((e) => {
                    window.alert("Some Error Occured.Please try again.")
                    history.push('/forgotpassword')
                }
                )
                if(response.data && response.data==="Password changed Succesfully"){
                    setmessage("Password changed Successfully.Please Login to continue")
                    history.push('/login')
                } else {
                    window.alert("Some Error Occured. Please Try Again")
                    history.push('/forgotpassword')

                }
            } catch (err) {
                console.log(err);
            }

        } else {
            setalert("Password and Confirm Password are not Same")
        }

        
        // loading icon starts
        setSubmitbtn(false);
        // loading icon ends
    };

    return (
        <div className="login__cont_new">
            <div className="login">
                <div className="sec__left_new">
                    <div className="upper">
                        <h1 className="heading">Almost There!!
                        </h1>
                    </div>
                    <div className="content">
                        <div className="lower">

                            <span> Welcome back :)
                            </span><p>See you on the other side  👋
                            </p>
                            
                        </div>
                        {/* <div className="lower">

                            💸<span>On-demand creative workforce</span>
                        </div>
                        <div className="lower">

                            👀 <span>On-demand creative workforce</span>
                        </div> */}
                    </div>
                  
                </div>
                <div className="sec__right_new">
                    <h1 className="fw__600 logreg__title color__orange" style={{textAlign:"left"}}>
                        Reset Password
                    </h1>
                    <form className="login__form">
                        <div className="password_label">
                        <label >Password * </label>
                        {/* <label className="min__char">Minimum 8 characters</label> */}
                        </div>
                        <input
                            onChange={changeHandler}
                            type="password"
                            name="password"
                            placeholder="Enter New Password"
                            required
                            className="epass"
                        />

                        <label>Confirm Password *</label>

                        <input
                            onChange={changeHandler}
                            type="password"
                            name="confirmpassword"
                            placeholder="Re-enter Your Password"
                            required
                            className="epass"
                        />


                        <div className="log__for">
                            {/* <label>
                                *You will be contacted from our team within 24 hrs for profile
                                activation.
                            </label> */}
                        </div>
                       <p className="color__orange">{alert}</p>
                            <button type="submit" className="login__btn" onClick={SubmitHandler} >
                                {/* <Link to='/login' className="color__white"> */}
                                    Submit
                                {/* {submitbtn && (
                                    <i className="fas fa-circle-notch donebtnclient"></i>
                                )}
                                {submitbtn && " Registering"}
                                {!submitbtn && "Register"} */}
                                {/* </Link> */}
                            </button>
                            <p className="color__orange">{message}</p>
                            
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ConfirmPassword;
