import React from "react";
import arrowdownp from "../../images/arrowdownp.png";
import { useHistory } from "react-router-dom";




function ProjectCard({ project }) {
  const history = useHistory();

  const viewProject = (event) => {
    history.push({
      pathname: '/projectbrief',
      state: { id: project._id }
    });
  }

  return (
    <div className="project__card">
      <div className="part1">
        {project.type !== "" && <button className="para requestpr">{project.type}</button>}
        <h4>{project.projectName}</h4>
        <div className="tags">
          <p className="tag para">{project.services.join(", ")}</p>
        </div>
      </div>

      <div className="part23__cont">
        <button className="viewproject" onClick={viewProject}>View Project</button>

        <div className="part3">
          <p className="planned color__orange">{project.projectStatus}</p>
          <img src={arrowdownp} className="arrowdownp" alt="" />
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
