import React, { useEffect, useState } from "react";
import "../../styles/SapFilters.css";
import searchgrey from "../../images/searchgrey.png";
import redcross from "../../images/redcross.png";
import axios from "../../axios";
import { useHistory } from "react-router-dom";
// import Select from "react-dropdown-select";
import options from "./options.js";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import {
  StateDropdown,
  RegionDropdown,
} from "react-indian-state-region-selector";
import cities from "./cities";

function SapFilters() {
  //////////// Switch tab functionality starts//////////
  var temp = [];
  // statement of work swtichtab
  const switchTabs = (e) => {
    const tabItem = e.target;

    // For btn activation
    const alltablinks = document.querySelectorAll(".sapfilter__type");

    alltablinks.forEach((link) => {
      link.classList.remove("active"); //deactivating rest of the links
    });

    tabItem.classList.add("active"); //activating the link which is clicked

    //for component activation
    const destid = e.target.getAttribute("dest"); //getting the destination id which needs to be activated.
    const tabelement = document.getElementById(destid); //element which we need to activate

    const allstatedatas = document.querySelectorAll(".sapfilter__data");

    allstatedatas.forEach((item) => {
      item.classList.remove("activetab");
    });

    tabelement.classList.add("activetab"); //activating the link which is clicked
  };

  //////////// Switch tab functionality ends//////////
  const [industries, setIndustries] = useState();
  const [services, setServices] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const res = await axios.get("/superAdmin/filters/getAllServices");
        setSelectedServices(res.data.selectedServices);
        const serviceOptions = [];
        res.data.names.forEach((k) => {
          serviceOptions.push({ label: k, value: k, key: k });
        });
        setServices(serviceOptions);
      } catch (e) {
        console.log(e);
      }
    };
    const fetchIndustries = async () => {
      try {
        const res = await axios.get("/superAdmin/filters/getAllIndustries");
        setSelectedIndustries(res.data.selectedIndustries);
        const industryOptions = [];
        res.data.names.forEach((k) => {
          industryOptions.push({ label: k, value: k, key: k });
        });
        setIndustries(industryOptions);
      } catch (e) {
        console.log(e);
      }
    };
    const fetchLocations = async () => {
      try {
        const res = await axios.get("/superAdmin/filters/getAllLocations");
        setSelectedLocations(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    fetchServices();
    fetchIndustries();
    fetchLocations();
  }, []);
  const [newService, setNewService] = useState("");
  const newServiceChangeHandler = (e) => {
    const { value } = e.target;
    setNewService(value);
  };
  const newServiceSubmitHandler = async () => {
    try {
      if (newService === "") return;
      const res = await axios.post("/superAdmin/filters/addService", {
        newService: newService,
      });
      console.log(res.data);
      setSelectedServices(res.data.selectedServices);
      setServices((p) => [...p, newService]);
      setNewService("");
    } catch (e) {
      console.log(e);
    }
  };
  const [newIndustry, setNewIndustry] = useState("");
  const newIndustryChangeHandler = (e) => {
    const { value } = e.target;
    setNewIndustry(value);
  };
  const newIndustrySubmitHandler = async () => {
    try {
      if (newIndustry === "") return;
      const res = await axios.post("/superAdmin/filters/addIndustry", {
        newIndustry: newIndustry,
      });
      setSelectedIndustries(res.data.selectedIndustries);
      setIndustries((p) => [...p, newIndustry]);
      setNewIndustry("");
    } catch (e) {
      console.log(e);
    }
  };
  const [newLocation, setNewLocation] = useState("");
  const newLocationChangeHandler = (e) => {
    const { value } = e.target;
    setNewLocation(value);
  };
  const newLocationSubmitHandler = async () => {
    try {
      if (newLocation === "") return;
      const res = await axios.post("/superAdmin/filters/addLocation", {
        newLocation: newLocation,
      });
      setSelectedLocations(res.data.selectedLocations);

      setNewLocation("");
    } catch (e) {
      console.log(e);
    }
  };

  const removeIndustryHandler = async (ind) => {
    try {
      const res = await axios.post("/superAdmin/filters/removeIndustry", {
        industry: selectedIndustries[ind],
      });
      setSelectedIndustries(res.data.selectedIndustries);
    } catch (e) {
      console.log(e);
    }
  };

  const serviceRemoveHandler = async (ind) => {
    try {
      const res = await axios.post("/superAdmin/filters/removeService", {
        service: selectedServices[ind],
      });
      setSelectedServices(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const locationRemoveHandler = async (ind) => {
    try {
      const res = await axios.post("/superAdmin/filters/removeLocation", {
        location: selectedLocations[ind],
      });
      setSelectedLocations(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const dropdownChangeHandler = async (e) => {
    try {
      const res = await axios.post("/superAdmin/filters/selectedIndustry", {
        industry: e.value,
      });
      console.log(res.data);
      setSelectedIndustries(res.data);
    } catch (e) {
      console.log(e);
    }
  };
  const serviceDropdownChangeHandler = async (e) => {
    try {
      const res = await axios.post("/superAdmin/filters/selectedService", {
        service: e.value,
      });
      console.log(res.data);
      setSelectedServices(res.data);
    } catch (e) {
      console.log(e);
    }
  };
  const cityChangeHandler = async (e) => {
    try {
      const res = await axios.post("/superAdmin/filters/selectedLocation", {
        Location: e.value,
      });
      setSelectedLocations(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="sapfilters">
      <div className="sapfilter__types">
        <div
          className="sapfilter__type active"
          onClick={switchTabs}
          dest="industrylist"
        >
          Industry List
        </div>
        <div
          className="sapfilter__type"
          onClick={switchTabs}
          dest="servicelist"
        >
          Service List
        </div>
        <div
          className="sapfilter__type"
          onClick={switchTabs}
          dest="locationlist"
        >
          Location List
        </div>
      </div>
      <div className="sapfilter__datas">
        <div className="sapfilter__data activetab" id="industrylist">
          <p className="text__left">Industry list</p>
          {/*<form className="sapfilter__data__inpcont">
            <img className="search__icon" src={searchgrey} alt="" />
            <input className="search__input" placeholder="Search" />
          </form>*/}
          <Select
            options={industries}
            isSearchable
            onChange={dropdownChangeHandler}
          />

          <div className="tags">
            {selectedIndustries?.map((i, ind) => (
              <div className="tag">
                {i}
                <img
                  onClick={() => removeIndustryHandler(ind)}
                  className="redcross"
                  src={redcross}
                  alt=""
                />
              </div>
            ))}
          </div>
          <div className="dflex justify__between">
            <p className="para color__orange cursor__pointer">Show more</p>
          </div>
          <div className="sapfilter__data__addcont">
            <input
              value={newIndustry}
              onChange={newIndustryChangeHandler}
              className="add__input"
              placeholder="Enter new Industry"
            />
            <button onClick={newIndustrySubmitHandler} className="add__btn">
              Add
            </button>
          </div>
        </div>
        <div className="sapfilter__data" id="servicelist">
          <p className="text__left">Service list</p>
          <Select
            options={services}
            isSearchable
            onChange={serviceDropdownChangeHandler}
          />
          <div className="tags">
            {selectedServices?.map((s, i) => (
              <div className="tag">
                {s}
                <img
                  onClick={() => {
                    serviceRemoveHandler(i);
                  }}
                  className="redcross"
                  src={redcross}
                  alt=""
                />
              </div>
            ))}
          </div>
          <div className="dflex justify__between">
            <p className="para color__orange cursor__pointer">Show more</p>
          </div>
          <div className="sapfilter__data__addcont">
            <input
              value={newService}
              onChange={newServiceChangeHandler}
              className="add__input"
              placeholder="Enter new Service"
            />
            <button onClick={newServiceSubmitHandler} className="add__btn">
              Add
            </button>
          </div>
        </div>
        <div className="sapfilter__data" id="locationlist">
          <p className="text__left">Location list</p>
          <Select options={cities} isSearchable onChange={cityChangeHandler} />
          <div className="tags">
            {selectedLocations?.map((l, i) => (
              <div className="tag">
                {l}
                <img
                  onClick={() => locationRemoveHandler(i)}
                  className="redcross"
                  src={redcross}
                  alt=""
                />
              </div>
            ))}
          </div>
          <div className="dflex justify__between">
            <p className="para color__orange cursor__pointer">Show more</p>
          </div>
          <div className="sapfilter__data__addcont">
            <input
              value={newLocation}
              onChange={newLocationChangeHandler}
              className="add__input"
              placeholder="Enter new Location"
            />
            <button onClick={newLocationSubmitHandler} className="add__btn">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SapFilters;
