import React, { useEffect } from "react";
import { useState } from "react";
import deleteicon from "../img/delete.png";
import { Link } from "react-router-dom";
// import CaseStudyFilter from "../filterdata/filter"
// import FilterDropDown from "../../FiterDropDown/FilterDropDown";


const Credits = ({ caseStudies, rejectCasestudy }) => {
  const [caseStudy, setCasestudy] = useState([])

  useEffect(() => {
    setCasestudy(caseStudies)
  }, [caseStudies])



  // const Filter = [   
  //   {
  //     name: "Search by Brands",
  //     array: ["Unispade", "onePlus",""],
  //   },
  //   {
  //     name: "Search by Roles",
  //     array: ["Devloper", "tester", "marketer", "CTO"],   
  //   },
  //   {
  //     name: "Search by Agencies",
  //     array: ["Uber", "ola", "Oneplus"],
  //   },
  // ];

  const rejectCaseStudy = (casStudyId, index) => {
    rejectCasestudy(casStudyId)
    const caseStudyCopy = [...caseStudy]
    caseStudyCopy.splice(index, 1)
    setCasestudy(caseStudyCopy)
  }

  return (
    <div className="MarketersCredits">
      {/* <p>Filters</p>
          <div className="marketers_casestudy_Filter">
            {Filter.map((key) => (   
              <div className="marketersFilterCont">
                <FilterDropDown
                  placeholder={key.name}
                  dropdownOptions={key.array}
                  setDropDownValues={setDropDownValues}
                />
              </div> 
            ))}
          </div> */}
      <div className="searchResultsCr">
        <p>{caseStudy && caseStudy.length}  search results</p>
      </div>
      <div className="MarketersCasestudies">
        {(caseStudy && caseStudy.length > 0)
          ? (caseStudy.map((key, index) => (
            <div className="casestudy">
              <div className="head">
                <p className="brandname">{key.brand && key.brand}</p>
                <img
                  className="deleteIcon"
                  onClick={() => rejectCaseStudy(key.creditId, index)}
                  src={deleteicon && deleteicon}
                  alt=""
                />
              </div>
              <img
                className="coverImage"
                src={key.coverImg && key.coverImg}
              />
              <div className="body">
                <Link className="heading" to={"/case-study/" + key.uniqueKey}>
                  {"Case Study >"}
                </Link>
                <p className="description">
                  {key.projectTitle && key.projectTitle}
                </p>
                <h5 className="agencyname">
                  by {key.agencyName && key.agencyName}
                </h5>
              </div>
              <p className="role">{key.role && key.role}</p>
            </div>
          )))
          : (
            <p className="emptyCreditsPara">
              You have not been credited for any project yet.<br />
              Invite your team and <span className="link">create a case study</span> to get credited
            </p>
          )
        }
      </div>
    </div>
  );
}

export default Credits