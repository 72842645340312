import React, { useState } from "react";
import "../../styles/Login.css";
import boy2 from "../../images/boy2.png";
import dots from "../../images/dots.png";
import axios from "../../axios.js";
import { useHistory } from "react-router-dom";

function BrandRegister() {
  const [submitbtn, setSubmitbtn] = useState(false);
  const history = useHistory();
  const [newBrand, setNewBrand] = useState({
    name: "",
    brandName: "",
    phoneNumber: "",
    businessEmail: "",
    brandWebsite: "",
    password: ""
  });

  const changeHandler = event => {
    const { name, value } = event.target;
    setNewBrand(prev => {
      return {
        ...prev,
        [name]: value
      };
    });
  };

  const submitHandler = async e => {
    // loading icon starts
    setSubmitbtn(true);
    // loading icon ends

    e.preventDefault();
    const saveBrand = newBrand;
    try {
      const response = await axios.post("/brands/register", saveBrand);
      // console.log(response);
      history.push("/brandlogin");
    } catch (err) {
      console.log(err);
    }
    // loading icon starts
    setSubmitbtn(false);
    // loading icon ends
  };

  return (
    <div className="login__cont">
      <div className="login">
        <div className="sec__left" style={{display: "none"}}>
          <img src={boy2} alt="" className="girlimg" />
          <img src={dots} alt="" className="dots" />
        </div>
        <div className="sec__right agencyRegSec">
          <h1 className="fw__600 logreg__title">
            Brand <span className="color__black">Registration</span>
          </h1>
          <form className="login__form" onSubmit={submitHandler}>
            <label>Name</label>
            <input
              onChange={changeHandler}
              name="name"
              value={newBrand.name}
              placeholder="Enter your name here"
              className="epass"
              required
            />
            <label>Brand Name</label>
            <input
              onChange={changeHandler}
              name="brandName"
              value={newBrand.brandName}
              placeholder="Enter your agency name here"
              required
              className="epass"
            />
            <label>Phone number</label>
            <input
              onChange={changeHandler}
              name="phoneNumber"
              value={newBrand.phoneNumber}
              type="number"
              placeholder="Enter your number"
              required
              className="epass"
            />
            <label>Business E-mail</label>
            <input
              onChange={changeHandler}
              name="businessEmail"
              type="email"
              value={newBrand.businessEmail}
              placeholder="Enter your work email id"
              required
              className="epass"
            />
            <label>Brand Website</label>
            <input
              onChange={changeHandler}
              name="brandWebsite"
              placeholder="Enter Website URL"
              value={newBrand.brandWebsite}
              className="epass"
              required
            />
            <label>Password</label>
            <input
              onChange={changeHandler}
              name="password"
              value={newBrand.password}
              type="password"
              placeholder="Generate your password"
              required
              className="epass"
            />
            <div className="log__for">
              <label>
                *You will be contacted from our team within 24 hrs for profile
                activation.
              </label>
            </div>
            <button type="submit" className="login__btn">
              {submitbtn && (
                <i className="fas fa-circle-notch donebtnclient"></i>
              )}
              {submitbtn && " Registering"}
              {!submitbtn && "Register"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default BrandRegister;
