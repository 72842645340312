import React, { useEffect, useState, useRef } from "react";
import "../../styles/SapAgencies.css";
import searchgrey from "../../images/searchgrey.png";
import blogworks from "../../images/blogworks.png";
import { Link, useHistory } from "react-router-dom";
import axios from "../../axios";
import Select from "react-select";
import cities from "./cities";
import {Helmet} from "react-helmet";
import { Editor } from '@tinymce/tinymce-react';


function SapService() {
  const history = useHistory();

  const [services, setServices] = useState([]);
  const [locations, setLocations] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [pageTitle, setpageTitle] = useState(null);
  const [Sendservice, setSendServices] = useState(null);
  const [save, setSave] = useState(false);
  const [content, setContent] = useState("")

  // Edit New Page

  // const addedit = () => {
  //   history.push("/sap/service/addedit");
  // };

  // page Load then hit event

  // Edit Mode Open
  // On edit Mode change
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
    servicevalue: true,
    cityvalue: true,
    content: "",
  });
  const [inCopyMode, setInCopyMode] = useState({
    status: false,
    rowKey: null,
  });
  useEffect(async () => {
    fetchAllServices();
    const fetchLocations = async () => {
      try {
        const res = await axios.get("/superAdmin/filters/getAllLocations");
        setSelectedLocations(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    const fetchServices = async () => {
      try {
        const res = await axios.get("/superAdmin/filters/getAllServices");
        setSelectedServices(res.data.selectedServices);
        const serviceOptions = [];
        res.data.names.forEach((k) => {
          serviceOptions.push({ label: k, value: k, key: k });
        });
        setServices(serviceOptions);
      } catch (e) {
        console.log(e);
      }
    };
    fetchAllServices();
    fetchServices();
    fetchLocations();
  }, [save]);

  const fetchAllServices = async () => {
    try {
      const res = await axios.get("/superAdmin/servicePage/getAll");
      setAllServices(res.data);
      setpageTitle(res.data.pageTitle);
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };

  const onEdit = ({ id, pageTitle, service, locations, content }) => {
    // console.log('check row key',id )

    setInEditMode({
      status: true,
      rowKey: id,
      servicevalue: true,
      cityvalue: true,
      content: content
    });
    setpageTitle(pageTitle);
    setSendServices(service);
    setLocations(locations);
    setContent(content);
  };

  // cancel changes

  const onCancel = () => {
    setInEditMode({
      status: false,
      rowKey: null,
      servicevalue: true,
      cityvalue: true,
      content: "",
    });
  };

  // Service Dropdown

  const serviceDropdownChangeHandler = async (e) => {
    try {
      const res = await axios.post("/superAdmin/filters/selectedServiceSap", {
        service: e.value,
      });

      setSelectedServices(res.data);
      setSendServices(e.value);
    } catch (e) {
      console.log(e);
    }
  };

  // City Dropdown

  const cityChangeHandler = async (e) => {
    try {
      const res = await axios.post("/superAdmin/filters/selectedLocationSap", {
        Location: e.value,
      });
      setLocations(e.value);
      console.log(locations);
    } catch (e) {
      console.log(e);
    }
  };
  
  //Add New Page
  const addNewPage = () => {
    const position=document.body.scrollHeight
    window.scrollTo({
      left:0,
      top:position
    });
    setAllServices((prev) => {
      const l = prev.length;

      var newAr = [];
      prev.forEach((i, ind) => {
        newAr.push({
          _id: l - ind,
          pageTitle: i.pageTitle,
          service: i.service,
          location: i.location,
          content: i.content,
        });
      });
      setInEditMode({
        status: true,
        rowKey: allServices.length,
        servicevalue: false,
        cityvalue: false,
        content: "",
      });
      newAr.push({
        servicePageId: l + 1,
        pageTitle: (
          <input
            className="add__input"
            style={{ height: 37 }}
            onChange={(event) => setpageTitle(event.target.value)}
            value={pageTitle}
          />
        ),
        service: (
          <Select
            options={services}
            isSearchable
            onChange={(e) => {
              serviceDropdownChangeHandler(e);
            }}
          />
        ),
        location: (
          <Select
            options={cities}
            isSearchable
            onChange={(e) => {
              cityChangeHandler(e);
            }}
          />
        ),
      });

      return newAr;
    });
  };

  // Update Data

  const onSave = async (e) => {
    if (e.id === undefined) {
      const addService = {
        pageTitle: e.pageTitle,
        service: e.services,
        location: e.locations && e.locations.length ? e.locations : "-",
        content: e.content
      };
      try {
        const response = await axios.post(
          "/superAdmin/servicePage/add",
          addService
        );
        //window.location.reload();
        fetchAllServices();
        setInEditMode({
          status: false,
          rowKey: null,
          servicevalue: true,
          cityvalue: true,
          content: "",
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      const updateService = {
        servicePageId: e.id,
        pageTitle: e.pageTitle,
        service: e.services,
        location: e.locations && e.locations.length ? e.locations : "-",
        content: e.content,
      };
      try {
        const response = await axios.post(
          "/superAdmin/servicePage/edit",
          updateService
        );

        //window.location.reload();
        fetchAllServices();
        setInEditMode({
          status: false,
          rowKey: null,
          servicevalue: true,
          cityvalue: true,
          content: "",
        });
      } catch (err) {
        console.log(err);
      }
    }
    setSave(false);
  };
  //copying url to clipboard
  const copyToClipboard = (e, id, service) => {
    let str =service.service
    let res=str.replace(/\s/g, '_')
    navigator.clipboard.writeText(
      `http://unispade.com/${res}-agencies-in-${service.location}`
    );
    // setCopySuccess("Copied");
    console.log(service.location);
  };

  const editorRef = useRef(null);
   const log = () => {
     if (editorRef.current) {
       console.log(editorRef.current.getContent());
     }
   };

  return (
    
    <div className="sapagencies">
       {/* <Helmet>
         <meta charset="UTF-8" name="description" content="Service Description"></meta>
       </Helmet> */}
      <div className="sapagencies__top">
        <div className="sapagencies__dataselect__cont">
          <form className="sapagencies__data__inpcont">
            <img className="search__icon" src={searchgrey} alt="" />
            <input className="search__input" placeholder="Search" />
          </form>
          <div className="dflex justify__between">
            
            <p
              className="para color__orange cursor__pointer"
              onClick={() => {
                addNewPage();
              }}
            >
              + Add New Page
            </p>
            
          </div>
        </div>
        <select className="sap__select">
          <option defaultValue="Sort">Sort</option>
          <option>Popular</option>
          <option>Popular</option>
        </select>
      </div>

      <div className="saptable__lists casestudy sapservicestable">
        <table className="pricing__table">
          <tbody className="">
            <tr className="table__heading__row ">
              <th className="heading__row__part">
                <p className="theading ">Page Title</p>
              </th>
              <th className="heading__row__part">
                <p className="theading ">Service</p>
              </th>
              <th className="heading__row__part">
                <p className="theading ">Location</p>
              </th>
              <th className="heading__row__part">
                <p className="theading "></p>
              </th>
            </tr>

            {allServices.map((a, i) => (
              <React.Fragment className="saptablerow" style={{width: "100%"}}>
              <tr key={i} className="text__center" >
                <td className="para adgrey image__name__cont pad2row textleftfirr">
                  {inEditMode.status && inEditMode.rowKey === i ? (
                    <input
                    id="abc"
                      className="add__input"
                      style={{ height: 37 }}
                      value={pageTitle}
                      onChange={(event) => setpageTitle(event.target.value)}
                    />
                  ) : (
                    a.pageTitle
                  )}
                </td>
                <td className="para adgrey pad2row">
                  {inEditMode.status && inEditMode.rowKey === i ? (
                    <Select
                      options={services}
                      isSearchable
                      onChange={(e) => {
                        serviceDropdownChangeHandler(e);
                        setInEditMode({
                          status: true,
                          rowKey: i,
                          servicevalue: true,
                          cityvalue: false,
                        });
                      }}
                      value={
                        inEditMode.servicevalue
                          ? services.filter(
                              (option) => option.label === Sendservice
                            )
                          : null
                      }
                    />
                  ) : (
                    a.service
                  )}
                </td>

                <td className="para adgrey pad2row">
                  {inEditMode.status && inEditMode.rowKey === i ? (
                    <Select
                    className= "css-menu"
                      options={cities}
                      isSearchable
                      onChange={(e) => {
                        cityChangeHandler(e);
                        setInEditMode({
                          status: true,
                          rowKey: i,
                          value: true,
                          servicevalue: true,
                          cityvalue: true,
                        });
                      }}
                      value={
                        inEditMode.cityvalue
                          ? cities.filter(
                              (option) => option.label === locations
                            )
                          : null
                      }
                    />
                  ) : (
                    a.location
                  )}
                </td>
                <td className="para adgrey pad2row">
                  <div className="dflex align__center justify__center">
                    {/* <p
                    className="para color__orange cursor__pointer"
                    onClick={addedit}
                  >
                    Edit
                  </p> */}

                    <td>
                      {inEditMode.status && inEditMode.rowKey === i ? (
                        <React.Fragment>
                          <button
                            className="para color__orange cursor__pointer "
                            onClick={() => {
                              setSave(true);
                              onSave({
                                id: a._id,
                                pageTitle: pageTitle,
                                services: Sendservice,
                                locations: locations,
                                content: content
                              });
                            }}
                            style={{ background: "#f8f8f8" }}
                          >
                            Save
                          </button>
                          <button
                            className="para color__orange cursor__pointer "
                            style={{ marginLeft: 8, background: "#f8f8f8" }}
                            onClick={() => onCancel()}
                          >
                            Cancel
                          </button>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <button
                            className="para color__orange cursor__pointer "
                            onClick={() =>
                              onEdit({
                                id: i,
                                pageTitle: a.pageTitle,
                                service: a.service,
                                locations: a.location,
                                content: a.content
                              })
                            }
                            style={{ background: "#f8f8f8" }}
                          >
                            Edit
                          </button>
                          {inCopyMode.status && inCopyMode.rowKey === i ? (
                            <button
                              className="para color__orange cursor__pointer mx-4"
                              onClick={(e) => {
                                setInCopyMode({
                                  status: false,
                                  rowKey: null,
                                });
                              }}
                              style={{ background: "#f8f8f8" }}
                            >
                              "Copied"
                            </button>
                          ) : (
                            <button
                              className="para color__orange cursor__pointer mx-4"
                              onClick={(e) => {
                                copyToClipboard(
                                  e,
                                  i,

                                  allServices[i]
                                );
                                setInCopyMode({
                                  status: true,
                                  rowKey: i,
                                });
                              }}
                              style={{ background: "#f8f8f8" }}
                            >
                              {/* <i class="fa fa-clipboard" aria-hidden="true"></i> */}
                              <div className="vector1"></div>
                              <div className="vector2"></div>
                            </button>
                          )}
                        </React.Fragment>
                      )}
                    </td>
                  </div>
                </td>
              </tr>
              <tr key={i}  className="text__center" >
              <td colSpan={3} >
              {inEditMode.status && inEditMode.rowKey === i ? (
                <Editor
                  apiKey="tj55rrirq2vmb4xn34qzdaq4enr7sc1ltzvg6w4talwguw0y"
                  onInit={(evt, editor) => editorRef.current = editor}
                  value={content}
                  onEditorChange={(newValue, editor) => {setContent(newValue); console.log(content)}}
                  init={{
                    height: 200,
                    menubar: false,
                    
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help | fullscreen',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                  }}
                /> )  : ""  }
                </td>
              </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
   
  );
}

export default SapService;
