import React, { useState } from "react";
import { Link } from "react-router-dom"
import "../../styles/Login.css";
import "../../styles/AgencyRegistration.css";
import circlebig from "../../images/Ellipse_4.png"
import circle1 from "../../images/Ellipse_3.png"
import circle2 from "../../images/Ellipse_5.png"
import boy2 from "../../images/boy2.png";
import dots from "../../images/dots.png";
import axios from "../../axios.js";
import { useHistory } from "react-router-dom";
import emailjs from "emailjs-com"
import { BorderTop } from "@material-ui/icons";

function ForgotPassword() {
    const history = useHistory();
    const [message,setmessage]=useState("")
    const[validotpmessage,setvalidotpmessage]=useState("")
    const [submitbtn, setSubmitbtn] = useState(false);

    const SubmitHandler= async (e)=>{
        e.preventDefault();
        console.log("Submit OTP")

        try {
            const response = await axios.post('/getOtpbyMail',{
                email:user.businessEmail
            });
            if (response.data.code == user.otp){
                console.log("Correct OTP")
                history.push({
                    pathname: '/confirmpassword',
                    state: { email: user.businessEmail,
                            otp: user.otp }
                  })
            } else {
                setvalidotpmessage("Invalid OTP. Please Try Again")
            }
        } catch (err) {
            console.log(err);
        }
    }
    const OtpEnterHandler = async () => {

        try {
            const response = await axios.post('/emailSend',{
                email:user.businessEmail
            });
            
            if (response.data.message == "Please Check your Email") {
                console.log(response.data.message)
                setSubmitbtn(true);
            } else if (response.data.message == "Email id Does not exist"){
                setmessage(response.data.message)
            }
            
        } catch (err) {
            console.log(err);
        }

    }
    const [user, setUser] = useState({
        businessEmail: "",
        otp: "",
        password: "",
    });
    
    
    const changeHandler = (event) => {
        const { name, value } = event.target;
        setUser((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };


    return (
        <div className="login__cont_new">
            <div className="login">
                <div className="sec__left_new">
                    <div className="upper">
                        <h1 className="heading">Happens to the best of us!!
                        </h1>
                    </div>
                    <div className="content">
                        <div className="lower">

                            <span>We’ve got you covered</span>

                        </div>

                    </div>

                </div>
                {!submitbtn ?
                    <div className="sec__right_new">
                        <h1 className="fw__600 logreg__title color__orange">
                            Forgot Password
                        </h1>
                        <div className="login__form"
                        //  onSubmit={SubmitHandler}
                        >
                            
                            <label>Business E-mail</label>
                            <input
                                onChange={changeHandler}
                                name="businessEmail"

                                placeholder="Enter  your E-mail"
                                className="epass"
                                type="text"
                                required
                            />



                            <div className="log__for">
                                <label>
                                    OTP will be sent to your mail ID
                                </label>
                            </div>
                            {console.log(message,"sjsjshdshkd")}
                            <p className="color__orange">{message}</p>
                            <button className="login__btn" onClick={()=>OtpEnterHandler()}>

                                Send OTP

                            </button>

                        </div>
                    </div>
                    : 
                    <div className="sec__right_new">
                        <h1 className="fw__600 logreg__title color__orange">
                            Forgot Password
                        </h1>
                        <form className="login__form">
                            <label>Business E-mail</label>
                            <input
                                onChange={changeHandler}
                                name="businessEmail"
                                value = {user.businessEmail}
                                placeholder="Enter your E-mail"
                                className="epass"
                                required
                            />



                            <div className="log__for">
                                <label>
                                    OTP will be sent to your mail ID
                                </label>
                            </div>

                            <button type="submit" className="login__btn">

                                OTP Sent!

                            </button>

                        </form>
                        <form className="login__form" id="forgotpassword">

                            <input
                                onChange={changeHandler}
                                name="otp"
                             
                                placeholder="Enter OTP"
                                className="epass"
                                required
                                style={{ marginTop: "30px" }} />
<p className="color__orange">{validotpmessage}</p>
                            <button type="submit" className="login__btn" onClick={SubmitHandler} >
                                {/* <Link to="/confirmpassword" className="color__white" style={{ textDecoration: 'none' }}> */}
                                    Submit OTP
                                {/* </Link> */}
                            </button>
                        </form>
                    </div>}
            </div>
        </div>
    );
}

export default ForgotPassword;
