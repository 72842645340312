import React from "react";
import notificationg from "../../images/notificationg.png";
import dotg from "../../images/dotg.png";
import messageg from "../../images/messageg.png";
import ASidebarAlt from "./ASidebarAlt";

function ADashboardComp() {
  return (
    <div className="adashboard">
      <ASidebarAlt />
      <div className="section">
        <div className="part__left">
          <h3 className="adashboard__title">Dashboard</h3>
        </div>
        <div className="part__right">
          <div className="img__cont">
            <img className="msg__not" src={messageg} alt="" />
            <img className="dotg" src={dotg} alt="" />
          </div>
          <div className="img__cont">
            <img className="msg__not" src={notificationg} alt="" />
            <img className="dotg" src={dotg} alt="" />
          </div>
          <p className="date__day para adgrey"> </p>
        </div>
      </div>
      <div className="section__desc">
        <div className="desc__card">
          <p className="para adgrey text__left">Profile Views</p>
          <h1 className="color__orange text__center desc__card__text">50</h1>
        </div>
        <div className="desc__card">
          <p className="para adgrey text__left">New Business</p>
          <h1 className="color__orange text__center desc__card__text">73</h1>
        </div>
        <div className="desc__card">
          <p className="para adgrey text__left">Profile Views</p>
          <h1 className="color__orange text__center desc__card__text">22</h1>
        </div>
      </div>
      <div className="section2">
        <p className="para adgrey text__left">
          Red Agency is an Alpha Partner, Upgrade to become an Elite Partner
        </p>
        <p className=" para color__orange cursor__pointer ">
          Upgrade. It’s Free!
        </p>
      </div>
      <div className="section2">
        <p className="para adgrey text__left">
          Project Brief Collection Form | {"<url>"}
        </p>
        <p className="para color__orange cursor__pointer">Copy Link!</p>
      </div>
    </div>
  );
}

export default ADashboardComp;
