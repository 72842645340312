import React from "react";
import "../../styles/SapContent.css";
import searchgrey from "../../images/searchgrey.png";

function SapContent() {
  const opentabdata = e => {
    const itemc = e.target;

    const allarrow = document.querySelectorAll(".rotate90");
    allarrow.forEach(item => {
      if (itemc !== item) {
        item.classList.remove("rotate270"); //deactivating rest of the datas
      }
    });
    itemc.classList.toggle("rotate270");

    const addData =
      e.target.parentElement.parentElement.parentElement.nextElementSibling
        .children[0].children[0];

    const adddatacomp = document.querySelectorAll(".additioncont");

    adddatacomp.forEach(item => {
      if (addData !== item) {
        item.classList.remove("showadditioncontr"); //deactivating rest of the datas
      }
    });
    addData.classList.toggle("showadditioncontr"); //activating which is clicked
  };

  return (
    <div className="sapagencies sapcontent">
      <div className="sapagencies__top">
        <form className="sapagencies__data__inpcont">
          <img className="search__icon" src={searchgrey} alt="" />
          <input className="search__input" placeholder="Search" />
        </form>
        <div className="filtersort__cont">
          <select className="sap__select">
            <option defaultValue="Sort">Filter</option>
            <option>Popular</option>
            <option>Popular</option>
          </select>
          <select className="sap__select">
            <option defaultValue="Sort">Sort</option>
            <option>Popular</option>
            <option>Popular</option>
          </select>
        </div>
      </div>
      <div className="saptable__lists casestudy sapservicestable">
        <table className="pricing__table">
          <tbody className="">
            <tr className="table__heading__row ">
              <th className="heading__row__part">
                <p className="theading "></p>
              </th>
              <th className="heading__row__part">
                <p className="theading ">Category</p>
              </th>
              <th className="heading__row__part">
                <p className="theading ">Word Count</p>
              </th>
              <th className="heading__row__part">
                <p className="theading ">Stage</p>
              </th>
              <th className="heading__row__part">
                <p className="theading ">Status</p>
              </th>
              <th className="heading__row__part">
                <p className="theading "></p>
              </th>
            </tr>
            <tr className="text__center saptablerow">
              <td className="para adgrey image__name__cont pad2row textleftfirr">
                5784591
              </td>
              <td className="para adgrey pad2row">Text</td>
              <td className="para adgrey pad2row">1500</td>
              <td className="para adgrey pad2row">In Progress - Editing</td>
              <td className="para adgrey pad2row">
                <div className="dflex align__center justify__center">
                  <p className="para color__orange cursor__pointer">
                    View Brief
                  </p>
                </div>
              </td>
              <td className="para adgrey pad2row">
                <div className="dflex align__center justify__center">
                  <p
                    className="para color__orange cursor__pointer rotate90"
                    onClick={opentabdata}
                  >
                    >
                  </p>
                </div>
              </td>
            </tr>
            <tr className="additioncontr">
              <td className="additioncontd " colSpan="6">
                <div className="additioncont">
                  <p className="para fw__600 adgrey">
                    “The title of the content will be displayed like this”
                  </p>
                  <div className="basedata">
                    <div className="part">
                      <p className="para adgrey">Brand</p>
                      <p className="para adgrey fw__600">Mahindra</p>
                    </div>
                    <div className="part">
                      <p className="para adgrey">Mail ID</p>
                      <p className="para adgrey fw__600">anshul@mahindra.com</p>
                    </div>
                    <div className="part">
                      <p className="para adgrey">Contact Number</p>
                      <p className="para adgrey fw__600">+91-9824291969</p>
                    </div>
                  </div>
                  <div className="basedata">
                    <div className="part">
                      <p className="para adgrey">Creator Skill</p>
                      <p className="para adgrey fw__600">Advanced</p>
                    </div>
                    <div className="part">
                      <p className="para adgrey">Assigned Creator</p>
                      <p className="para adgrey fw__600">Priyansh Makwana</p>
                    </div>
                    <div className="part">
                      <p className="para adgrey">Rivisions Left</p>
                      <p className="para adgrey fw__600">2</p>
                    </div>
                  </div>
                  <div className="basedata">
                    <div className="part">
                      <p className="para adgrey">Estimate</p>
                      <p className="para adgrey fw__600">₹2200</p>
                    </div>
                  </div>
                  <div className="tagscont">
                    <p className="para adgrey">Focus Keywords</p>
                    <div className="tags">
                      <div className="tag">Keyword</div>
                      <div className="tag">Keyword</div>
                      <div className="tag">Keyword</div>
                      <div className="tag">Keyword</div>
                      <div className="tag">Keyword</div>
                    </div>
                  </div>
                  <div className="assigndata">
                    <p className="para adgrey fw__600">
                      Assignment Brief & Additional Comments
                    </p>
                    <p className="para adgrey">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Laoreet sagittis nullam mi justo, neque elementum ultrices
                      nibh. Aliquam ullamcorper facilisi vestibulum, ut nunc
                      fames nulla rhoncus. Tortor ultrices vitae tristique
                      tincidunt vel, nulla bibendum. At eu, ut aliquam diam quis
                      cursus hac pharetra. Quam in elementum tincidunt porttitor
                      magna tellus est. Velit mi sed magna metus adipiscing
                      pellentesque donec ut. Sagittis erat pellentesque mus vel.
                    </p>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="text__center saptablerow">
              <td className="para adgrey image__name__cont pad2row textleftfirr">
                5784592
              </td>
              <td className="para adgrey pad2row">Text</td>
              <td className="para adgrey pad2row">1500</td>
              <td className="para adgrey pad2row">In Progress - Editing</td>
              <td className="para adgrey pad2row">
                <div className="dflex align__center justify__center">
                  <p className="para color__orange cursor__pointer">
                    View Brief
                  </p>
                </div>
              </td>
              <td className="para adgrey pad2row">
                <div className="dflex align__center justify__center">
                  <p
                    className="para color__orange cursor__pointer rotate90"
                    onClick={opentabdata}
                  >
                    >
                  </p>
                </div>
              </td>
            </tr>
            <tr className="additioncontr">
              <td className="additioncontd " colSpan="6">
                <div className="additioncont">
                  <p className="para fw__600 adgrey">
                    “The title of the content will be displayed like this”
                  </p>
                  <div className="basedata">
                    <div className="part">
                      <p className="para adgrey">Brand</p>
                      <p className="para adgrey fw__600">Mahindra</p>
                    </div>
                    <div className="part">
                      <p className="para adgrey">Mail ID</p>
                      <p className="para adgrey fw__600">anshul@mahindra.com</p>
                    </div>
                    <div className="part">
                      <p className="para adgrey">Contact Number</p>
                      <p className="para adgrey fw__600">+91-9824291969</p>
                    </div>
                  </div>
                  <div className="basedata">
                    <div className="part">
                      <p className="para adgrey">Creator Skill</p>
                      <p className="para adgrey fw__600">Advanced</p>
                    </div>
                    <div className="part">
                      <p className="para adgrey">Assigned Creator</p>
                      <p className="para adgrey fw__600">Priyansh Makwana</p>
                    </div>
                    <div className="part">
                      <p className="para adgrey">Rivisions Left</p>
                      <p className="para adgrey fw__600">2</p>
                    </div>
                  </div>
                  <div className="basedata">
                    <div className="part">
                      <p className="para adgrey">Estimate</p>
                      <p className="para adgrey fw__600">₹2200</p>
                    </div>
                  </div>
                  <div className="tagscont">
                    <p className="para adgrey">Focus Keywords</p>
                    <div className="tags">
                      <div className="tag">Keyword</div>
                      <div className="tag">Keyword</div>
                      <div className="tag">Keyword</div>
                      <div className="tag">Keyword</div>
                      <div className="tag">Keyword</div>
                    </div>
                  </div>
                  <div className="assigndata">
                    <p className="para adgrey fw__600">
                      Assignment Brief & Additional Comments
                    </p>
                    <p className="para adgrey">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Laoreet sagittis nullam mi justo, neque elementum ultrices
                      nibh. Aliquam ullamcorper facilisi vestibulum, ut nunc
                      fames nulla rhoncus. Tortor ultrices vitae tristique
                      tincidunt vel, nulla bibendum. At eu, ut aliquam diam quis
                      cursus hac pharetra. Quam in elementum tincidunt porttitor
                      magna tellus est. Velit mi sed magna metus adipiscing
                      pellentesque donec ut. Sagittis erat pellentesque mus vel.
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SapContent;
