import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import "./TalentPoolUserConsent.css";
import axios from "../../../../axios";
import { IsoTwoTone } from "@material-ui/icons";
import { toast } from "react-toastify";

export const TalentPoolUserConsent = () => {
  const location = useLocation();
  const history = useHistory();

  const [isInterested, setIsInterested] = useState(null);
  const [userData, setUserData] = useState(null);

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  const toast_style = {
    position: "top-right",
    autoClose: 4000,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    width: "10rem",
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const user_data = JSON.parse(decodeURIComponent(params.get("data")));
    const interested = params.get("interested") === "true";
    setIsInterested(interested);
    setUserData(user_data);
  }, []);





  const sendInterest = async () => {
    try{
        if (isInterested && userData) {
            const { data } = await axios.post(
                `/talentPool/talent_pool_user_consent`,
                userData,
                { headers: { authorization: "Bearer " + Marketer.token } }
            );
            
            if(!data?.success) {
                toast.error("Already added to pool!", toast_style)
            }
            history.push("/user-dashboard");
  
          }
    }
    catch(error) {
        console.log(error)
    }
  }

  return (
    <div className="consent-container">
      {isInterested === null ? (
        <p>Processing your consent...</p>
      ) : isInterested ? (
        <>
        <div className="message interested">
          <h2>Thank you for your interest!</h2>
          <p>
            We are excited to have you on board. You will receive further
            instructions via email soon.
          </p>
        </div>
        <button
        className="home-button"
        onClick={sendInterest}
      >
        Click here to continue
      </button>
        </>
        
      ) : (
        <>
        <div className="message not-interested">
          <h2>Thank you for your response!</h2>
          <p>
            We understand that you are not interested at the moment. If you
            change your mind, feel free to reach out to us anytime.
          </p>
        </div>
        <button
        className="home-button"
        onClick={() => {
          history.push("/user-dashboard");
        }}
      >
        Click here to continue
      </button>
        </>
      )}

    </div>
  );
};

export default TalentPoolUserConsent;
