import React, { useEffect, useState } from "react";
import "../../styles/Campaign.css";
import featuredimg from "../../images/featuredimg.png";
import Views from "../../images/Views.png";
import study1 from "../../images/study1.png";
import study2 from "../../images/study2.png";
import study3 from "../../images/study3.png";
import FavoriteIcon from "@material-ui/icons/Favorite";
import CampaignCard from "./CampaignCard";
import { Link, useHistory } from "react-router-dom";
import axios from "../../axios.js";

function Campaigns() {
  const history = useHistory();
  const [path, setPath] = useState();
  const [studies, setStudies] = useState();
  useEffect(() => {
    const fetchCaseStudies = async () => {
      try {
        const response = await axios.get("/agencyOnboarding/getAllCaseStudies");
        setStudies(response.data);
        // console.log(response.data);
        const path = "/portfoliodetail/" + response.data[1]._id + "/0";
        await setPath(path);
      } catch (e) {
        console.log(e);
      }
    };
    fetchCaseStudies();
  }, []);
  return (
    <div className="camp__cont">
      <div className="camp__top__cont">
        <div className="camp__top">
          <h1 className="color__orange">Campaigns</h1>

          <p className="para color__white">
            You can refine filters to find the best agency for your business
            needs.
          </p>
        </div>
      </div>
      <div className="camp">
        {path && (
          <div className="camp__feature">
            
          </div>
        )}
        <div className="camp__img__conts">
          {studies &&
            studies.map((study) => {
              return study.studies.map((s, index) => {
                return (
                  <CampaignCard
                    key={s._id}
                    id={study._id}
                    index={index}
                    client={s.client}
                    image={s.media[0]}
                    title={s.projectTitle}
                  />
                );
              });
            })}
         
        </div>
        <div className="show__more__btn mart2 marb1">
          <button className="reg__btn">Show more</button>
        </div>
      </div>
      {/* Search agencies */}
      <div className="mar5__cont">
        <div className="searchagencies__cont ">
          <div className="searchagencies">
            <p className="mwidthsmall">
              Increase the chances of your project success, partner with the{" "}
              <span className="color__orange">right agency</span>
            </p>
            <div>
              <button onClick={() => history.push("/search")}>
                Search Agencies
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Campaigns;
