import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import arrowIcon from "../img/arrow_back_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import calendar from "../img/calendar_month_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import time from "../img/schedule_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import PaymentPage from "./paymentPage";
import PaymentDone from "./paymentDone";
import axios from "../../../../../axios.js";
import mobileArrow from "../img/chevron_left_40dp_FILL0_wght400_GRAD0_opsz40.svg";

import "./slotselect.css";
import { ContactSupportOutlined } from "@material-ui/icons";

import Loader from "../../../../loader/Loader.js";

const useIsMounted = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};

function SlotSelect({ sessionDetails, setIsBooking }) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [requestBody, setRequestBody] = useState({});

  const [maxBookingDays, setMaxBookingDays] = useState(null);
  const [noticePeriod, setNoticePeriod] = useState(null);
  const [availableDays, setAvailableDays] = useState([]);

  const Marketer = JSON.parse(localStorage.getItem("marketer"));
  const isMounted = useIsMounted();

  // Function to format date as per requirement
  const formatDate = (date) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-IN", options);
  };

  console.log("The session details are ", sessionDetails);

  // Function to handle time slot selection
  const handleTimeSlotSelect = (slotInfo) => {
    setSelectedTimeSlot(`${slotInfo.startTime} - ${slotInfo.endTime}`);
    setRequestBody((prevData) => ({
      ...prevData,
      date: selectedDate,
      startTime: slotInfo.startTime,
      endTime: slotInfo.endTime,
      duration: slotInfo.duration,
      session_id: sessionDetails?._id,
      mentor_id: sessionDetails?.mentor_id?._id,
      user_id: Marketer.user._id,
    }));
  };

  const handleNextButtonClick = () => {
    setShowPaymentPage(true);
  };

  const setNewDate = (date) => {
    console.log("The date is ", date);
    setSelectedDate(date);
  };

  useEffect(() => {
    (async () => {
      if (selectedDate) {
        console.log("The shit inside use effect is ", selectedDate);
        const { data } = await axios.get(
          `/mentoring/get-availability-by-date/${selectedDate}/${sessionDetails?.duration}/${sessionDetails?.mentor_id?._id}`,
          {
            headers: {
              authorization: "Bearer " + Marketer.token,
            },
          }
        );

        console.log("The data is ", data);
        if (data) {
          setTimeSlots(data);
        }
      }
    })();
  }, [selectedDate]);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `/mentoring/get-general-availability/${sessionDetails?.mentor_id?._id}`,
        {
          headers: {
            authorization: "Bearer " + Marketer.token,
          },
        }
      );
      const the_days = data?.map((element) => element?.week_day);

      console.log("The days ", the_days);
      if (isMounted.current) {
        setAvailableDays(the_days);
      }
    })();
  }, []); 

  useEffect(() => {
    (async () => {
      const conditionData = await axios.get(
        `/mentoring/get-availability-conditions/${sessionDetails?.mentor_id?._id}`,
        {
          headers: {
            authorization: "Bearer " + Marketer.token,
          },
        }
      );

      if (isMounted.current) {
        setMaxBookingDays(conditionData?.data?.maximum_booking_period);
        setNoticePeriod(conditionData?.data?.minimum_notice_period);
        setSelectedDate(selectedDate);
      }
    })();
  }, []);

  const getDayName = (date) => {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  };

  const getDayClassName = (date) => {
    const dayName = getDayName(date);
    if (!availableDays.includes(dayName)) {
      return "sunday-holiday";
    }
    return "";
  };

  const filterDates = (date) => {
    const dayName = getDayName(date);
    return !availableDays.includes(dayName);
  };

  const maxSelectableDate = new Date();
  maxSelectableDate.setDate(maxSelectableDate.getDate() + maxBookingDays);

  if (showPaymentPage) {
    return (
      <div>
        <PaymentPage
          selectedDate={selectedDate}
          selectedTimeSlot={selectedTimeSlot}
          requestBody={requestBody}
          setRequestBody={setRequestBody}
          sessionDetails={sessionDetails}
          setShowPaymentPage={setShowPaymentPage}
        />
      </div>
    );
  }

  console.log("length of time slots is ", timeSlots)

  return (
    <>
      {timeSlots  ? (
        <div className="userSlotSingleSession">
          <div className="sessionHeader">
            <div className="sessionHeader-title">
              <button
                className="backButton"
                onClick={() => setIsBooking(false)}
              >
                <img src={arrowIcon} alt="left arrow" />
              </button>
              <h1 className="sessionHeader-title heading">
                {sessionDetails?.title}
              </h1>
              <div className="mobile-icon">
                <img src={mobileArrow} alt="mobile arrow" />
              </div>
            </div>
            <div className="duration-slot">
              <div className="duration-item">
                <span>
                  <img src={calendar} alt="calendar" />{" "}
                  {formatDate(selectedDate)}
                </span>
              </div>
                {selectedTimeSlot &&                 <span>
              <div className="duration-item">
                  <img src={time} alt="time" />{" "}
                  {selectedTimeSlot}

              </div>
                </span> }
            </div>
          </div>

          <div className="details-section">
            <div className="details-section-container">
              <div className="reschedule-section">
                <div className="calendar-container">
                  <p>Select day</p>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setNewDate(date)}
                    dateFormat="dd/MM/yyyy"
                    inline
                    dayClassName={getDayClassName}
                    filterDates={filterDates}
                    minDate={new Date()} // Set the start date to today
                    maxDate={maxSelectableDate} // Set the end date to 2 weeks from today
                  />
                </div>

                <div className="time-slot-container">
                  <p>Select time slots</p>
                  <div className="time-selector">
                    {timeSlots?.map((element, index) => (
                      <div
                        key={index}
                        className={`time-slot ${
                          selectedTimeSlot ===
                          `${element.startTime} - ${element.endTime}`
                            ? "active"
                            : ""
                        }`}
                        onClick={() => handleTimeSlotSelect(element)}
                      >
                        <p>{element?.startTime}</p>
                        <span> - </span>
                        <p>{element?.endTime}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="duration-footer">
              <div className="duration-item-footer">
                <span style={{ fontWeight: "400" }}>
                  <img src={calendar} alt="calendar" />{" "}
                  {formatDate(selectedDate)}
                </span>
              </div>
              <div className="duration-item-footer">
                <span style={{ fontWeight: "400" }}>
                  <img src={time} alt="time" />{" "}
                  {selectedTimeSlot ? selectedTimeSlot : "Select a time slot"}
                </span>
              </div>
            </div>

            <div className="sessionFooter">
              <div className="price">
                <span>&#8377; {sessionDetails?.price}</span>
              </div>
              <button className="bookButton" onClick={handleNextButtonClick}>
                Next
              </button>
            </div>
          </div>
        </div>
      ) : (
        <Loader></Loader>
      )}
    </>
  );
}

export default SlotSelect;
