import React from "react";
import "../../styles/AOnBoarding.css";
import notificationg from "../../images/notificationg.png";
import dotg from "../../images/dotg.png";
import messageg from "../../images/messageg.png";
import ASidebarAlt from "./ASidebarAlt";
import authHeader from "../../login-auth.js";
import { useState, useEffect } from "react";
import axios from "../../axios.js";
import { useHistory } from "react-router-dom";
import RatingStars from "../RatingStars";
import { uploadImage } from "../../common/service";

const config = {
  bucketName: "unispade-react-js-s3",
  dirName: "clientLogos" /* optional */,
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
};
const configMedia = {
  bucketName: "unispade-react-js-s3",
  dirName: "caseStudyMedia" /* optional */,
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
};
const configAward = {
  bucketName: "unispade-react-js-s3",
  dirName: "awardsLogo" /* optional */,
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
};
const configNews = {
  bucketName: "unispade-react-js-s3",
  dirName: "newsLogo" /* optional */,
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
};

function AOnboarding() {
  const history = useHistory();
  const [clientloadbtn, setClientloadbtn] = useState(false);
  const [caseloadbtn, setCaseloadbtn] = useState(false);
  const [uploadbtn, setUploadbtn] = useState(false);

  const info = authHeader();
  var loginToken = info.Token;
  var loginUser = info.User;
  const [yearOptions, setYearOptions] = useState([]);
  useEffect(() => {
    var currentYear = new Date().getFullYear();
    var years = [];
    var startYear = 1900;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    setYearOptions(years);
  }, []);

  const [agencyLocations, setAgencyLocations] = useState([]);
  const [agencyServices, setAgencyServices] = useState([]);
  const [temp10, setTemp10] = useState(1);
  const [basicAgencyDetails, setBasicAgencyDetails] = useState({
    agencyName: "",
    foundedYear: "",
    agencySize: 0,
    agencyWebsite: "",
    locations: ["", "", ""],
    aboutAgency: "",
  });
  const [clientsSaved, setClientsSaved] = useState(false);
  const [caseStudiesSaved, setCaseStudiesSaved] = useState(false);
  const [brands, setBrands] = useState([]);
  const [services, setServices] = useState([""]);
  const [checkServices, setCheckServices] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);

  //brandname array
  const [brandname, setBrandname] = useState(["rayban"]);

  //brandname
  const [brand, setBrand] = useState();

  const [serviceBoxes, setServiceBoxes] = useState([
    { no: 1, name: 0, check: false },
    { no: 2, name: 1, check: false },
    { no: 3, name: 2, check: false },
  ]);
  const [contactBoxes, setContactBoxes] = useState([]);
  const [newsBoxes, setNewsBoxes] = useState([]);
  const [awardBoxes, setAwardBoxes] = useState([]);
  const [canSelect, setCanSelect] = useState(true);
  const [locationBoxes, setLocationBoxes] = useState([1, 2]);
  const [clientBoxes, setClientBoxes] = useState([]);
  const [statisticsBoxes, setStatisticsBoxes] = useState([]);
  const [caseStudies, setCaseStudies] = useState([
    {
      client: "",
      projectTitle: "",
      services: [],
      industry: "",
      overview: "",
      goals: "",
      challenges: "",
      ideaAndSolution: "",
      results: "",
      statistics: [
        {
          reach: "",
          stats1: "",
          revenue: "",
          stats2: "",
        },
      ],
      media: [],
      team: [
        {
          name: "",
          position: "",
          mailId: "",
          isAdmin: false,
        },
        {
          name: "",
          position: "",
          mailId: "",
          isAdmin: false,
        },
        {
          name: "",
          position: "",
          mailId: "",
          isAdmin: false,
        },
      ],
    },
  ]);
  const [caseStudyBoxes, setCaseStudyBoxes] = useState([]);
  const [mediaBoxes, setMediaBoxes] = useState([
    { no: 1, name: 0 },
    { no: 2, name: 1 },
    { no: 3, name: 2 },
    { no: 4, name: 3 },
  ]);
  const [teamBoxes, setTeamBoxes] = useState([
    { no: 1, name: 0 },
    { no: 2, name: 1 },
  ]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [serviceCounter, setServiceCounter] = useState(0);
  const [statisticsCounter, setStatisticsCounter] = useState(0);
  const [newsCounter, setNewsCounter] = useState(0);
  const [awardsCounter, setAwardsCounter] = useState(0);
  const [locationCounter, setLocationCounter] = useState(0);
  const [flag, setFlag] = useState(0);
  const [contactCounter, setContactCounter] = useState(0);
  const [clientCounter, setClientCounter] = useState(0);
  const [caseStudyCounter, setCaseStudyCounter] = useState(0);
  const [mediaCounter, setMediaCounter] = useState(0);
  const [teamCounter, setTeamCounter] = useState(0);
  const [progress, setProgress] = useState(0);
  const [contactDetails, setContactDetails] = useState([
    {
      officeLocation: "",
      contactPerson: "",
      mailId: "",
      mobile: "",
    },
  ]);
  const [clientDetails, setClientDetails] = useState([
    {
      clientName: "",
      clientService: "",
      clientURL: "",
      clientLogo: {},
      clientReview: "",
    },
  ]);
  const [awardDetails, setAwardDetails] = useState([
    {
      awardName: "",
      awardLink: "",
      awardLogo: "",
    },
  ]);

  const [newsMentions, setNewsMentions] = useState([
    {
      newsHeading: "",
      newsURL: "",
      activate: true,
      image: "",
    },
  ]);

  const [fillServices, setFillServices] = useState(true);
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    var temp = [...new Set(basicAgencyDetails.locations)];
    temp = temp.filter((l) => l !== "");
    setAgencyLocations(temp);
  }, [
    basicAgencyDetails.locations,
    locationCounter,
    basicAgencyDetails,
    locationBoxes,
    temp10,
  ]);

  useEffect(() => {
    var temp = [...new Set(services)];
    temp = temp.filter((l) => l !== "");
    setAgencyServices(temp);
  }, [services, serviceCounter, serviceBoxes, temp10]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const res = await axios.get("/brands/register", {
          headers: { authorization: "Bearer " + authHeader().Token },
        });
        setBrands(res.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchBrands();
  }, []);

  useEffect(() => {
    var count = 0;
    if (basicAgencyDetails.agencyName != "") {
      count += 7;
    }
    if (basicAgencyDetails.foundedYear != "") {
      count += 6;
    }
    if (basicAgencyDetails.agencySize != "") {
      count += 7;
    }
    if (basicAgencyDetails.agencyWebsite != "") {
      count += 6;
    }
    if (
      basicAgencyDetails.locations[0] !== undefined &&
      basicAgencyDetails.locations[0].length > 0
    ) {
      count += 7;
    }
    if (basicAgencyDetails.aboutAgency != "") {
      count += 6;
    }

    if (services[0] !== undefined && services[0] !== "") count += 7;

    if (
      contactDetails[0].officeLocation !== undefined &&
      contactDetails[0].officeLocation !== ""
    )
      count += 6;

    if (
      contactDetails[0].contactPerson !== undefined &&
      contactDetails[0].contactPerson !== ""
    )
      count += 7;

    if (
      contactDetails[0].mailId !== undefined &&
      contactDetails[0].mailId !== ""
    )
      count += 7;

    if (
      contactDetails[0].mobile !== undefined &&
      contactDetails[0].mobile !== ""
    )
      count += 7;

    if (
      clientDetails[0].clientName !== undefined &&
      clientDetails[0].clientName.length > 0 &&
      clientDetails[0].clientName !== "" &&
      clientDetails[0].clientURL !== "" &&
      clientDetails[0].clientService !== ""
    )
      count += 6;

    if (
      caseStudies[0] !== undefined &&
      caseStudies[0].client !== undefined &&
      caseStudies[0].client !== ""
      // caseStudies[0].projectTitle !== "" &&
      // caseStudies[0].services[0] !== "" &&
      // caseStudies[0].industry !== "" &&
      // caseStudies[0].overview !== "" &&
      // caseStudies[0].goals !== "" &&
      // caseStudies[0].challenges !== "" &&
      // caseStudies[0].ideaAndSolution !== "" &&
      // caseStudies[0].results !== "" &&
      // caseStudies[0].statistics[0].reach !== "" &&
      // caseStudies[0].statistics[0].stats1 !== "" &&
      // caseStudies[0].statistics[0].stats2 !== "" &&
      // caseStudies[0].statistics[0].revenue !== "" &&
      // // caseStudies[0].media[0] !== "" &&
      // caseStudies[0].team[0].name !== "" &&
      // caseStudies[0].team[0].position !== "" &&
      // caseStudies[0].team[0].mailId !== ""
    )
      count += 7;

    if (
      newsMentions[0].newsURL !== undefined &&
      newsMentions[0].newsURL != "" &&
      newsMentions[0].newsHeading !== ""
    )
      count += 6;

    if (
      awardDetails[0].awardName !== undefined &&
      awardDetails[0].awardName !== "" &&
      awardDetails[0].awardLink !== ""
    )
      count += 7;
    if (count > 95) count = 100;
    setProgress(count);
  }, [
    basicAgencyDetails,
    basicAgencyDetails.locations,
    services,
    contactDetails,
    clientDetails,
    caseStudies,
    awardDetails,
    newsMentions,
    temp10,
  ]);

  const caseStudySubmitHandler = async () => {
    // loading icon starts
    setCaseloadbtn(true);

    // loading icon ends

    setCaseStudiesSaved(true);
    var temp = caseStudies.filter((t) => t.client !== "");

    temp.forEach((t) => {
      var newstats = t.statistics.filter(
        (ts) =>
          ts.reach !== "" ||
          ts.stats1 !== "" ||
          ts.revenue != "" ||
          ts.stats2 != ""
      );
      t.statistics = newstats;
      var newteam = t.team.filter(
        (tt) => tt.name !== "" || tt.position !== "" || tt.mailId != ""
      );
      t.team = newteam;
    });

    try {
      const res = await axios.post(
        "/agencyOnboarding/caseStudy",
        { agencyId: authHeader().User.agencyId, studies: temp },
        { headers: { authorization: "Bearer " + authHeader().Token } }
      );
      console.log(res.data);
      setCaseloadbtn(false);
    } catch (e) {
      console.log(e);
    }
  };

  const contactChangeHandler = (i, event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(name);
    setContactDetails((prev) => {
      prev[i][name] = value;
      return prev;
    });
    setTemp10((p) => p + 1);

    console.log(contactDetails);
  };

  const clientChangeHandler = async (i, event) => {
    // console.log(event.target.files[0]);
    // console.log(event.target.value);
    // console.log(fileToSend);
    setTemp10((p) => p + 1);
    if (event.target.name === "clientLogo") {
      try {
        const res = await uploadImage(event.target.files[0]);
        setClientDetails((p) => {
          p[i].clientLogo = res;
          return p;
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      const name = event.target.name;
      const value = event.target.value;
      console.log(name);
      setClientDetails((prev) => {
        prev[i][name] = value;
        return prev;
      });
    }
    // console.log(fileToSend);
  };

  const newsChangeHandler = async (i, e) => {
    setTemp10((p) => p + 1);
    if (e.target.name === "newsLogo") {
      try {
        const res = await uploadImage(e.target.files[0]);
        setNewsMentions((p) => {
          p[i].image = res;
          return p;
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      const { name, value } = e.target;
      setNewsMentions((p) => {
        p[i][name] = value;
        return p;
      });
    }
    console.log(newsMentions);
  };

  const submitClientHandler = async () => {
    // loading icon starts
    setClientloadbtn(true);
    setClientsSaved(true);
    var temp = [...new Set(clientDetails)];

    const clientsToSave = clientDetails.filter((c) => {
      if (
        // c.clientLogo !== "" &&
        c.clientName !== "" &&
        c.clientURL !== "" &&
        c.clientReview !== "" &&
        c.clientService !== ""
      )
        return true;
      else return false;
    });
    console.log(clientsToSave);

    try {
      const res = await axios.post("/addClient", {
        client: clientsToSave,
        agencyId: authHeader().User.agencyId,
      });
      console.log(res.data);
      setClientloadbtn(false);
    } catch (e) {
      console.log(e);
    }
  };
  // const submitClientHandler = async () => {

  //   setClientloadbtn(true);

  //   setClientsSaved(true);
  //   var temp = [...new Set(clientDetails)];
  //   // const formData = new FormData();

  //   // Update the formData object
  //   // formData.append("myFile", fileToSend);

  //   const clientsToSave = clientDetails.filter((c) => {
  //     if (
  //       c.clientLogo !== "" &&
  //       c.clientName !== "" &&
  //       c.clientURL !== "" &&
  //       c.clientReview !== "" &&
  //       c.clientService !== ""
  //     )
  //       return true;
  //     else return false;
  //   });
  //   console.log(clientsToSave);

  //   try {
  //     const res = await axios.post("/addClient", {
  //       client: clientsToSave,
  //       agencyId: authHeader().User.agencyId,
  //     });
  //     console.log(res.data);
  //     setClientloadbtn(false);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const awardChangeHandler = async (i, event) => {
    setTemp10((p) => p + 1);

    const name = event.target.name;
    const value = event.target.value;
    if (name === "awardLogo") {
      try {
        const res = await uploadImage(event.target.files[0]);
        setAwardDetails((p) => {
          p[i].awardLogo = res;
          return p;
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      setAwardDetails((prev) => {
        prev[i][name] = value;
        return prev;
      });
    }
  };

  const addMoreContacts = () => {
    setContactDetails((p) => {
      p.push({
        officeLocation: "",
        contactPerson: "",
        mailId: "",
        mobile: 0,
      });
      return p;
    });
    setContactCounter((p) => p + 1);
  };

  useEffect(() => {
    setContactBoxes((prev) => {
      const l = prev.length;
      var newAr = [];
      prev.forEach((i, ind) => {
        newAr.push({ no: l - ind, name: i.name });
      });
      newAr.push({ no: l + 1, name: l });
      return newAr;
    });
  }, [contactCounter]);
  const addMoreAwards = () => {
    setAwardDetails((p) => {
      p.push({
        awardName: "",
        awardLink: "",
        awardLogo: "",
      });
      return p;
    });
    setAwardsCounter((p) => p + 1);
  };

  useEffect(() => {
    setAwardBoxes((prev) => {
      const l = prev.length;
      var newAr = [];
      prev.forEach((i, ind) => {
        newAr.push({ no: l - ind, name: i.name });
      });
      newAr.push({ no: l + 1, name: l });
      return newAr;
    });
  }, [awardsCounter]);

  const addMoreNews = () => {
    setNewsMentions((p) => {
      p.push({
        newsHeading: "",
        newsURL: "",
        activate: true,
        image: "",
      });
      return p;
    });
    setNewsCounter((p) => p + 1);
  };

  useEffect(() => {
    setNewsBoxes((prev) => {
      const l = prev.length;
      var newAr = [];
      prev.forEach((i, ind) => {
        newAr.push({ no: l - ind, name: i.name });
      });
      newAr.push({ no: l + 1, name: l });
      return newAr;
    });
  }, [newsCounter]);

  const addClientHandler = () => {
    setClientDetails((p) => {
      p.push({
        clientName: "",
        clientService: "",
        clientURL: "",
        clientLogo: {},
        clientReview: "",
      });
      return p;
    });
    setClientCounter((p) => p + 1);
  };

  useEffect(() => {
    setClientBoxes((prev) => {
      const l = prev.length;
      var newAr = [];
      prev.forEach((i, ind) => {
        newAr.push({ no: l - ind, name: i.name });
      });
      newAr.push({ no: l + 1, name: l });
      return newAr;
    });
  }, [clientCounter]);

  const addStatisticsHandler = (i) => {
    setCaseStudies((p) => {
      p.forEach((cs) => {
        cs.statistics.push({
          reach: "",
          stats1: "",
          revenue: "",
          stats2: "",
        });
      });
      return p;
    });
    setStatisticsCounter((p) => p + 1);
  };
  useEffect(() => {
    setStatisticsBoxes((prev) => {
      const l = prev.length;
      var newAr = [];
      prev.forEach((i, ind) => {
        newAr.push({ no: l - ind, name: i.name });
      });
      newAr.push({ no: l + 1, name: l });
      return newAr;
    });
  }, [statisticsCounter]);

  const statisticsChangeHandler = (i, j, e) => {
    const { name, value } = e.target;
    setCaseStudies((p) => {
      p[i].statistics[j][name] = value;
      return p;
    });
    console.log(caseStudies);
  };

  const caseStudyChangeHandler = (i, e) => {
    setTemp10((p) => p - 1);
    const name = e.target.name;
    const value = e.target.value;
    setCaseStudies((p) => {
      p[i][name] = value;
      return p;
    });
    console.log(caseStudies);
  };

  const addCaseStudyHandler = () => {
    setCaseStudies((p) => {
      p.push({
        client: "",
        projectTitle: "",
        services: [],
        industry: "",
        overview: "",
        goals: "",
        challenges: "",
        ideaAndSolution: "",
        results: "",
        statistics: [
          {
            reach: "",
            stats1: "",
            revenue: "",
            stats2: "",
          },
        ],
        media: [],
        team: [
          {
            name: "",
            position: "",
            mailId: "",
            isAdmin: false,
          },
          {
            name: "",
            position: "",
            mailId: "",
            isAdmin: false,
          },
          {
            name: "",
            position: "",
            mailId: "",
            isAdmin: false,
          },
        ],
      });
      return p;
    });
    setCaseStudyCounter((p) => p + 1);
  };
  useEffect(() => {
    setCaseStudyBoxes((prev) => {
      const l = prev.length;
      var newAr = [];
      prev.forEach((i, ind) => {
        newAr.push({ no: l - ind, name: i.name });
      });
      newAr.push({ no: l + 1, name: l });
      return newAr;
    });
  }, [caseStudyCounter]);

  const mediaChangeHandler = async (i, j, e) => {
    try {
      const res = await uploadImage(e.target.files[0]);
      setCaseStudies((p) => {
        p[i].media[j] = res.location;
        return p;
      });
    } catch (e) {
      console.log(e);
    }
  };

  const addMediaHandler = () => {
    setMediaCounter((p) => p + 1);
  };
  useEffect(() => {
    setMediaBoxes((prev) => {
      const l = prev.length;
      var newAr = [];
      prev.forEach((i, ind) => {
        newAr.push({ no: l - ind, name: i.name });
      });
      newAr.push({ no: l + 1, name: l });
      return newAr;
    });
  }, [mediaCounter]);
  const addTeamHandler = () => {
    setCaseStudies((p) => {
      p.forEach((cs) => {
        cs.team.push({
          name: "",
          position: "",
          mailId: "",
          isAdmin: false,
        });
      });
      return p;
    });
    setTeamCounter((p) => p + 1);
  };

  const teamChangeHandler = (i, j, e) => {
    const { name, value } = e.target;
    const t = "isAdmin";
    if (value === "Admin" || value === "General") {
      // console.log(e.target.checked);
      if (value === "Admin") {
        setCaseStudies((p) => {
          p[i].team[j][t] = true;
          return p;
        });
      } else {
        setCaseStudies((p) => {
          p[i].team[j][t] = false;
          return p;
        });
      }
    } else {
      setCaseStudies((p) => {
        p[i].team[j][name] = value;
        return p;
      });
    }
    console.log(caseStudies);
    // console.log(value, name);
  };

  useEffect(() => {
    setTeamBoxes((prev) => {
      const l = prev.length;
      var newAr = [];
      prev.forEach((i, ind) => {
        newAr.push({ no: l - ind, name: i.name });
      });
      newAr.push({ no: l + 1, name: l });
      return newAr;
    });
  }, [teamCounter]);

  const basicDetailsChangeHandler = (event) => {
    const { value, name } = event.target;

    if (!name.includes("locations")) {
      console.log("not location");
      setBasicAgencyDetails((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    } else {
      var count = 0;
      basicAgencyDetails.locations.forEach((b) => {
        if (b !== "") count++;
      });
      if (count + 1 === locationBoxes.length) setFillLocation(false);
      const ind = name.split("locations");
      setBasicAgencyDetails((prev) => {
        prev.locations[ind[1]] = value;
        return prev;
      });
    }
    setTemp10((p) => p + 1);
    console.log(basicAgencyDetails);
  };

  const addMoreService = () => {
    setFillServices(true);
    setServiceCounter((p) => p + 1);
  };

  const serviceCheck = (event) => {
    setFlag((p) => p + 1);
    const name = event.target.name;
    const value = event.target.checked;

    if (value) {
      var ct = 0;
      serviceBoxes.forEach((s) => {
        if (s.check === true) ct++;
      });
      if (ct < 3)
        setServiceBoxes((prev) => {
          prev[name].check = true;
          return prev;
        });
    } else {
      setServiceBoxes((prev) => {
        prev[name].check = false;
        return prev;
      });
    }
  };

  useEffect(() => {
    var ct = 0;
    serviceBoxes.forEach((b) => {
      if (b.check == true) ct++;
      if (ct >= 3) setCanSelect(false);
    });
    if (ct >= 3) setCanSelect(false);
    else setCanSelect(true);
  }, [serviceBoxes, flag]);

  useEffect(() => {
    setServiceBoxes((prev) => {
      const l = prev.length;
      var newAr = [];
      prev.forEach((i, ind) => {
        newAr.push({ no: l - ind, name: i.name });
      });
      newAr.push({ no: l + 1, name: l });
      return newAr;
    });
  }, [serviceCounter]);

  const [fillLocation, setFillLocation] = useState(true);
  const addMoreLocation = () => {
    setFillLocation(true);
    setLocationCounter((p) => p + 1);
  };
  useEffect(() => {
    setBasicAgencyDetails((prev) => {
      prev.locations.push("");
      return prev;
    });
    setLocationBoxes((prev) => {
      const l = prev.length;
      var newAr = [];
      prev.forEach((i, ind) => {
        newAr.push(l - ind);
      });
      newAr.push(l + 1);
      return newAr;
    });
  }, [locationCounter]);

  const serviceChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setTemp10((p) => p - 1);
    var count = 0;
    services.forEach((b) => {
      if (b !== "") count++;
    });
    if (count + 1 === serviceBoxes.length) setFillServices(false);

    setServices((prev) => {
      prev[name] = value;
      return prev;
    });
  };

  function capitalize(input) {
    if (input === null || input === undefined) return input;
    var words = input.split(" ");
    var CapitalizedWords = [];
    words.forEach((element) => {
      CapitalizedWords.push(
        element[0].toUpperCase() + element.slice(1, element.length)
      );
    });
    return CapitalizedWords.join(" ");
  }

  useEffect(() => {
    try {
      const getInfo = async () => {
        const res = await axios.post(
          "/agencyOnboarding/getAgencyById",
          {
            id: loginUser.agencyId,
          },
          { headers: { authorization: "Bearer " + authHeader().Token } }
        );
        setBasicAgencyDetails({
          agencyName: res.data.agencyName,
          foundedYear: "",
          agencySize: 0,
          agencyWebsite: res.data.agencyWebsite,
          locations: [],
          aboutAgency: "",
        });
      };

      const getLocationOptions = async () => {
        const res = await axios.get("/superAdmin/filters/getAllLocations");

        setLocationOptions(res.data);
      };

      const getAllServices = async () => {
        const res = await axios.get("/superAdmin/filters/getAllServices");
        setServiceOptions(res.data.selectedServices);
      };
      getAllServices();
      getLocationOptions();
      getInfo();
    } catch (e) {
      console.log(e);
    }
  }, []);

  //const submitClientHandler = async () => {
  // loading icon starts
  // setClientloadbtn(true);

  // // loading icon ends

  // setClientsSaved(true);
  // var temp = [...new Set(clientDetails)];

  // const clientsToSave = clientDetails.filter((c) => {
  //   if (
  //     // c.clientLogo !== "" &&
  //     c.clientName !== "" &&
  //     c.clientURL !== "" &&
  //     c.clientReview !== "" &&
  //     c.clientService !== ""
  //   )
  //     return true;
  //   else return false;
  // });
  // console.log(clientsToSave);

  // try {
  //   const res = await axios.post("/addClient", {
  //     client: clientsToSave,
  //     agencyId: authHeader().User.agencyId,
  //   });
  //   console.log(res.data);
  //   setClientloadbtn(false);
  // } catch (e) {
  //   console.log(e);
  // }

  //   setBrandname([...brandname, brand]);
  //   setBrand("");
  // };

  const submitHandler = async () => {
    // loading icon starts
    setUploadbtn(true);
    // loading icon ends

    var temp = [...new Set(basicAgencyDetails.locations)];
    temp = temp.filter((l) => l !== "");
    setBasicAgencyDetails((prev) => {
      prev.locations = temp;
      return prev;
    });

    console.log(basicAgencyDetails);
    const topServices = [];
    serviceBoxes.forEach((s, i) => {
      if (s.check) topServices.push(services[i]);
    });
    const topWithoutDouble = [...new Set(topServices)];
    const servicesWithoutDup = [...new Set(services)];
    const servicesToSave = servicesWithoutDup.filter((s) => s !== "");
    const finalServices = [];
    topWithoutDouble.forEach((d) => {
      finalServices.push(d);
    });
    servicesToSave.forEach((s) => {
      finalServices.push(s);
    });
    const servicesToSend = [...new Set(finalServices)];

    const contactsToSave = contactDetails.filter((c) => {
      if (
        c.officeLocation !== "" &&
        c.contactPerson !== "" &&
        c.mailId !== "" &&
        c.mobile !== 0
      )
        return true;
      else return false;
    });
    // console.log(contactsToSave);
    const awardsTosave = awardDetails.filter(
      (a) => a.awardName !== "" || a.awardLink !== ""
    );
    const newsTosave = newsMentions.filter(
      (a) => a.newsURL !== "" || a.newsHeading !== ""
    );

    //contactsToSave , temp , finalServices, awardsTosave, newstosave
    console.log(finalServices, temp, awardsTosave, newsTosave, contactsToSave);

    try {
      const isUpdated = await axios.post(
        "/setagencyUpdated",
        {
          agencyId: authHeader().User.agencyId,
        },
        { headers: { authorization: "Bearer " + authHeader().Token } }
      );
      console.log(isUpdated);
      const res = await axios.post(
        "/agencyOnboarding",
        {
          agencyId: authHeader().User.agencyId,
          basicAgencyDetails: basicAgencyDetails,
          services: servicesToSend,
          awards: awardsTosave,
          contactDetails: contactsToSave,
          newsMention: newsTosave,
        },
        { headers: { authorization: "Bearer " + authHeader().Token } }
      );
      history.push("/adashboard");
      setUploadbtn(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    // <div className="aonboarding__cont">
    //   <ASidebar />
    <div className="aonboarding">
      <ASidebarAlt />
      <div className="section__cont">
        <div className="section">
          <div className="part__left">
            <h3 className="aonboarding__title">Onboarding</h3>
          </div>
          <div className="part__right">
            <div className="img__cont">
              <img className="msg__not" src={messageg} alt="" />
              <img className="dotg" src={dotg} alt="" />
            </div>
            <div className="img__cont">
              <img className="msg__not" src={notificationg} alt="" />
              <img className="dotg" src={dotg} alt="" />
            </div>
            <p className="date__day para adgrey">
              {" "}
              {new Date().getDate()}-{months[new Date().getMonth()]}-
              {new Date().getFullYear()} | {days[new Date().getDay()]}
            </p>
          </div>
        </div>
        <div className="section__progress__cont">
          <div className="part1">
            <p className="para fw__600">
              Profile Score &nbsp;{" "}
              <span className="adgrey">{progress}/100</span>{" "}
            </p>
            <p className=" para color__orange cursor__pointer fw__600">Save</p>
          </div>
          <div className="part2">
            <div
              className="progress__bar"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      </div>
      <div className="basdetails">
        <p className="para text__left fw__600">Basic Agency Details</p>
        <div className="basdetails__data">
          <p className="para adgrey">Select the Head office location</p>
          <div className="basdetails__data__forms">
            <form className="form">
              <div className="inp__fields flxl">
                <label className="para">Agency Name</label>
                <input
                  onChange={basicDetailsChangeHandler}
                  name="agencyName"
                  className="input "
                  placeholder="Agency name"
                  type="text"
                  value={basicAgencyDetails.agencyName}
                ></input>
              </div>
              <div className="inp__fields flxs">
                <label className="para">Year Established</label>
                <select
                  name="foundedYear"
                  className="select "
                  onChange={basicDetailsChangeHandler}
                >
                  <option value="">Select a year</option>
                  {yearOptions.map((y) => (
                    <option value={y}>{y}</option>
                  ))}
                </select>
              </div>
              <div className="inp__fields flxs">
                <label className="para">Employees</label>
                <select
                  name="agencySize"
                  onChange={basicDetailsChangeHandler}
                  className="input adgrey"
                  value={basicAgencyDetails.agencySize}
                >
                  <option value="">Select A Size</option>
                  <option value="0-10">0-10</option>
                  <option value="11-50">11-50</option>
                  <option value="51-100">51-100</option>
                  <option value="above 100">above 100</option>
                </select>
              </div>
              <div className="inp__fields flxl">
                <label className="para">Website</label>
                <input
                  name="agencyWebsite"
                  onChange={basicDetailsChangeHandler}
                  className="input "
                  placeholder="https://www.redagency.com"
                  value={basicAgencyDetails.agencyWebsite}
                ></input>
              </div>
            </form>
            <form className="form">
              {locationBoxes.map((item, index) => (
                <div className="inp__fields flxs">
                  <label className=" labelf para">
                    {" "}
                    Office Location {index + 1}
                  </label>
                  <select
                    className="select "
                    onChange={basicDetailsChangeHandler}
                    name={"locations" + index}
                  >
                    <option value="">Select a Location</option>
                    {locationOptions.map((loc) => (
                      <option value={loc}>{loc}</option>
                    ))}
                  </select>
                </div>
              ))}
              <div className="inp__fields flxs">
                <label className="para">Logo</label>
                <div className="logo__conts">
                  <input
                    name="clientLogo"
                    type="file"
                    id="logo"
                    onChange={basicDetailsChangeHandler}
                  ></input>
                  {/*<p className="para adgrey">500 X 500 px Square dimension</p>*/}
                </div>
              </div>
            </form>
            <div className="dflex justify__between">
              {!fillLocation && (
                <p
                  className="para color__orange fw__600 cursor__pointer"
                  onClick={addMoreLocation}
                >
                  + Add More Locations
                </p>
              )}
            </div>
            <div className="agency__overview">
              <div className="part1">
                <p className="para">Agency Overview</p>
                <p className="para color__orange fw__600 cursor__pointer">
                  Edit
                </p>
              </div>
              <textarea
                type="text"
                name="aboutAgency"
                className="agency__textarea"
                onChange={basicDetailsChangeHandler}
                value={basicAgencyDetails.aboutAgency}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Contact details */}
      <div className="contact__details">
        <p className="para text__left fw__600">Contact Details</p>
        <div className="contact__details__data">
          <p className="para adgrey">
            Just in case you miss it, we will notify you about new business
            opportunities
          </p>
          <div className="contact__details__forms">
            {contactBoxes.map((c, i) => (
              <div
                onChange={(e) => {
                  contactChangeHandler(i, e);
                }}
                className="form"
              >
                <div className="inp__fields flxs">
                  <label className="para">Office Location</label>
                  <select name="officeLocation" className="select adgrey">
                    <option value="">Select a Location</option>
                    {agencyLocations.map((loc) => (
                      <option value={loc}>{loc}</option>
                    ))}
                  </select>
                </div>
                <div className="inp__fields flxl">
                  <label className="para">Contact Person</label>
                  <input
                    className="input adgrey"
                    placeholder="John Doe"
                    type="text"
                    name="contactPerson"
                  ></input>
                </div>
                <div className="inp__fields flxl">
                  <label className="para">Mail ID</label>
                  <input
                    name="mailId"
                    className="input adgrey"
                    placeholder="business@redagency.com"
                    type="text"
                  ></input>
                </div>
                <div className="inp__fields mobinpcont flxs">
                  <label className="para">Mobile</label>
                  <input
                    name="mobile"
                    className="input mobinp adgrey"
                    placeholder="9824291969"
                    type="text"
                  ></input>
                </div>
              </div>
            ))}
            <div className="dflex justify__between">
              <p
                onClick={addMoreContacts}
                className="para color__orange fw__600 cursor__pointer"
              >
                + Add More Contacts
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Services */}
      <div className="contact__details">
        <p className="para text__left fw__600">Services</p>
        <div className="contact__details__data">
          <p className="para adgrey">
            Select any 3 focus services. This will help your agency to position
            as an expert in the market
          </p>
          <div className="contact__details__forms">
            <form className=" form  client__form">
              {serviceBoxes.map((b, i) => {
                return (
                  <div key={b} className="inp__fields flxs">
                    <label className=" labelf para">
                      {" "}
                      Service {i + 1}{" "}
                      <input
                        disabled={!canSelect && !b.check}
                        checked={b.check}
                        onChange={serviceCheck}
                        name={b.name}
                        type="checkbox"
                      ></input>
                    </label>
                    <select
                      key={b}
                      onChange={serviceChangeHandler}
                      name={b.name}
                      className="select adgrey"
                    >
                      <option value="">Select a Service</option>
                      {serviceOptions.map((s) => {
                        return <option value={s}>{capitalize(s)}</option>;
                      })}
                    </select>
                  </div>
                );
              })}
            </form>
            <div className="dflex justify__between">
              {fillServices && (
                <p
                  onClick={addMoreService}
                  className="para color__orange fw__600 cursor__pointer"
                >
                  + Add More Services
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Clients */}
      <p className="para text__left fw__600">Clients</p>
      <div className="contact__details">
        {clientBoxes.map((cb, i) => (
          <div className="contact__details__data">
            <div
              className="contact__details__forms"
              onChange={(e) => {
                clientChangeHandler(i, e);
              }}
            >
              <form className="form client__form">
                <div className="inp__fields flx25">
                  <label className="para">Brand</label>
                  <input
                    value={brand}
                    name="clientName"
                    className="input adgrey"
                    placeholder="Brand name"
                    type="text"
                    onChange={(e) => {
                      e.preventDefault();
                      setBrand(e.target.value);
                    }}
                  ></input>
                </div>
                <div className="inp__fields flxl">
                  <label className="para">Website</label>
                  <input
                    name="clientURL"
                    className="input adgrey"
                    placeholder="https://www.pepsi.com"
                    type="text"
                  ></input>
                </div>

                <div className="inp__fields flxs25">
                  <label className=" labelf para"> Service</label>
                  <select name="clientService" className="select adgrey">
                    <option value="">Select a Service</option>
                    {agencyServices.map((s) => {
                      return <option value={s}>{capitalize(s)}</option>;
                    })}
                  </select>
                </div>
              </form>
              <form className="form client__form ">
                <div className="inp__fields flxs">
                  <label className="para">Logo</label>
                  <div className="logo__conts">
                    <input name="clientLogo" type="file"></input>
                    {/*<p className="para adgrey">500 X 500 px Square dimension</p>*/}
                  </div>
                </div>
                <div className="inp__fields reviews__cont flxs none">
                  <label className="labelf para">
                    Ratings & Reviews{" "}
                    {/* <img className="review__star__img" src={stars} alt="" /> */}
                    <RatingStars />
                  </label>
                  <textarea
                    name="clientReview"
                    className="textarea review__textarea adgrey"
                    placeholder="Write review (100 words max)"
                    type="text"
                  ></textarea>
                </div>
                <div className="inp__fields client__para__cont flxs">
                  <label className=" para adgrey">
                    These ratings & Reviews are anonymous and only the average
                    aggregate ratings are displayed on the Brand profile
                  </label>
                </div>
              </form>
            </div>
          </div>
        ))}
        <div className="contact__details__data">
          <div className="contact__details__forms">
            <div className="done__add__cont">
              <button className="done__btn" onClick={submitClientHandler}>
                {clientloadbtn && (
                  <i className="fas fa-circle-notch donebtnclient"></i>
                )}
                {clientloadbtn && " Processing"}
                {!clientloadbtn && "Done"}
              </button>{" "}
              &nbsp; &nbsp;
            </div>
          </div>
          {clientsSaved && <p>Clients Saved...</p>}
          <div className="dflex justify__between">
            <p
              onClick={addClientHandler}
              className="para color__orange fw__600 cursor__pointer"
            >
              {" "}
              + Add More Clients
            </p>
          </div>
        </div>
      </div>

      {/* Case Study */}
      {/* Clients */}
      <div className="contact__details">
        <p className="para text__left fw__600">Case Study</p>
        <div className="contact__details__data">
          {caseStudyBoxes.map((c, i) => (
            <div className="contact__details__forms">
              <div className="case__study__tops">
                <p className="para adgrey">
                  Showcase the work that you did for the Brand in detail
                </p>
                <p className="para adgrey">
                  <span>
                    <img src={dotg} /> &nbsp;
                  </span>
                  Better Search Appearances
                </p>
                <p className="para adgrey">
                  <span>
                    <img src={dotg} /> &nbsp;
                  </span>
                  Enhanced Profile Credibility
                </p>
              </div>
              <form
                className="form client__form"
                onChange={(e) => {
                  caseStudyChangeHandler(i, e);
                }}
              >
                <div className="inp__fields flxs">
                  <label className="para">Brand</label>
                  <select name="client" className="select adgrey">
                    <option value="">Select a Client</option>
                    {/* {brands.map((s) => {
                      return (
                        <option value={s.brandName}>
                          {capitalize(s.brandName)}
                        </option>
                      );
                    })} */}
                    {brandname.map((s) => {
                      return <option value={s}>{capitalize(s)}</option>;
                    })}
                  </select>
                </div>
                <div className="inp__fields flxs">
                  <label className="para">Project Title</label>
                  <input
                    name="projectTitle"
                    className="input adgrey"
                    placeholder="Nike Product Launch"
                    type="text"
                  ></input>
                </div>

                <div className="inp__fields flxs">
                  <label className=" labelf para"> Service</label>
                  <select name="services" className="select adgrey">
                    <option value="">Select a Service</option>
                    {agencyServices.map((s) => {
                      return <option value={s}>{capitalize(s)}</option>;
                    })}
                  </select>
                </div>
                <div className="inp__fields flxs">
                  <label className="para">Industry</label>
                  <input
                    name="industry"
                    className="input adgrey"
                    placeholder="Food & Beverage"
                    type="text"
                  ></input>
                </div>
              </form>
              <div
                onChange={(e) => {
                  caseStudyChangeHandler(i, e);
                }}
                className="case__overview"
              >
                <p className="para">Overview</p>
                <textarea
                  name="overview"
                  type="text"
                  className="case__textarea"
                  placeholder="Content"
                />
              </div>
              <div
                onChange={(e) => {
                  caseStudyChangeHandler(i, e);
                }}
                className="case__textarea__cont"
              >
                <div className="case__textarea__part">
                  <label className="label para"> Goals</label>
                  <textarea
                    name="goals"
                    type="text"
                    className="case__textarea"
                    placeholder="Content"
                  />
                </div>
                <div className="case__textarea__part">
                  <label className="label para"> Challenges</label>
                  <textarea
                    name="challenges"
                    type="text"
                    className="case__textarea"
                    placeholder="Content"
                  />
                </div>
              </div>
              <div
                className="case__textarea__cont"
                onChange={(e) => {
                  caseStudyChangeHandler(i, e);
                }}
              >
                <div className="case__textarea__part">
                  <label className="label para"> Idea & Solution</label>
                  <textarea
                    name="ideaAndSolution"
                    type="text"
                    className="case__textarea"
                    placeholder="Content"
                  />
                </div>
                <div className="case__textarea__part">
                  <label className="label para"> Results</label>
                  <textarea
                    name="results"
                    type="text"
                    className="case__textarea"
                    placeholder="Content"
                  />
                </div>
              </div>
              {/* case study statics */}
              <div className="case__statics__cont">
                <div className="case__statics__title">
                  <p className="para">Statistics</p>
                  <p className="para color__orange cursor__pointer">Skip</p>
                </div>
                {statisticsBoxes.map((s, j) => (
                  <form
                    className="case__statics__form"
                    onChange={(e) => {
                      statisticsChangeHandler(i, j, e);
                    }}
                  >
                    <div className="part">
                      <div className="inp__fields inpsmall">
                        <input
                          name="reach"
                          className="input adgrey text__center"
                          placeholder="47k"
                          type="text"
                        ></input>
                      </div>
                      <div className="inp__fields inpbig">
                        <input
                          name="stats1"
                          className="input adgrey"
                          placeholder="Social Media Reach"
                          type="text"
                        ></input>
                      </div>
                    </div>
                    <div className="part">
                      <div className="inp__fields inpsmall">
                        <input
                          name="revenue"
                          className="input adgrey text__center"
                          placeholder="5M"
                          type="text"
                        ></input>
                      </div>
                      <div className="inp__fields inpbig">
                        <input
                          name="stats2"
                          className="input adgrey"
                          placeholder="Revenue in 6 months"
                          type="text"
                        ></input>
                      </div>
                    </div>
                  </form>
                ))}
                <div className="dflex justify__between">
                  <p
                    onClick={() => {
                      addStatisticsHandler(i);
                    }}
                    className="para color__orange fw__600 cursor__pointer"
                  >
                    + Add More Statistics
                  </p>
                </div>
              </div>
              {/* case study img gif vid */}
              <div className="case__igv__main__cont">
                <div className="case__statics__title">
                  <p className="para">Images, GIFs & Videos</p>
                </div>
                <div className="case__igv__cont">
                  {mediaBoxes.map((m, j) => (
                    <input
                      type="file"
                      onChange={(e) => {
                        mediaChangeHandler(i, j, e);
                      }}
                    ></input>
                  ))}
                </div>
                <div className="dflex justify__between">
                  <p
                    onClick={addMediaHandler}
                    className="para color__orange fw__600 cursor__pointer"
                  >
                    + Add More Media
                  </p>
                </div>
              </div>
              {/* case study team */}
              <div className="case__team">
                <p className="para">Team</p>
                {teamBoxes.map((t, j) => (
                  <form
                    className="form client__form"
                    onChange={(e) => {
                      teamChangeHandler(i, j, e);
                    }}
                  >
                    <div className="inp__fields flxs">
                      <input
                        name="name"
                        className="input adgrey"
                        placeholder="Amit Dhakite"
                        type="text"
                      ></input>
                    </div>
                    <div className="inp__fields flxs">
                      <input
                        name="position"
                        className="input adgrey"
                        placeholder="Creative Director"
                        type="text"
                      ></input>
                    </div>
                    <div className="inp__fields flxs">
                      <input
                        name="mailId"
                        className="input adgrey"
                        placeholder="kuldeeo@redagency.com"
                        type="text"
                      ></input>
                    </div>

                    <div className=" radio__inp__main__cont flxs">
                      <div className="radio__inp__cont">
                        {" "}
                        <input
                          type="radio"
                          className="input adgrey"
                          id="html"
                          name="fav_language"
                          value="Admin"
                        />
                        <label for="html" className="para">
                          Admin
                        </label>{" "}
                        <input
                          type="radio"
                          className="input adgrey"
                          id="css"
                          name="fav_language"
                          value="General"
                        />
                        <label for="css" className="para">
                          General
                        </label>
                      </div>
                    </div>
                    <div className="flxs">
                      <p className="invite__btn para">Invite(d!)</p>
                    </div>
                  </form>
                ))}
                <div>
                  <p
                    onClick={addTeamHandler}
                    className="para color__orange fw__600 cursor__pointer"
                  >
                    + Add More Team Members
                  </p>
                </div>
              </div>
              <hr />
            </div>
          ))}
          <div className="">
            <div className="done__add__cont">
              <button className="done__btn" onClick={caseStudySubmitHandler}>
                {caseloadbtn && (
                  <i className="fas fa-circle-notch donebtnclient"></i>
                )}
                {caseloadbtn && " Processing"}
                {!caseloadbtn && "Done"}
              </button>{" "}
              &nbsp; &nbsp;
            </div>
            {caseStudiesSaved && <p>Case Studies Saved...</p>}
            &nbsp; &nbsp;
            <p
              onClick={addCaseStudyHandler}
              className="para color__orange fw__600 cursor__pointer"
            >
              {" "}
              + Publish more Projects
            </p>
          </div>
        </div>
      </div>
      {/* Awards */}
      <div className="contact__details">
        <p className="para text__left fw__600">Awards</p>
        <div className="contact__details__data">
          <p className="para adgrey">Link to the article builds credibility</p>
          <div className="contact__details__forms">
            {awardBoxes.map((a, i) => (
              <form
                onChange={(e) => {
                  awardChangeHandler(i, e);
                }}
                className=" form  award__form"
              >
                <div className="inp__fields flxxs">
                  <label className=" labelf para"> Award Name</label>
                  <input
                    name="awardName"
                    className="input adgrey"
                    placeholder="Creative Director"
                    type="text"
                  ></input>
                </div>
                <div className="inp__fields flxs">
                  <label className=" labelf para"> Award Link</label>
                  <input
                    name="awardLink"
                    className="input adgrey"
                    placeholder="kuldeeo@redagency.com"
                    type="text"
                  ></input>
                </div>
                <div className="inp__fields flxxs">
                  <label className=" labelf para"> Award Logo</label>
                  <div className="upload__btn__cont">
                    <input
                      name="awardLogo"
                      type="file"
                      className="input adgrey uploadbtn"
                    ></input>{" "}
                  </div>
                </div>
              </form>
            ))}
            <div className="more__awards__btn">
              <p
                onClick={addMoreAwards}
                className="para color__orange fw__600 cursor__pointer"
              >
                + Add More Awards
              </p>
              <p className="para color__orange cursor__pointer">Skip</p>
            </div>
          </div>
        </div>
      </div>

      {/* News Mentions */}
      <div className="contact__details">
        <p className="para text__left fw__600">News Mentions</p>
        <div className="contact__details__data">
          <p className="para adgrey">Link to the article builds credibility</p>
          <div className="contact__details__forms">
            {newsBoxes.map((n, i) => (
              <form
                onChange={(e) => {
                  newsChangeHandler(i, e);
                }}
                className=" form  award__form"
              >
                <div className="inp__fields flxxs">
                  <label className=" labelf para"> Title of the Article</label>
                  <input
                    name="newsHeading"
                    className="input adgrey"
                    placeholder="Abhishek Rajan"
                    type="text"
                  ></input>
                </div>
                <div className="inp__fields flxs">
                  <label className=" labelf para"> News Url</label>
                  <input
                    name="newsURL"
                    className="input adgrey"
                    placeholder="Creative Director"
                    type="text"
                  ></input>
                </div>
                <div className="inp__fields flxxs">
                  <label className=" labelf para">Image</label>
                  <div className="upload__btn__cont">
                    <input
                      name="newsLogo"
                      type="file"
                      className="input adgrey uploadbtn"
                    ></input>{" "}
                  </div>
                </div>
              </form>
            ))}
            <div className="more__awards__btn">
              <p
                onClick={addMoreNews}
                className="para color__orange fw__600 cursor__pointer"
              >
                + Add More News Mentions
              </p>
              <p className="para color__orange cursor__pointer">Skip</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mainupload__btn__cont">
        <button onClick={submitHandler} className="mainupload__btn">
          {uploadbtn && <i className="fas fa-circle-notch donebtnclient"></i>}
          {uploadbtn && " Updating"}
          {!uploadbtn && "Update Profile"}
        </button>
      </div>
    </div>
    // </div>
  );
}

export default AOnboarding;
