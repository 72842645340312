import React from "react";
import "./PopularAgency.css";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

function PopularAgency() {
  const services1 = [
    {
      location: "Delhi",
    },
    {
      location: "Bangalore",
    },
    {
      location: "Mumbai",
    },
    {

      location: "Chennai",
    },
    {
      location: "Punjab",
    },
    {
      location: "Ahmedabad",
    },
    {

      location: "Pune",
    },
    {
      location: "Gurgaon",
    },
    {
      location: "Chandigarh",
    },
    {
      location: "Noida",
    },
  ];

  return (
    <div className="popularagency">
      <div className="agenciesCont">
        <ul className="tab">
          <div className="serviceHeading">
          <Link className="serviceHeadingLink" to={"seo-" + "agencies"}>
            <h3>SEO</h3>
          </Link>
          </div>
          {services1.map((m) => (
            <li className="servicePageTags" key={uuidv4()}>
              <Link className="servicePageText"
                to={
                  "seo-" + "agencies-" + "in-" +
                  m.location.toLowerCase()
                }
              >
                SEO in {m.location}
              </Link>
            </li>
          ))}
        </ul>

        <ul className="tab">
        <div className="serviceHeading">
          <Link className="serviceHeadingLink" to={"social-media-marketing-" + "agencies"}>
            <h3>Social Media Marketing</h3>
          </Link>
        </div>
          {services1.map((m) => (
            <li className="servicePageTags" key={uuidv4()}>
              <Link className="servicePageText"
                to={
                  "social-media-marketing-" + "agencies-" + "in-" +
                  m.location.toLowerCase()
                }
              >
                Social Media marketing in {m.location}
              </Link>
            </li>
          ))}
        </ul>

        <ul className="tab">
          <div className="serviceHeading">
            <Link className="serviceHeadingLink" to={"content-writing-" + "agencies"}>
              <h3>Content Writing</h3>
            </Link>
          </div>
          {services1.map((m) => (
            <li className="servicePageTags" key={uuidv4()}>
              <Link className="servicePageText"
                to={
                  "content-writing-" + "agencies-" + "in-" +
                  m.location.toLowerCase()
                }
              >
                Content writing in {m.location}
              </Link>
            </li>
          ))}
        </ul>
        <ul className="tab">
          <div className="serviceHeading">
            <Link className="serviceHeadingLink" to={"design-" + "agencies"}>
              <h3>Design</h3>
            </Link>
          </div>
          {services1.map((m) => (
            <li className="servicePageTags" key={uuidv4()}>
              <Link className="servicePageText"
                to={
                  "design-" + "agencies-" + "in-" +
                  m.location.toLowerCase()
                }
              >
                Design in {m.location}
              </Link>
            </li>
          ))}
        </ul>
        <ul className="tab">
          <div className="serviceHeading">
            <Link className="serviceHeadingLink" to={"digital-marketing-" + "agencies"}>
              <h3>Digital Marketing</h3>
            </Link>
          </div>
          {services1.map((m) => (
            <li className="servicePageTags" key={uuidv4()}>
              <Link className="servicePageText"
                to={
                  "digital-marketing-" + "agencies-" + "in-" +
                  m.location.toLowerCase()
                }
              >
                Digital Marketing in {m.location}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {/* <div className="">
        <button className="reg__btn ">Explore All</button>
      </div> */}
    </div>
  );
}

export default PopularAgency;
