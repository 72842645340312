import React, { useState } from "react";
import "./Question.css";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";

function Question({ question, answer, isLast }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="question-container-unique">
      <h2 className="question-title-unique" onClick={toggleAnswer}>
        {question}
        <span className="accordion-icon-unique">
          {isOpen ? <MdOutlineKeyboardArrowDown /> : <MdKeyboardArrowLeft />}
        </span>
      </h2>
      {isOpen && <p className="question-answer-unique">{answer}</p>}
      {!isLast && <div className="question-divider-unique"></div>}
    </div>
  );
}

export default Question;
