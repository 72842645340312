import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../styles/PortfolioDetail.css";
import saveempty from "../images/saveempty.png";
import shareIcon from "../images/share.png";
import process from "../images/process.png";
import scrolltotop from "../images/scrolltotop.png";
import AgencyReviews from "./agency/AgencyReviews";
import CaseStudies from "./agency/CaseStudies";
import { Link, useHistory } from "react-router-dom";
import fullWidth from "../images/full__width.png";
import x9 from "../images/9x9.png";
import carousel from "../images/carousel.png";
import vertical from "../images/vertical.png";
import "../styles/CaseStudyLayout.css"
import axios from "../axios.js";
import Carousel from "react-bootstrap/Carousel"
// components

function ImageLayout({imgtype, image}) {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    const divStyle = ["", "full__width", "fit__width", "images__9", "vertical", "carousel"]
    if(imgtype==5){
        return (
            <Carousel activeIndex={index} onSelect={handleSelect} className="carousels">
                {image.map(item =>
                <Carousel.Item interval={2000}>
                    <img
                        className="d-block w-100"
                        src={item}
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <h3>First slide label</h3>
                        
                    </Carousel.Caption>
                </Carousel.Item>
                )}
            </Carousel>
            )
    } else if(imgtype<5) {
        return (
            <div className={divStyle[imgtype]}>
                {image.map(item => <img src={item}></img>)}
            </div>
        )
    }
}

function CaseStudyLayout() {
    // const temp = [
    //     {client :"helllpp",
    //     coverImg:"https://unispade-react-js-s3.s3.amazonaws.com/caseStudyMedia/image1.png",
    //     projectTitle:"title",
    //     services:["service1", "Service2", "service3"],
    //     industry:"Building Materials",
    //     overview:"lorem ipsum lorem ipsum lorem ipsum lorem ipsum",
    //     goals: {text: "Lorem ipsum",
    //             imgtype:"1",
    //             image:["https://unispade-react-js-s3.s3.amazonaws.com/caseStudyMedia/image1.png"]
    //             },
    //     challenges:{text:"lorem",
    //                 imgtype:"2",
    //                 image:["https://unispade-react-js-s3.s3.amazonaws.com/caseStudyMedia/image2.jpg"]
    //             },
    //     ideaAndSolution:{text:"ikdvkdn",
    //                     imgtype:"3",
    //                     image:["https://unispade-react-js-s3.s3.amazonaws.com/caseStudyMedia/image3.jpg",
    //                            "https://unispade-react-js-s3.s3.amazonaws.com/caseStudyMedia/image1.png"]
    //                     },
    //     results: {text:"lorem",
    //               imgtype:"4",
    //               image:["https://unispade-react-js-s3.s3.amazonaws.com/caseStudyMedia/image3.jpg"]
    //             },
    //     statistics:[{reach:"45", stats1:"Social",revenue :"34",stats2:"Rev"},
    //                 {reach:"45", stats1:"Social",revenue :"34",stats2:"Rev"}],
    //     team:[{name:"Yogesh",
    //            position:"Dev",
    //            mailId:"email@example.com",
    //            isAdmin:false}]
    //         }]

    const history = useHistory();
    // To perform scroll
    const params = useParams();
    // console.log(params);
    const [agencyName, setAgencyName] = useState("");
    const [comment, setComment] = useState("");
    const [caseStudies, setCaseStudies] = useState([]);
    const [awards, setAwards] = useState(null);
    const [caseStudiesFiltered, setCaseStudiesFiltered] = useState([]);
    // const myArray = ["demo1", "demo2", "demo3", "Demo4"];
    // const imgArray = ["https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
    // "https://cdn.pixabay.com/photo/2021/08/25/20/42/field-6574455__340.jpg",
    // "https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80",
    // "https://media.istockphoto.com/photos/picturesque-morning-in-plitvice-national-park-colorful-spring-scene-picture-id1093110112?k=20&m=1093110112&s=612x612&w=0&h=3OhKOpvzOSJgwThQmGhshfOnZTvMExZX2R91jNNStBY=",
    // "https://i.pinimg.com/474x/01/88/dc/0188dc41881e0e410b5375cdead5f49a.jpg"]
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(
                    "/agencyOnboarding/getACaseStudy",
                    params
                )
                // .then(res => console.log("res", res.data.caseStudies[params.index]));
                // setCaseStudies(temp)
                
                await setCaseStudies(response.data.caseStudies);
                await setAgencyName(response.data.agencyName);
                await setAwards(response.data.awards);
                const newStudies = response.data.caseStudies.filter(
                    (s) => s._id !== response.data.caseStudies[params.index]._id
                );
                setCaseStudiesFiltered(newStudies);
            } catch (e) {
                console.log(e);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const newStudies = caseStudies?.filter(
            (s) => s._id !== caseStudies[params.index]._id
        );
        setCaseStudiesFiltered(newStudies);
    }, [params.index]);

    const performScroll = (e) => {
        e.preventDefault();

        const alinkitem = e.target; //btn which is clicked

        const id = e.target.getAttribute("href").slice(1);

        const idcont = document.getElementById(id); //element we have to reach
        const headerHeight = 70; //height of header
        const position = idcont.offsetTop - headerHeight - 20; //also dereasing some height to look good

        const alllinks = document.querySelectorAll(".alinkstoscroll");
        // console.log("allLinks -> ", alllinks);

        alllinks.forEach((link) => {
            link.classList.remove("active"); //deactivating rest of the links
        });

        alinkitem.classList.add("active"); //activating the link which is clicked

        window.scrollTo({
            left: 0,
            top: position,
        });
    };

    // Scroll to top functionality starts
    const [sttvisible, setsttvisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setsttvisible(true);
        } else if (scrolled <= 300) {
            setsttvisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    window.addEventListener("scroll", toggleVisible);




    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    // console.log(caseStudies[params.index].coverImg)
    var cover = {
        backgroundImage: caseStudies[params.index]?.coverImg ? "url("+ caseStudies[params.index].coverImg + ")" : "" ,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: "no-repeat"
        
      };
    var tint = {
        background: caseStudies[params.index]?.coverImg ? "rgba(11, 28, 56,0.85)" : "rgb(11, 28, 56)"
    }
      
    return (
        <div className="portdet__cont">
            {/* Scoll to top btn */}
            <img
                onClick={scrollToTop}
                src={scrolltotop}
                className="scrolltotopbtn"
                alt=""
                style={{ display: sttvisible ? "inline" : "none" }}
            />
            <div style={ cover }>
                <div className="pd__top__cont" style={tint}>
                    <div className="pd__top">
                        <h2 className="heading" style={{ color: "white" }}>
                            {/* How did this brand manage to increase their quarterly sales by 23%? */}
                            {caseStudies[params.index]?.projectTitle}
                            {/* {console.log(caseStudiesFiltered)} */}

                        </h2>

                        <div className="tags">
                            {caseStudies[params.index]?.services?.map((m) => (
                                <p className="tag">{m.toUpperCase()}</p>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="agencyprofile portdet">

                <div className="sec__right" id="sec__right">

                    <div className="para__cont">
                        <div className="portfolio__details" >
                            <div className="part">
                                <p className="para">Clients</p>
                                {console.log("Hi", caseStudies)}
                                <h4>{caseStudies[params.index]?.client}</h4>
                            </div>
                            <div className="part">
                                <p className="para">Agency</p>
                                <h4>{agencyName}</h4>
                            </div>


                        </div>
                        <div className="portfolio__details" id="border__bottom" >
                            <div className="part">
                                <p className="para">Industry</p>
                                <h4>{caseStudies[params.index]?.industry}</h4>
                            </div>
                            <div className="part">
                                <p className="para">Services</p>
                                <h4>
                                    {caseStudies[params.index]?.services.map(item => <span>{item.toUpperCase()}</span>)
                                            .reduce((acc, x) => acc === null ? [x] : [acc, ' | ', x], null)}
                                </h4>
                            </div>
                        </div>
                        <span className="Overview">{caseStudies[params.index]?.overview}</span>
                        <div className="statistics">
                            {caseStudies[params.index]?.statistics.map((m) => (
                                <>
                                    <div className="stats">
                                        <p className="para">{m.reach}</p>
                                        <h4>{m.stats1}</h4>
                                    </div>
                                    <div className="stats">
                                        <p className="para">{m.revenue}</p>
                                        <h4>{m.stats2}</h4>
                                    </div>
                                </>

                            ))}
                        </div>

                        <div className="challenges">
                            <h2>
                                Goals
                            </h2>
                            <p>
                                {caseStudies[params.index]?.goals?.text}
                            </p>
                        </div>

                        {caseStudies[params.index]?.goals.imgtype && caseStudies[params.index]?.goals?.image ? 
                        <ImageLayout imgtype={caseStudies[params.index]?.goals?.imgtype} image={caseStudies[params.index]?.goals?.image}/>
                        : ""
                        }



                        <div className="challenges">
                            <h2>
                                Challenges
                            </h2>
                            <p>
                                {caseStudies[params.index]?.challenges?.text}
                            </p>
                        </div>

                        {caseStudies[params.index]?.challenges?.imgtype && caseStudies[params.index]?.challenges?.image ?
                        <ImageLayout imgtype={caseStudies[params.index]?.challenges?.imgtype} image={caseStudies[params.index]?.challenges?.image}/>
                        : ""
                        }

                        <div className="challenges">
                            <h2>
                                Idea And Solution
                            </h2>
                            <p>
                                 {caseStudies[params.index]?.ideaAndSolution?.text}
                            </p>
                        </div>

                        {caseStudies[params.index]?.ideaAndSolution?.imgtype && caseStudies[params.index]?.ideaAndSolution?.image ?
                        <ImageLayout imgtype={caseStudies[params.index]?.ideaAndSolution?.imgtype} image={caseStudies[params.index]?.ideaAndSolution?.image}/>
                        : "" }
                        

                        <div className="challenges">
                            <h2>
                                Results
                            </h2>
                            <p>
                            {caseStudies[params.index]?.results?.text}
                            </p>
                        </div>
                        {caseStudies[params.index]?.results?.imgtype && caseStudies[params.index]?.results?.image ?
                        <ImageLayout imgtype={caseStudies[params.index]?.results?.imgtype} image={caseStudies[params.index]?.results?.image}/>
                        : "" }



                    </div>
                </div>
            </div>
            {/* Search agencies */}
            <div className="mar5__cont">
                <div className="searchagencies__cont ">
                    <div className="searchagencies">
                        <p className="mwidthsmall">
                            Increase the chances of your project success, partner with the{" "}
                            <span className="color__orange">right agency</span>
                        </p>
                        <div>
                            <button onClick={() => history.push("/search")}>
                                Search Agencies
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CaseStudyLayout;
