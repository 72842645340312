import React from "react";
import SapFilters from "./SapFilters";
import "../../styles/Sap.css";
import SapSidebar from "./SapSidebar";

import {
  useHistory,
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import SapAgencies from "./SapAgencies";
import SapLeads from "./SapLeads";
import SapService from "./SapService";
import SapContent from "./SapContent";
import SapServiceAddEdit from "./SapServiceAddEdit";
import Homepage from "../Homepage";
import Footer from "../Footer/Footer";
import Header from "../Header";
import SapLogin from "./SapLogin";

function Sapmain() {
  const history=useHistory();
  if(localStorage.getItem("sapuser")==null){
    history.push('/saplogin')

  }
  return (
    <Router>
      <div className="sap">
        <ScrollToTop />
        <SapSidebar />
        <Switch>
          <Route path="/sap/service" exact>
            <SapService />
          </Route>
          <Route path="/sap/service/addedit" exact>
            <SapServiceAddEdit />
          </Route>
          <Route path="/sap/leads" exact>
            <SapLeads />
          </Route>
          <Route path="/sap/filters" exact>
            <SapFilters />
          </Route>
          <Route path="/sap/agencies" exact>
            <SapAgencies />
          </Route>
          <Route path="/sap/content" exact>
            <SapContent />
          </Route>
          <Route path="/sap">
            <SapAgencies />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default Sapmain;
